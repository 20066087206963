/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../translate/translate.pipe";
import * as i2 from "../translate/translate.service";
import * as i3 from "./mobile-device.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import * as i5 from "../service/wt-storage.service";
var styles_MobileDeviceComponent = [];
var RenderType_MobileDeviceComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MobileDeviceComponent, data: {} });
export { RenderType_MobileDeviceComponent as RenderType_MobileDeviceComponent };
export function View_MobileDeviceComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "h4", [["class", "text-center get-andr-app-header"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", " ", ""])), i0.ɵpid(0, i1.TranslatePipe, [i2.TranslateService]), i0.ɵpid(0, i1.TranslatePipe, [i2.TranslateService]), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "modal-body get-android-app"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "d-flex  modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "a", [["class", "app-download-link"]], [[1, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(8, null, [" ", " "])), i0.ɵpid(0, i1.TranslatePipe, [i2.TranslateService])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("mobiledevice_line1")); var currVal_1 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 4).transform("mobiledevice_line2")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.wtStorageService.mobileAppDownloadLink; _ck(_v, 7, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform("mobiledevice_download_app")); _ck(_v, 8, 0, currVal_3); }); }
export function View_MobileDeviceComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mobile", [["class", "device"]], null, null, null, View_MobileDeviceComponent_0, RenderType_MobileDeviceComponent)), i0.ɵdid(1, 49152, null, 0, i3.MobileDeviceComponent, [i4.NgbActiveModal, i5.WTStorageService], null, null)], null, null); }
var MobileDeviceComponentNgFactory = i0.ɵccf("mobile.device", i3.MobileDeviceComponent, View_MobileDeviceComponent_Host_0, {}, {}, []);
export { MobileDeviceComponentNgFactory as MobileDeviceComponentNgFactory };
