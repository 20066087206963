
    <div class="col-12 col-md-12 form-content-block">
      <div class="row">
        <div class="deposit-form-block" [perfectScrollbar]>
          <form
            class="dynamic-form"
            [formGroup]="form"
            (submit)="handleSubmit($event)">
            <ng-container
              *ngFor="let field of config;"
              dynamicField
              [config]="field"
              [group]="form">
            </ng-container>
          </form>
        </div>
      </div>
    </div>
  