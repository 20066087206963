import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as wtconfig from './config/wt.config';

if (environment.production) {
  // Google Analytics Code Snippet
  if (wtconfig.WT_CONFIG.GA_TRACKING_CODE && wtconfig.WT_CONFIG.GA_TRACKING_CODE !== '') {

    const script1 = document.createElement('script');

    script1.src = `https://www.googletagmanager.com/gtag/js?id=${wtconfig.WT_CONFIG.GA_TRACKING_CODE}`;
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = ` window.dataLayer = window.dataLayer || [];

    function gtag() {
        dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config','${wtconfig.WT_CONFIG.GA_TRACKING_CODE}');`;

    document.head.appendChild(script2);
  }
  if (wtconfig.WT_CONFIG.LOGGING_BASE_URL && wtconfig.WT_CONFIG.LOGGING_BASE_URL !== '') {
    const script3 = document.createElement('script');
    script3.innerHTML = `
    window.onerror = function (errorMsg, url, lineNumber) {
      var req = new XMLHttpRequest();
      var params = 'error= ' + errorMsg.message ;
      req.open('POST', '${wtconfig.WT_CONFIG.LOGGING_BASE_URL}/logError');
      req.setRequestHeader('Content-type', 'application/json;charset=UTF-8');
      req.setRequestHeader('secretkey', '${wtconfig.WT_CONFIG.SECRET_KEY}');
      req.send(JSON.stringify({ error: errorMsg.message }));
    }`;

    document.head.appendChild(script3);
  }
  enableProdMode();
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
