<span *ngIf="tradeViewLoaded"></span>
<div *ngIf="tradeViewLoaded" id="scroller" [perfectScrollbar] class="opentrades side-collapse animated" [ngClass]="{'in': showOpenTrades,
              'slideInLeft': showOpenTrades, 'fadeOutLeftBig': !showOpenTrades}">
  <div class="title-head">
    <span class="opentrades-font"> {{'Open_Open_Positions' | translate}}</span>
  </div>
  <div class="empty-trade" *ngIf="counter()">{{'Open_NO_Trade' | translate}}</div>
  <!-- ng repeat  -->

  <div *ngFor="let openTrade of openTrades | openTradeOrderbyId">
    <div class="positions-row animated fadeInDown" *ngIf="openTrade.symbol">
      <!-- circle lose -->
      <div class="align-items-center d-flex">
        <div class="col-4 float-left progress-wrapper" [ngClass]="{'trade-result': openTrade.closeTime!=null}">
          <div class="timer-text" *ngIf="openTrade.closeTime==null">
            {{date | countDownTimer:openTrade.expirationTime:true | timeHMS}}
          </div>
          <div *ngIf="openTrade.closeTime!=null" [ngClass]="{'lose':openTrade.status=='Loss','win':openTrade.status=='Win',
                                    'draw':openTrade.status=='Draw', 'open-position-res':true}">
            {{openTrade | openTradeStatus | translate}}</div>
          <round-progress *ngIf="openTrade.closeTime==null"
            [current]="date | countDownTimer:openTrade.expirationTime:true"
            [max]="openTrade.openTime | countDownTimer:openTrade.expirationTime:false" [stroke]="3" [radius]="35"
            [rounded]="rounded" [responsive]="true" [clockwise]="true" [color]="gradient ? 'url(#gradient)' : '#f9c751'"
            [background]="'#000000'" [duration]="800" [animation]="'easeOutCubic'" [animationDelay]="0">
          </round-progress>

        </div>
        <!-- end circle lose -->
        <div class="col-8 float-left bottomborder">
          <h5 class="opentrades-font">{{openTrade.symbol}} <i class="fa iconsize open_position_symbol_align"
              [ngClass]="{'fa-angle-double-down wintxt ' : openTrade.action == 'Down', 'fa-angle-double-up losetxt' : openTrade.action == 'Up'}"></i>
          </h5>
          <h6 class="opentrades-font">{{'Open_Open' | translate}}: {{openTrade.openPrice}}</h6>
          <h6 class="opentrades-font">{{'Open_Close' | translate}}: <span
              [ngClass]="{'losetxt': openTrade.openPrice<openTrade.closePrice,
                                        'wintxt': openTrade.openPrice>openTrade.closePrice,
                                        'neutraltxt': openTrade.openPrice==openTrade.closePrice}">{{openTrade.closePrice}}</span></h6>
        </div>
      </div>

      <div class="mt15">
        <div class="col-4 float-left profit-padding">
          <h6 class="opentrades-font opentrades-margin">{{'Open_Amount' | translate}}</h6>
          <span>${{openTrade.amount}}</span>
        </div>
        <div class="col-3 float-left profit-padding">
          <h6 class="opentrades-font opentrades-margin">{{openTrade|timePeriod}}</h6>
          <span>{{openTrade.payout}}%</span>
        </div>
        <div class="col-5 float-left profit-padding">
          <h6 class="opentrades-font opentrades-margin">{{'Open_Profit' | translate}}</h6>
          <span
            [ngClass]="{'wintxt':openTrade.status=='Loss','losetxt':openTrade.status=='Win',
                                  'neutraltxt':openTrade.status=='Draw' }">{{openTrade.profit | currency:'USD':'symbol' | wtcurrency}}</span>
        </div>
      </div>
    </div>
  </div>

</div>
