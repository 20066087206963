var FormInputComponent = /** @class */ (function () {
    function FormInputComponent() {
    }
    FormInputComponent.prototype.fileUpload = function (event) {
        var _a;
        var fi = this.fileInput.nativeElement;
        if (fi.files && fi.files[0]) {
            var fileToUpload = fi.files[0];
            this.group.patchValue((_a = {},
                _a[this.config.name] = fileToUpload,
                _a));
        }
    };
    return FormInputComponent;
}());
export { FormInputComponent };
