import { Component ,EventEmitter, Output} from '@angular/core';
import {TradeService} from '../shared/trade.service';
import {WTStorageService} from '../../shared/service/wt-storage.service';
import { TranslateService } from '../../shared/translate/translate.service';
import {TranslatePipe} from '../../shared/translate/translate.pipe';
import * as moment from 'moment-timezone';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import {Observable} from 'rxjs/Observable';
import swal from 'sweetalert2';

@Component({
  moduleId: module.id,
  selector: 'trade-history',
  templateUrl: 'history.component.html',
  styleUrls: ['history.component.css']
})
export class TradeHistoryComponent {
	showTradesHistory:boolean = false;
	page:number = 1;
	currentRecords:Array<Object> = [];
	totalPages = 0;
	totalRecordsCount = 0;
	pageList:Array<number> = []
	tradeViewLoaded:boolean = false;
	isHistoryEmpty:boolean = false;
	historyRecords:any = {};
	archieveRecords:any = {};
	private isRequestProcessing:boolean = true;
	private isDataInitialized = false;
	private disableClose:boolean = false;
	private currentPageNumber:number = 1;
	private offsetIndex:number = 0;
	private tradePages:number = 0;
	private archivePages:number = 0;
	private maxDataPerPage:number = 20;
	tradeRecordsCount:number = 0;
	archiveRecordsCount:number = 0;
	isDataOutdated:boolean=false;

	@Output() closeHistoryView:EventEmitter<any> = new EventEmitter<any>();

	constructor(public wtStorageService: WTStorageService,private tradeService:TradeService, private translateService: TranslateService) {}

	initTradeHistory(){
		this.currentPageNumber = 1;
		this.pageList = [];
		this.currentRecords = [];
		this.isRequestProcessing = true;
		this.scrollToTop();
		if(this.isDataOutdated){
			this.historyRecords = {};
		} else if(this.historyRecords.hasOwnProperty(1)){
			this.setPageList();
			this.updateView(1);
			this.isHistoryEmpty = (this.historyRecords[1].length === 0);
			this.isDataInitialized = true;
		}
		this.getHistory(1);
	}

	private setPageList(){
		if(this.totalPages < 3) {
			this.pageList = [];
			for(let i = 1; i <= this.totalPages; i++){
				this.pageList.push(i);
			}
		} else {
			this.pageList = [1,2,3];
		}
	}

	private getHistory(pageNumber:any){
		let trade = this.tradeService.getTradeHistory(pageNumber,this.maxDataPerPage);
		let archiveTrade = this.tradeService.getArchiveHistory(pageNumber,this.maxDataPerPage);
		Observable.forkJoin([trade, archiveTrade]).subscribe((results:any) => {
			let tradeData = results[0];
			let archive = results[1];
			if (tradeData.success == false && tradeData['message'] == 'Invalid User') {
				this.showReloginMessage();
			}
			if(typeof(tradeData.data) === 'undefined') {
				tradeData.data = [];
				tradeData.total = 0;
				tradeData.last_page = 0;
			}
			if(typeof(archive.data) === 'undefined') {
				archive.data = [];
				archive.total = 0;
				archive.last_page = 0;
			}
			if(this.tradeRecordsCount != tradeData.total || this.archiveRecordsCount != archive.total){
				this.isDataOutdated = true;
				this.isRequestProcessing = true;
			}
			if(this.isRequestProcessing || this.isDataOutdated){
				if(this.archiveRecordsCount != archive.total) this.archieveRecords = {};
				this.tradeRecordsCount = tradeData.total;
				this.archiveRecordsCount = archive.total;
				this.tradePages = tradeData.last_page;
				this.archivePages = archive.last_page;
				this.offsetIndex = this.tradeRecordsCount % this.maxDataPerPage;
				this.totalRecordsCount = this.tradeRecordsCount + this.archiveRecordsCount;
				this.isHistoryEmpty = (this.totalRecordsCount === 0);
				this.totalPages = Math.ceil(this.totalRecordsCount / this.maxDataPerPage );

				let data = tradeData.data.length < this.maxDataPerPage ? [...tradeData.data,...archive.data.slice(0, this.maxDataPerPage - this.offsetIndex)] : tradeData.data;
				if(archive.data.length > 0) this.updateArchiveRecord(archive.data, pageNumber);
				this.setPageList();
				if(pageNumber == this.currentPageNumber) {
					if(this.isDataOutdated){
						this.isRequestProcessing = true;
						this.historyRecords = {};
						this.isDataOutdated = false;
					}
					this.updateHistoryRecord(data, pageNumber);
					this.updateView(pageNumber);
				}
				this.isDataInitialized = true;
			}
		});
	}

	private mergeData(tradeData:any, archiveData:any):Array<Object>{
		let data = tradeData;
		if(archiveData.length > this.maxDataPerPage - data.length){
			data = [...data,...archiveData.slice(0, this.maxDataPerPage - this.offsetIndex)];
		} else {
			data = [...data,...archiveData]
		}
		return data;
	}

	private updateHistoryRecord(data:any, pageNumber:number){
		for(let rec of data){
			if(rec.result === 'Draw'){
				rec.profit = rec.profit.split('-').length > 1 ? rec.profit.split('-')[1]:rec.profit;
			}
			var current_tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
			
			//console.log(current_tz)
			// moment.tz.guess();
			// rec.date = moment.tz(rec.date,'Europe/Athens').tz('Asia/Singapore').format('YYYY-MM-DD HH:mm')
			var open_time_raw = moment.tz(rec.open_time, 'Europe/Athens').tz(current_tz);
			var close_time_raw = moment.tz(rec.close_time, 'Europe/Athens').tz(current_tz);
			rec.date = moment.tz(rec.date, 'Europe/Athens').tz(current_tz).format('YYYY-MM-DD');
			rec.open_time = moment.tz(rec.open_time, 'Europe/Athens').tz(current_tz).format('YYYY-MM-DD HH:mm:ss');
			rec.close_time = moment.tz(rec.close_time, 'Europe/Athens').tz(current_tz).format('YYYY-MM-DD HH:mm:ss');
			rec.amount = "$"+ rec.amount;
			if (Number((moment.duration(close_time_raw.diff(open_time_raw))).asMinutes()) < 0  ) {
				rec.interval = "Pending";
				rec.result = "Pending";
			}
			else {
				rec.interval = (moment.duration(close_time_raw.diff(open_time_raw))).asMinutes()+"m";
			}
			//rec.profit = "$"+ rec.profit;
			// rec.interval = (rec.close_time.getTime() - rec.open_time.getTime())/1000
			let substring = "bo";
			if (rec.symbol.indexOf(substring) === -1 && rec.action !== "Balance") {
				rec.action = "Fx"
				rec.amount = "-"
				rec.interval = "-"
			}
			if (rec.action === "Balance" && rec.result === "Win") {
				rec.result = "Deposit";
				rec.open_price = "";
				rec.close_price = "";
				rec.amount = "$" + rec.profit;
				rec.interval = ""
				rec.profit = "";
			}
			if (rec.action === "Balance" && rec.result === "Loss") {
				rec.result = "Withdrawal"
				rec.open_price = "";
				rec.close_price = "";
				rec.amount = "-$" + Math.abs(rec.profit);
				rec.interval = ""
				rec.profit = "";
			}
		 //var a=	moment(rec.date).tz("Europe/Athens").valueOf()
		}
		this.historyRecords[pageNumber] = data;
	}

	private updateArchiveRecord(data:any, pageNumber:number){
		this.archieveRecords[pageNumber] = data;
	}

	private updateView(pageNum:any){
		this.currentRecords = this.historyRecords[pageNum];
		this.isRequestProcessing = false;
	}

	showNextPage(){
		if(this.currentPageNumber == this.totalPages) {
			return;
		} else if(this.pageList.indexOf(this.currentPageNumber) >= Math.floor(this.pageList.length / 2)
			&& this.pageList[this.pageList.length - 1] < this.totalPages){
			this.currentPageNumber = this.pageList[this.pageList.indexOf(this.currentPageNumber)] + 1;
			this.pageList.shift();
			this.pageList.push(this.pageList[this.pageList.length - 1] + 1);
		} else {
			this.currentPageNumber += 1;
		}
		this.updatePage(this.currentPageNumber);
	}

	openTradeClosed(data:any){
		let updatePages:boolean = false;
		let oldTotalPages = this.totalPages;
		if(this.totalPages < 3){
			updatePages = true;
		}
		this.tradePages = Math.ceil(this.tradeRecordsCount / this.maxDataPerPage );
		this.totalPages = Math.ceil(this.totalRecordsCount / this.maxDataPerPage );
		this.offsetIndex = this.tradeRecordsCount % this.maxDataPerPage;
		this.historyRecords = {};
		this.scrollToTop();
		this.updatePage(this.currentPageNumber);
		if(oldTotalPages !== this.totalPages && updatePages) this.setPageList();
	}

	updateCurrentPage(pageNum:any){
		if(this.isRequestProcessing) return;
		this.currentPageNumber = pageNum;
		if(this.pageList[0] > 1
			&& this.pageList.indexOf(this.currentPageNumber) < Math.floor(this.pageList.length / 2)){
				this.pageList.unshift(this.pageList[0]-1);
				this.pageList.pop();
		}
		if(this.pageList.indexOf(this.currentPageNumber) > Math.floor(this.pageList.length / 2)
			&& this.pageList[this.pageList.length - 1] < this.totalPages){
			this.pageList.shift();
			this.pageList.push(this.pageList[this.pageList.length - 1] + 1);
		}

		if(this.historyRecords.hasOwnProperty(pageNum.toString()) && !this.isDataOutdated){
			this.currentRecords = this.historyRecords[pageNum];
			this.updateView(pageNum);
		} else if(this.currentPageNumber > this.tradePages){
			let archPageNum = this.currentPageNumber - this.tradePages;
			this.offsetIndex = (this.tradeRecordsCount % this.maxDataPerPage) ;
			let data;
			if(this.offsetIndex == 0){
				if(this.archieveRecords.hasOwnProperty(archPageNum.toString())){
					data = this.archieveRecords[archPageNum];
				} else {
					this.getTradeArchiveHistory(pageNum, this.maxDataPerPage);
					return;
				}
			} else {
				let next = archPageNum + 1;
				if(this.archieveRecords.hasOwnProperty(archPageNum.toString())
					&& this.archieveRecords.hasOwnProperty(next.toString())){
					data =  [...this.archieveRecords[archPageNum].slice(this.maxDataPerPage - this.offsetIndex, this.maxDataPerPage), ...this.archieveRecords[next].slice(0, this.maxDataPerPage - this.offsetIndex)];
				} else {
					this.getMultiArchieveData(pageNum, archPageNum, this.offsetIndex);
					return;
				}
			}
			this.updateHistoryRecord(data, pageNum);
			this.updateView(pageNum);
		} else {
			this.getTradeHistory(pageNum);
		}
	}
	private updatePage(pageNum:any){
		if(this.isRequestProcessing) return;
		this.currentPageNumber = pageNum;
		if(this.historyRecords.hasOwnProperty(pageNum.toString())){
			this.currentRecords = this.historyRecords[pageNum];
			this.updateView(pageNum);
		} else {
			if(this.currentPageNumber > this.tradePages){
				this.getTradeArchiveHistory(pageNum, this.maxDataPerPage);
			} else {
				this.getTradeHistory(pageNum);
			}
		}
	}
	scrollToTop(){
		let tradeHistView = document.getElementById("tradeHistoryView");
		if(tradeHistView) tradeHistView.scrollTop = 0;
	}
	showPreviousPage(){
		if(this.currentPageNumber == 1) {
			return;
		} else if(this.pageList[0] > 1
			&& this.pageList.indexOf(this.currentPageNumber) <= Math.floor(this.pageList.length / 2)){
				this.currentPageNumber = this.pageList[this.pageList.indexOf(this.currentPageNumber)] - 1;
				this.pageList.unshift(this.pageList[0]-1);
				this.pageList.pop();
		} else {
			this.currentPageNumber -= 1;
		}
		this.updatePage(this.currentPageNumber);
	}

	private getTradeHistory(pageNumber:any) {
		this.isRequestProcessing = true;
		this.tradeService.getTradeHistory(pageNumber,this.maxDataPerPage).subscribe(
			(history:any) => {
				if(history && history.data) {
					let data = (history.data.length < this.maxDataPerPage && this.archieveRecords.hasOwnProperty(1))? this.mergeData(history.data, this.archieveRecords[1]) : history.data;
					this.updateHistoryRecord(data, pageNumber);
					if(pageNumber == this.currentPageNumber) this.updateView(pageNumber);
					this.isDataOutdated = false;
				} else if (history.success == false && history['message'] == 'Invalid User') {
					this.showReloginMessage();
				}
			});
	}

	private showReloginMessage() {
		let msg = (new TranslatePipe(this.translateService)).transform("Trade_History_Relogin_Message");
		swal({
			html: `<i style="color:#ffffff;">${msg}</i>`,
			timer: 3000,
			width: 300,
			padding: 20,
			allowOutsideClick: false,
			allowEscapeKey: false,
			background: '#030608 url(/assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
			showConfirmButton: false,
			target: '#body_full_screen'
		});
	}
	private getMultiArchieveData(pageNumber:number, archPageNum:number, offSet:number){
		this.isRequestProcessing = true;
		let firstParam = this.archieveRecords.hasOwnProperty(archPageNum.toString()) ? Observable.of(null) : this.tradeService.getArchiveHistory(archPageNum,this.maxDataPerPage);
		let secondParam = (archPageNum + 1 > this.archivePages) ? Observable.of(null) : this.tradeService.getArchiveHistory(archPageNum + 1,this.maxDataPerPage);
		Observable.forkJoin([firstParam, secondParam]).subscribe((results:any) => {
			let data;
			if(results[0] && results[0].data) this.updateArchiveRecord(results[0].data, archPageNum);
			if(results[1] && results[1].data){
				this.updateArchiveRecord(results[1].data, (archPageNum + 1));
				data = [...this.archieveRecords[archPageNum].slice(this.maxDataPerPage - offSet, this.maxDataPerPage),...results[1].data.slice(0, this.maxDataPerPage - offSet)];
			} else {
				data = [...this.archieveRecords[archPageNum].slice(this.maxDataPerPage - offSet, this.maxDataPerPage)];
			}
			this.updateHistoryRecord(data, pageNumber);
			if(pageNumber == this.currentPageNumber) this.updateView(pageNumber);
		})
	}

	private getTradeArchiveHistory(pageNumber:number, length:any){
		this.isRequestProcessing = true;
		let archivePageNum = pageNumber - this.tradePages;
		let data;
		if(this.offsetIndex == 0 && this.archieveRecords.hasOwnProperty(archivePageNum.toString())) {
			data = this.archieveRecords[archivePageNum];
		} else if(this.offsetIndex > 0
			&& this.archieveRecords.hasOwnProperty(archivePageNum.toString())
			&& this.archieveRecords.hasOwnProperty((archivePageNum + 1).toString())) {
			let next = archivePageNum + 1;
			data = [...this.archieveRecords[archivePageNum].slice(this.maxDataPerPage - this.offsetIndex, this.maxDataPerPage), ...this.archieveRecords[next].slice(0, this.maxDataPerPage - this.offsetIndex)];
		} else {
			if(this.offsetIndex == 0){
				this.tradeService.getArchiveHistory(archivePageNum, length).subscribe(
					(archiveHistory: any) => {
						this.updateArchiveRecord(archiveHistory.data, archivePageNum);
						this.updateHistoryRecord(archiveHistory.data, pageNumber);
						if(pageNumber == this.currentPageNumber) this.updateView(pageNumber);
						this.isDataOutdated = false;
					}
				);
			} else {
				this.getMultiArchieveData(pageNumber, archivePageNum, this.offsetIndex);
			}
			return;
		}
		this.updateHistoryRecord(data, pageNumber);
		this.updateView(pageNumber);
		this.isDataOutdated = false;
	}
	close() {
		this.disableClose = true;
		this.closeHistoryView.emit();
	}

	selectedLang(result: string) : string {
		if(this.wtStorageService.selected_lang === 'en'){
			if(result=='Loss') return 'wintxt';
			if(result=='Win') return 'losetxt';
			return 'yellowtxt';
		}
		else {
			if(result=='Loss') return 'losetxt';
			if(result=='Win') return 'wintxt';
			return 'yellowtxt';
		}
	}
	
}
