import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { BehaviorSubject } from 'rxjs/Rx';
import {WTHttpService} from '../../shared/service/wt-http.service';
import {WTStorageService} from '../../shared/service/wt-storage.service';
import {WTUtilService} from '../../shared/service/wt-util.service';
import {WTCrmService} from '../../shared/service/wt-crm.service';

@Injectable()
export class TradeService {

	constructor(private wthttpService: WTHttpService,
				private wtStorageService:WTStorageService,
				private wtCrmService:WTCrmService,
				private wtUtilService: WTUtilService) {
		this.wtStorageService.boBaseUrl = this.wtUtilService.config.BO_BASE_URL;
		this.wtStorageService.crmBaseUrl = this.wtUtilService.config.CRM_BASE_URL;
		this.wtStorageService.apiBaseUrl = this.wtUtilService.config.FOX_BASE_URL;
		if(!this.wtStorageService.selectedMt4Account) {
         	this.wtStorageService.selectedMt4Account = JSON.parse(sessionStorage.getItem('mt4Account'));
       	}
       	if(!this.wtStorageService.mt4AccountsList ) {
       		this.wtStorageService.mt4AccountsList = JSON.parse(sessionStorage.getItem('mt4AccountList'));
       	}
		this.wtStorageService.rememberMe = JSON.parse(sessionStorage.getItem('rememberMe'));
       	this.wtCrmService.fetchRefreshToken = true;
		this.wtCrmService.startTokenRefresh();
	}
	getAppConfig(){
		var headers = new Headers({});
    	headers.append('Domain', this.wtUtilService.config.DOMAIN);
    	headers.append('MerchantKey', this.wtUtilService.config.MERCHANT_KEY);
    	headers.append('lang', this.wtStorageService.selected_lang);
    	headers.append('Content-type','application/x-www-form-urlencoded; charset=UTF-8');
    	headers.append('IP', this.wtStorageService.ip);
		return this.wthttpService.get(`${this.wtStorageService.crmBaseUrl}${this.wtUtilService.config.APP_CONFIG_URI}`,
					 {headers:headers})
	}
	getTradeHistory(pageNumber:any, pageLength:any) {
		var headers = this.getApiHeaders(this.wtStorageService.crmToken);
		headers.append('mt4account', this.wtStorageService.selectedMt4Account.accountId);
		return this.wthttpService.get(`${this.wtStorageService.apiBaseUrl}${this.wtUtilService.config.FOX_HISTORY_URI}page-length=${pageLength}&page=${pageNumber}`,
					 {headers:headers})
	}
	getArchiveHistory(pageNumber:any, pageLength:any) {
		var headers = this.getApiHeaders(this.wtStorageService.crmToken);
		headers.append('mt4account', this.wtStorageService.selectedMt4Account.accountId);
		return this.wthttpService.get(`${this.wtStorageService.apiBaseUrl}${this.wtUtilService.config.FOX_HISTORY_ARCHIVE_URI}page-length=${pageLength}&page=${pageNumber}`,
		{headers:headers});
	}
	getPastCandles(symbol:string, period:string, count:string, lastCandleDate:string) {
		var headers = this.getHeaders(this.wtStorageService.selectedMt4Account.accessToken);
		headers.append('mt4account', this.wtStorageService.selectedMt4Account.accountId);
		if(lastCandleDate){
			return this.wthttpService.post(`${this.wtUtilService.config.BO_BASE_URL}/history/last/${symbol}?
				timeframe=${period}&count=${count}&openDate=${lastCandleDate}`,null, {headers:headers});
		} else {
			return this.wthttpService.post(`${this.wtUtilService.config.BO_BASE_URL}/history/last/${symbol}?
				timeframe=${period}&count=${count}`,null, {headers:headers});
		}
	}
	private getHeaders(token:any) {
		var headers = new Headers({});
    	headers.append('Domain', this.wtUtilService.config.DOMAIN);
    	headers.append('MerchantKey', this.wtUtilService.config.MERCHANT_KEY);
    	headers.append('lang', this.wtStorageService.selected_lang);
    	headers.append('Content-type','application/xml');
    	headers.append('IP', this.wtStorageService.ip);
    	headers.append('Authorization', `bearer ${token}`);

    	return headers;
	}
	private getApiHeaders(token:any) {
		var headers = new Headers({});
    	headers.append('token', token);
    	return headers;
	}

	logOut() {
		var headers = this.getHeaders(this.wtStorageService.crmToken);
		if(this.wtStorageService.selectedMt4Account)
			headers.append('mt4account',this.wtStorageService.selectedMt4Account.accountId);
		return this.wthttpService.post(`${this.wtStorageService.crmBaseUrl}${this.wtUtilService.config.LOGOUT_URI}`,null, {headers:headers});
	}
  getProfileInformation(id): any {
    var headers = this.getHeaders(this.wtStorageService.crmToken);
    const queryparam = id ? ('?mt4Id=' + id) : '';
    return this.wthttpService.get(this.wtStorageService.crmBaseUrl + `/v2/member/profile${queryparam}`, {headers: headers});
  }
  getBalance(): any {
    const headers = this.getHeaders(this.wtStorageService.crmToken);
    return this.wthttpService.get(this.wtStorageService.crmBaseUrl + `/v2/user-balance`, {headers: headers});
  }

}
