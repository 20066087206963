import { InjectionToken } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { WT_CONFIG } from '../config/wt.config';
import { WTUtilService } from './shared/service/wt-util.service';
import { NoActionLink } from './shared/directives/anchor-tag-action.directive';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
var WTUtil_CONFIG = new InjectionToken('WTUTIL');
export function WTUtilFactory(config) {
    return new WTUtilService(config);
}
var ɵ0 = '/' + WT_CONFIG.IMAGE_ROOT, ɵ1 = WT_CONFIG, ɵ2 = NoActionLink, ɵ3 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
