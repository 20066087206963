
    <div
      class="dynamic-field dynamic-field-layout form-select"
      [formGroup]="group">
      <select [formControlName]="config.name" [(ngModel)]="defaultValue">
        <option hidden disabled *ngIf="config.options.length > 1" value="">{{ config.placeholder }}</option>
        <option *ngFor="let option of config.options" [value]="option.id">
          {{ option.name ? option.name : option.id }}
        </option>
      </select>
      <div *ngIf="config.error" class="alert alert-danger">
        {{ config.error }}
      </div>
    </div>
  