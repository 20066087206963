/* To handle DST changes time 'Europe/Athens' time is used to calculate time.
 * However there is an issue with time conversion at BOAPI end. When DST is on, offset is not changed to +300,
 * in the response what we get. Hence if DST is on subtracting 1hr i.e., 3600000 from time, to display proper time in
 * highchart.
 */
import { Component, ViewChild, HostListener, ElementRef, OnInit, AfterViewInit, AfterViewChecked, Renderer2, OnDestroy } from '@angular/core';
// import '../../../node_modules/intl/locale-data/jsonp/en.js';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as moment from 'moment-timezone';
import swal from 'sweetalert2';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { PricehubService } from './shared/pricehub.service';
import { TradeshubService } from './shared/tradeshub.service';
import { TradeService } from './shared/trade.service';
import { ChartComponent } from './chart/chart.component';
import { OpenTradesComponent } from './openTrades/open-trades.component';
import { TradeHistoryComponent } from './history/history.component';
import { IndicatorsComponent } from './indicators/indicators.component';
import { IndicatorService } from './indicators/indicator.service';
import { WTStorageService } from '../shared/service/wt-storage.service';
import { WTUtilService } from '../shared/service/wt-util.service';
import { TranslatePipe } from '../shared/translate/translate.pipe';
import { DepositComponent } from '../shared/deposit/deposit.component';
import { WithdrawalComponent } from '../shared/withdrawal/withdrawal.component';
import { MT4AuthenticateComponent } from '../shared/mt4-authenticate/mt4-authenticate.component';
import { CustomSearchPipe } from '../shared/pipes/search-filter.pipe';
import { OnlyNumber } from '../shared/directives/onlynumber.directive';
import { WTCrmService } from '../shared/service/wt-crm.service';
import { ObjKeysLengthPipe } from '../shared/pipes/jsonobj-keys-length.pipe';
import { OrientationComponent } from '../shared/orientation/orientation.component';
import { UserProfileComponent } from '../shared/user-profile/user-profile.component';
import { IndicatorsStorageService } from '../shared/indicators/indicators-storage.service';
import { TranslateService } from '../shared/translate/translate.service';
import * as wtconfig from '../../config/wt.config';
import { DomSanitizer } from '@angular/platform-browser';
declare var Highcharts: any;
const noop = () => { };
var hasOwnProperty: any = Object.prototype.hasOwnProperty;

/**
 * This class represents the lazy loaded TradeComponent.
 */
@Component({
  moduleId: module.id,
  selector: 'sd-about',
  templateUrl: 'trade.component.html',
  styleUrls: ['trade.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})

export class TradeComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  @ViewChild('chart') chartComponent: ChartComponent;
  @ViewChild('openTrade') openTradesComponent: OpenTradesComponent;
  @ViewChild('tradeHistory') tradeHistoryComponent: TradeHistoryComponent;
  @ViewChild('indicators') indicatorsComponent: IndicatorsComponent;
  @ViewChild('divtradescreen') tradeScreenDiv: any;
  @ViewChild('extendSession') extendSession: ElementRef;
  @ViewChild('depositRef') depositRef: ElementRef;
  @ViewChild('referralRef') referralRef: ElementRef;
  @ViewChild('fullscreenRef') fullscreenRef: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  lang: string = localStorage.getItem('lanGuage');
  brandName: any = wtconfig.WT_CONFIG.BRAND_NAME;
  licenseId: number;
  group = 13;
  currentPrice: string;
  date: string;
  configuration: any;
  accountInfo: any;
  chart: any;
  chartData: Array<any>;
  chartTab: any = [];
  dropDowns: any = {};
  intervalList: any = [];
  isFavListEmpty: boolean = true;
  symbolConfiguration: any;
  favorites: any;
  selectedSymConfig: any = [];
  selectedChartIndex: any = -1;
  favCount: any = 0;
  selectedChart: any;
  trade: Object;
  removeIndex: any = 0;
  tradeAmount: number;
  maxAmountFlag: boolean = false;
  tradeAction: any;
  profit: number;
  tradeUpdate: any;
  totalExpAmount: number;
  openPositions: number = 0;
  currencySymbolPadding: number = 34;
  amountIpWidth: number = 50;
  searchInput: any;
  statusDescription: string = '';
  chartLoaded: boolean = false;
  showFSbutton: boolean = false;
  profileData: any;
  // fullScrView : boolean = false;
  initLoad: boolean = false;
  // isPortraitMode: boolean = false;
  isLoggingOut: boolean = false;
  accountSwitch: boolean = false;
  showCal: boolean = false;
  modalOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    windowClass: 'fadeInDown animated md',
    container: '#body_full_screen'
  };
  tradeValidate: boolean = false;
  crmToken: any;
  lastRecordDate: string;
  lastReqRecordDate: string;
  isSymbolLoaded: boolean = false;
  isMt4Switched: boolean = false;
  disableClose: boolean = false;
  secLeft: number = 60;
  orientationModalRef: any;
  orientationModalOpened: boolean = false;
  crmLogin: string;
  device: any;
  isMobileDevice: boolean = false;
  userAvatar: any;
  countryFlagUrl = 'assets/images/us-flag.jpg';
  userName: any;
  isDocumentLoaded = false;
  inFullScreenMode = false;
  windowHeight: number;
  headerWrapperHeight: number;
  openFullscreenRef: any;
  hasDemo: any;
  private oldLabelVal: any;
  private loadIndicator = true;
  private addPlot: boolean = false;
  private labelText: any;
  private sessionExtendDialog: NgbModalRef;
  private timer: any;
  private chartContainerWidth: any;
  public isConnected: Observable<boolean>;
  private isHidden: Observable<any>;
  private ignoreHashChange: boolean = false;
  private preAmount: number;

  constructor(private tradeshubService: TradeshubService, private renderer: Renderer2, private sanitizer: DomSanitizer,
    private tradeService: TradeService,
    private pricehubService: PricehubService,
    private modalService: NgbModal,
    private wtCrmService: WTCrmService,
    private router: Router,
    private idle: Idle,
    public wtStorageService: WTStorageService,
    public translateService: TranslateService,
    public wtUtilService: WTUtilService,
    private indicatorStorage: IndicatorsStorageService,
    private indicatorService: IndicatorService,
    private deviceService: DeviceDetectorService) {
    this.renderer.addClass(document.body, 'webtrader-chart');

    let self = this;
    this.wtStorageService.deviceInfo = this.deviceService.getDeviceInfo();
    this.device = this.wtStorageService.deviceInfo && this.wtStorageService.deviceInfo.device || null;
    if (this.device === "android"
      || this.device === "iphone"
      || (window.innerHeight > window.innerWidth && window.innerWidth < 640)) {
      this.isMobileDevice = true;
    }
    window.addEventListener("orientationchange", function () {
      self.applyOrientation();
    }, false);
    this.applyOrientation();
    this.isConnected = Observable.merge(
      Observable.of(navigator.onLine),
      Observable.fromEvent(window, 'online').map(() => true),
      Observable.fromEvent(window, 'offline').map(() => false));
    this.isHidden = Observable.fromEvent(document, 'visibilitychange').map(() => document.visibilityState);
    this.selectedSymConfig.winPayout = 0;
    this.tradeAmount = 0;
    this.preAmount = 0;
    this.wtStorageService.selectedSymbol = 'Symbols';
    this.selectedSymConfig.interval = 0;
    this.isConnected.subscribe((data: any) => {
      if (data == false) {
        swal({
          html: `<span style="color:#ffffff">${(new TranslatePipe(this.translateService))
            .transform("Internet_connection_is_lost_Reconnecting")}<span>`,
          width: 400,
          allowOutsideClick: false,
          allowEscapeKey: false,
          background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
          showConfirmButton: false,
          target: '#body_full_screen'
        });
      } else if (data == true) {
        swal.close();
        if (this.wtStorageService.selectedSymbol !== 'Symbols') {
          this.fetchOldPrice();
          this.indicatorStorage.resetIndicatorStorage();
        }
      }
    });
    this.isHidden.subscribe((data: any) => {
      if (document.hidden) {
        this.pricehubService.stopPricesHub();
        this.tradeshubService.stopTradesHub();
      } else {
        this.pricehubService.startPricesHub();
        this.tradeshubService.startTradesHub();
        if (this.wtStorageService.selectedSymbol !== 'Symbols') {
          this.fetchOldPrice();
          this.indicatorStorage.resetIndicatorStorage();
        }
      }
    });
    this.crmLogin = this.wtUtilService.config.CRM_APP;
    this.dropDowns = {
      closeSymSel: true,
      indicSel: true,
      calcSel: true
    }
    // sets an idle timeout of 14min.
    idle.setIdle(86400);
    // sets a timeout period of 60 seconds. after 15min of inactivity, the user will be considered timed out.
    idle.setTimeout(86400);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      if (this.sessionExtendDialog) this.sessionExtendDialog.dismiss('closing');
      this.secLeft = 60;
      this.idle.watch();
    });
    idle.onTimeout.subscribe(() => {
      this.sessionExtendDialog.dismiss('closing');
      this.secLeft = 60;
      this.logOut();
    });
    idle.onIdleStart.subscribe(() => noop);
    idle.onTimeoutWarning.subscribe(() => {
      if (this.secLeft == 60) {
        this.sessionExtendDialog = this.modalService.open(this.extendSession, { backdrop: 'static', windowClass: 'md', keyboard: false, container: '#body_full_screen' });
      }
      if (this.secLeft > 0) this.secLeft--;
    });

    this.pricehubService.getPrice()
      .subscribe(
        (data: any) => {
          this.newPriceUpdate(data);
        });
    setInterval(() => {
      this.date = moment(moment().tz("Europe/Athens").valueOf() + this.wtStorageService.offsetValue).tz("Europe/Athens").format('DD-MMM-YYYY hh:mm:ss');
    }, 1000);
    this.tradeshubService.getConfiguration().subscribe(
      (config: any) => {
        this.configuration = config;
        if (this.configuration.payoutConfigurations && this.configuration.payoutConfigurations.length > 0) {
          this.isSymbolLoaded = false;
          this.generateSymbolList();
          this.setFavorites(this.wtStorageService.selectedMt4Account.accountId);
          this.setCharts(this.wtStorageService.selectedMt4Account.accountId);
          let symbolName = null;
          let selectedSymbFound = false;
          let securityName = null;
          if (this.chart) {
            if (this.symbolConfiguration) {
              for (let security in this.symbolConfiguration) {
                for (let symbol in this.symbolConfiguration[security]) {
                  if (this.symbolConfiguration[security][symbol].length > 0 && !selectedSymbFound) {
                    if (!symbolName) {
                      symbolName = symbol;
                      securityName = security;
                      if (!this.selectedChart) break;
                    }
                    if (this.selectedChart && this.selectedChart.symbol === symbol) {
                      selectedSymbFound = true;
                      symbolName = symbol;
                      securityName = security;
                      break;
                    }
                  }
                };
              }
              if (this.selectedChart && selectedSymbFound) {
                if ((!this.initLoad || this.accountSwitch || this.selectedChart.symbol !== this.wtStorageService.selectedSymbol)
                  && (this.selectedChart.timeFrame.toLowerCase() !== this.wtStorageService.selectedTimeFrame)) {
                  this.wtStorageService.selectedTimeFrame = this.selectedChart.timeFrame.toLowerCase();
                  if (this.symbolConfiguration.constructor === Object && Object.keys(this.symbolConfiguration).length !== 0) {
                    this.changeSymbol(this.selectedChart.symbol, this.symbolConfiguration[securityName][this.selectedChart.symbol], false);
                  }
                } else if (this.wtStorageService.selectedSymbol !== 'Symbols') {
                  this.intervalList = this.symbolConfiguration[securityName][this.wtStorageService.selectedSymbol]
                  this.selectedSymConfig = this.clone(this.intervalList[0]);
                  this.accountSwitch = false;
                } else {
                  this.wtStorageService.selectedSymbol = symbolName;
                  this.wtStorageService.selectedTimeFrame = this.wtStorageService.defaultTimeFrame;
                  this.changeSymbol(this.wtStorageService.selectedSymbol, this.symbolConfiguration[securityName][this.wtStorageService.selectedSymbol], false);
                }
              } else {
                this.wtStorageService.selectedSymbol = symbolName;
                this.wtStorageService.selectedTimeFrame = this.wtStorageService.defaultTimeFrame;
                this.changeSymbol(this.wtStorageService.selectedSymbol, this.symbolConfiguration[securityName][this.wtStorageService.selectedSymbol], false);
              }
            };
            //	this.fetchOldPrice();
          }
        }
        else {
          this.chartLoaded = true;
          this.initLoad = true;
          this.accountSwitch = false;
          this.isSymbolLoaded = true;
          if (typeof this.configuration.payoutConfigurations !== 'undefined') {
            this.showErrorMessage('Trade_No_Symbols_Found');
          }
        }
      }
    );
    this.tradeshubService.getAccountInfoUpdate().subscribe(
      (accntInfo: any) => {
        this.accountInfo = accntInfo;
      }
    );
    this.tradeshubService.getSendTradeStatus().subscribe(
      (sendTradeStatus: any) => {
        if (sendTradeStatus.status != undefined && sendTradeStatus.status == 134) {
          this.statusDescription = (new TranslatePipe(this.translateService)).transform("Trade_Insufficient_Account_Balance");
        } else if (sendTradeStatus.status != undefined && sendTradeStatus.status == 148) {
          this.statusDescription = (new TranslatePipe(this.translateService)).transform("Trade_Too_many_orders");
        } else if (sendTradeStatus.status != undefined && sendTradeStatus.status == 132) {
          this.statusDescription = (new TranslatePipe(this.translateService)).transform("Trade_Market_is_closed");
        } else if (sendTradeStatus.status != undefined && sendTradeStatus.status == 3.0) {
          this.statusDescription = (new TranslatePipe(this.translateService)).transform("Trade_Invalid_parameters");
        } else if (sendTradeStatus.status != undefined && sendTradeStatus.status == -100) {
          this.statusDescription = (new TranslatePipe(this.translateService)).transform("Trade_Submit_order_failure");
        } else if (sendTradeStatus.status != undefined && sendTradeStatus.status == 131) {
          this.statusDescription = (new TranslatePipe(this.translateService)).transform("Trade_Invalid_Amount");
        } else if (sendTradeStatus.status != undefined && sendTradeStatus.status == -1) {
          this.statusDescription = (new TranslatePipe(this.translateService)).transform("Trade_Submit_order_failure");
        } else if (sendTradeStatus.status != undefined && sendTradeStatus.status == 64) {
          this.statusDescription = (new TranslatePipe(this.translateService)).transform("Trade_Account_Inactive");
        }
        if (sendTradeStatus.status != undefined && sendTradeStatus.status == 0) {
          swal({
            html: `<i style="color:#ffffff;">${(new TranslatePipe(this.translateService))
              .transform("Trade_Order_placed_successfully")}</i>`,
            timer: 1000,
            width: 300,
            padding: 20,
            background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
            showConfirmButton: false,
            target: '#body_full_screen'
          })
          this.ShowOpenTrade();
        } else if (sendTradeStatus.status != undefined && sendTradeStatus.status != 0) {
          swal({
            html: '<i style="color:#ffffff;">' + this.statusDescription + '</i> ',
            timer: 1000,
            width: 300,
            padding: 20,
            background: '#954553 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
            showConfirmButton: false,
            target: '#body_full_screen'
          }
          );
        }
      }
    );
    this.tradeshubService.getTradeUpdate().subscribe(
      (tradeUpdate: any) => {
        this.tradeUpdate = tradeUpdate;
        if (this.tradeUpdate && this.tradeUpdate.accountInfo)
          this.accountInfo.balance = this.tradeUpdate.accountInfo.balance;
      }
    );
    // this.tradeshubService.getSignalrStatus().subscribe(
    // 	(signalrStatus:any) => {
    // 		if(signalrStatus == "Internet connection is slow"){
    // 			swal({
    // 					text:`<span style="color:#ffffff">${(new TranslatePipe(this.translateService))
    // 								.transform("Internet_connection_is_slow")}<span>`,
    // 					timer:2000,
    // 					width: 400,
    // 					background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
    // 					showConfirmButton:false
    // 				});
    // 		}
    // 		else if(signalrStatus == "Internet connection is lost. Reconnecting....") {
    // 			swal({
    // 					text:`<span style="color:#ffffff">${(new TranslatePipe(this.translateService))
    // 								.transform("Internet_connection_is_lost_Reconnecting")}<span>`,
    // 					width: 400,
    // 					allowOutsideClick: false,
    // 					allowEscapeKey :false,
    // 					background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
    // 					showConfirmButton:false
    // 				});
    // 			swal.disableButtons();
    // 		}
    // 		else if(signalrStatus == "Connecting...."){
    // 			swal({
    // 					text:`<span style="color:#ffffff">${(new TranslatePipe(this.translateService))
    // 								.transform("Connecting")}<span>`,
    // 					width: 400,
    // 					allowOutsideClick: false,
    // 					allowEscapeKey :false,
    // 					background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
    // 					showConfirmButton:false
    // 				});
    // 			swal.disableButtons();
    // 		}else if (signalrStatus == "Connection established."){
    // 			swal.close();
    // 		}
    // 	}
    // );


  }
  applyOrientation() {
    if (window.innerHeight > window.innerWidth || window.orientation === 0) {
      this.wtStorageService.isPortraitMode = true;
    } else {
      this.wtStorageService.isPortraitMode = false;
    }
  }
  ngAfterViewChecked() {
    if (this.chartContainerWidth != this.tradeScreenDiv.nativeElement.offsetWidth && this.chart) {
      this.chart.setSize();
    }
    this.chartContainerWidth = this.tradeScreenDiv.nativeElement.offsetWidth;
  }
  ngAfterViewInit() {
    let self = this;
    // if (this.device === "android" || this.device === "iphone") {
    window.onresize = function (event) {
      let document: any = window.document;
      if (!document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement) {
        self.applyOrientation();
      }
    }
    // this.applyOrientation();
    // }

    this.chart = this.chartComponent.getChart();
    // Storing settings in localstorage for the first time

    let settings = JSON.parse(localStorage.getItem('settings'));
    if (settings == null || !settings[this.wtStorageService.selectedMt4Account.accountId]) {
      let settings = {
        [this.wtStorageService.selectedMt4Account.accountId]:
          { sound: this.wtStorageService.soundSetting, oneClick: this.wtStorageService.oneclickSetting }
      };
      localStorage.setItem('settings', JSON.stringify(settings));
    } else {
      this.wtStorageService.oneclickSetting = settings[this.wtStorageService.selectedMt4Account.accountId].oneClick;
      this.wtStorageService.soundSetting = settings[this.wtStorageService.selectedMt4Account.accountId].sound;
    }

    if (localStorage.getItem('lanGuage') != null) {
      this.wtStorageService.selected_lang = localStorage.getItem('lanGuage');
      this.translateService.selectLang(localStorage.getItem('lanGuage'));
    }

    if (localStorage.getItem('soundSetting') != null) {
      this.wtStorageService.soundSetting = JSON.parse(localStorage.getItem("soundSetting"));
    }

    if (localStorage.getItem('oneclickSetting') != null) {
      this.wtStorageService.oneclickSetting = JSON.parse(localStorage.getItem("oneclickSetting"));
    }

    let symbolName = null;
    let selectedSymbFound = false;
    let securityName = null;
    if (this.isSymbolLoaded) {
      for (let security in this.symbolConfiguration) {
        for (let symbol in this.symbolConfiguration[security]) {
          if (this.symbolConfiguration[security][symbol].length > 0 && !selectedSymbFound) {
            if (!symbolName) {
              symbolName = symbol;
              securityName = security;
              this.wtStorageService.selectedTimeFrame = this.wtStorageService.defaultTimeFrame;
              if (!this.selectedChart) break;
            }
            if (this.selectedChart && this.selectedChart.symbol === symbol) {
              selectedSymbFound = true;
              symbolName = symbol;
              securityName = security;
              this.wtStorageService.selectedTimeFrame = this.selectedChart.timeFrame.toLowerCase();
              break;
            }
          }
        };
      }
      if (symbolName) {
        this.changeSymbol(symbolName, this.symbolConfiguration[securityName][symbolName], false);
      }
    }
    Highcharts.addEvent(this.chart, 'redraw',
      (event: any) => {
        if (this.chart.xAxis) {
          let extremes = this.chart.xAxis[0].getExtremes();
          if (extremes && (extremes.min == extremes.dataMin || extremes.min < this.chart.series[0].xData[1]) && this.chartLoaded) {
            this.getMorePastData();
          }
        }
        this.alignCurrentPriceLabel();
        this.openTradesComponent.alignPlotlineLabel();
      }
    );
    Highcharts.addEvent(this.chart.xAxis[0], 'setExtremes',
      (event: any) => {
        this.openTradesComponent.alignPlotlineLabel();
        this.alignCurrentPriceLabel();
      }
    );
    this.wtUtilService.flipColors();
    this.chartComponent.flipColors();
    this.idle.watch();
  }
  resetCandlesView() {
    if (this.chartData.length > 0) {
      this.chart.xAxis[0].setExtremes(this.chartData[this.chartData.length > 25 ? this.chartData.length - 25 : 0][0],
        this.chartData[this.chartData.length - 1][0], true, true);
      this.chart.yAxis[0].setExtremes(this.chart.yAxis[0].getExtremes().dataMin,
        this.chart.yAxis[0].getExtremes().dataMax, true, true);
    }
  }
  private newPriceUpdate(newPrice: any) {
    if (newPrice.bar && newPrice.bar.close && newPrice.info &&
      newPrice.info.symbol == this.wtStorageService.selectedSymbol &&
      newPrice.info.timeframe.toLowerCase() == this.wtStorageService.selectedTimeFrame.toLowerCase()) {
      this.currentPrice = this.doPrecision(newPrice.bar.close);
      try {
        if (this.chartData && this.chartData.length > 0 && this.chart) {
          // if(this.chartData[this.chartData.length-1][0] == (moment.tz("Europe/Athens").isDST()?
          // 						new Date(newPrice.bar.datetime).getTime()-3600000 :new Date(newPrice.bar.datetime).getTime())) {
          if (this.chartData[this.chartData.length - 1][0] == new Date(newPrice.bar.datetime).getTime()) {
            this.chartData[this.chartData.length - 1] = [
							/*moment.tz("Europe/Athens").isDST()?
								new Date(newPrice.bar.datetime).getTime()-3600000
										:new Date(newPrice.bar.datetime).getTime(),*/
              new Date(newPrice.bar.datetime).getTime(),
              newPrice.bar.open,
              newPrice.bar.high,
              newPrice.bar.low,
              newPrice.bar.close
            ];
            this.chart.series[0].data[this.chart.series[0].data.length - 1].update(
              Object.assign(this.chartData[this.chartData.length - 1]));
            this.indicatorService.updateNewPoint(this.chart, [this.chart.series[0].xData, this.chart.series[0].yData],
              this.indicatorsComponent, 'update');

          } else {
            this.chartData.push([
							/*moment.tz("Europe/Athens").isDST()?
													new Date(newPrice.bar.datetime).getTime()-3600000
															:new Date(newPrice.bar.datetime).getTime(),*/
              new Date(newPrice.bar.datetime).getTime(),
              newPrice.bar.open,
              newPrice.bar.high,
              newPrice.bar.low,
              newPrice.bar.close
            ]);
            this.chart.series[0].addPoint(Object.assign([], this.chartData[this.chartData.length - 1]), true, false, true);
            this.indicatorService.updateNewPoint(this.chart, [this.chart.series[0].xData, this.chart.series[0].yData],
              this.indicatorsComponent, 'addpoint');
          }
          this.chart.yAxis[0].setExtremes(this.chart.yAxis[0].getExtremes().dataMin,
            this.chart.yAxis[0].getExtremes().dataMax, true, true);
          if (!this.addPlot) {
            let price = this.doPrecision(this.chartData[this.chartData.length - 1][4]);
            this.chart.yAxis[0].addPlotLine({
              value: newPrice.bar.close,
              color: '#d6d41f',
              arrow: true,
              width: 2,
              id: 'currentPrice',
              zIndex: 999,
              label: {
                align: 'right',
                backgroundColor: '#d6d41f',
                textAlign: 'left',
                verticalAlign: 'middle',
                text: '<span class="current-price-indicator">' +
                  price.toString().substr(0, 4) + '<span class="dec-bold">' +
                  price.toString().substr(4, price.toString().length) + '</span></span>',
              },
            });
            this.chart.yAxis[0].plotLinesAndBands[this.getPlotLineIndex()].label
              .yPosition = this.chart.yAxis[0].toPixels(newPrice.bar.close)
            this.addPlot = true;
            this.alignCurrentPriceLabel();
          }
        }
      } catch (error) {
      }
    }
  }
  private doPrecision(num: any) {
    let numString, splitNum = [], precNum = '';
    if (!num) return '';
    numString = num.toString();
    if (numString.indexOf('.') > -1) {
      splitNum = numString.split('.');
      if (splitNum[1]) {
        precNum = splitNum[1] + '0'.repeat(5 - splitNum[1].length);
      }
    } else if (numString.indexOf('.') == -1) {
      splitNum[0] = numString;
      precNum = '00000';
    }
    return `${splitNum[0]}.${precNum}`;
  }
  private fetchOldPrice() {
    this.chartLoaded = false;
    this.chartData = [];
    if (this.wtStorageService.selectedSymbol !== 'Symbols') {
      this.tradeService.getPastCandles(this.wtStorageService.selectedSymbol, this.wtStorageService.selectedTimeFrame, '200', null).subscribe(
        (oldPriceList: any) => {
          if (this.wtStorageService.selectedSymbol === oldPriceList.info.symbol
            && this.wtStorageService.selectedTimeFrame.toLowerCase() === oldPriceList.info.timeframe.toLowerCase()) {
            var priceList: any = [];
            for (var i = 0; i < oldPriceList.bars.length; i++) {
              priceList[i] = [
                /*moment(oldPriceList.bars[i]['datetime']).tz("Europe/Athens").isDST() ? new Date(oldPriceList.bars[i]['datetime']).getTime() - 3600000
                  : new Date(oldPriceList.bars[i]['datetime']).getTime(), // change required to handle daylight saving*/
                new Date(oldPriceList.bars[i]['datetime']).getTime(),
                oldPriceList.bars[i]['open'],
                oldPriceList.bars[i]['high'],
                oldPriceList.bars[i]['low'],
                oldPriceList.bars[i]['close']
              ];
            }
            this.lastRecordDate = oldPriceList.bars[0].datetime;
            this.chartData = priceList;
            this.chart.series[0].setData(Object.assign([], this.chartData), true, true, true);
            this.indicatorService.updatePreviousPoints(this.chart, [this.chart.series[0].xData, this.chart.series[0].yData], this.indicatorsComponent);
            if (this.chartData[this.chartData.length - 25]) this.resetCandlesView();
            this.chartLoaded = true;
            this.initLoad = true;
            this.accountSwitch = false;
            this.chartComponent.zoomCount = 0;
            this.openTradesComponent.addAllPlotLines();
            // this.chart.update({
            // 	mapNavigation: { enableButtons: true }
            // });
            if (this.wtStorageService.deviceInfo.device === "android" || this.wtStorageService.deviceInfo.device === "iphone" || this.wtStorageService.deviceInfo.device === "ipad") {
              /* this.chart.update({
                exporting: {
                  buttons: {
                    customButton: {
                      y: -65
                    }
                  }
                }
              }) */
            }
            if (this.loadIndicator) {
              this.indicatorsComponent.checkIndicators();
              this.loadIndicator = false;
            }

          }
        }
      );
    } else {
      this.chartLoaded = true;
    }
  }
  openDeposit(depositRef: any) {
    this.showCal = false;
    //this.modalService.open(DepositComponent, { backdrop: 'static', keyboard: false, windowClass: 'depositModal' });
    this.modalService.open(depositRef, { backdrop: 'static', windowClass: 'sm', keyboard: false, container: '#body_full_screen' });
    //this.modalService.open(DepositComponent, { backdrop: 'static', keyboard: false, windowClass: 'depositModal' });

  }
  openWithdraw(withdrawRef: any) {
    if (this.wtStorageService.selectedMt4Account.demo) {
      swal({
        html: `<span style="color:#ffffff">${(new TranslatePipe(this.translateService))
          .transform('demoAccountCannotProceed')}<span>`,
        width: 400,
        timer: 1000,
        allowOutsideClick: false,
        allowEscapeKey: false,
        background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
        showConfirmButton: false,
        target: '#body_full_screen'
      });
      return false;
    }
        this.modalService.open(withdrawRef, {
                  backdrop: 'static',
                  windowClass: 'sm deposit-modal',
                  keyboard: false,
                  container: '#body_full_screen'
                });
	}
  // openWithdraw(withdrawRef: any) {
  //   this.modalService.open(WithdrawalComponent, { backdrop: 'static', keyboard: false, windowClass: 'depositModal' });
  // }
  // changeOrientation() {
  //this.modalService.open(DepositComponent);
  // if (this.device == "iphone" && !this.wtStorageService.isPortraitMode) {
  // if (this.orientationModalOpened) {
  // 	this.orientationModalRef.close();
  // 	this.orientationModalOpened = false;
  // }
  // } else if (!this.orientationModalOpened) {
  // this.orientationModalOpened = true;
  //this.orientationModalRef = this.modalService.open(OrientationComponent, { backdrop: 'static', windowClass: 'sm', keyboard: false, container: '#body_full_screen' });
  // this.orientationModalRef.result.then((result: any) => {
  // 	this.orientationModalOpened = false;
  // }, (reason: any) => {
  // 	this.orientationModalOpened = false;
  // });
  // }
  // this.modalService.open(orientationRef,{backdrop:'static', windowClass:'sm', keyboard:false, container:'#body_full_screen'});
  // }
  makeTrade(tradeAction: any, oneclick: any) {
    this.tradeAction = tradeAction;
    this.showCal = false;
    if (this.accountInfo.balance < this.selectedSymConfig.minAmount || this.tradeAmount > this.accountInfo.balance) {
      swal({
        html: `<i style="color:#ffffff;">${(new TranslatePipe(this.translateService))
          .transform("Trade_Not_enough_money")}</i>`,
        timer: 1000,
        width: 300,
        padding: 20,
        background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
        showConfirmButton: false,
        target: '#body_full_screen'
      });
    } else if (this.tradeAmount < this.selectedSymConfig.minAmount || this.tradeAmount > this.selectedSymConfig.maxAmount) {
      swal({
        html: `<i style="color:#ffffff;"> ${(new TranslatePipe(this.translateService))
          .transform("Trade_Amount_Range_1")} ` + this.selectedSymConfig.minAmount + `${(new TranslatePipe(this.translateService))
            .transform("Trade_Amount_Range_2")}` + this.selectedSymConfig.maxAmount + `${(new TranslatePipe(this.translateService))
              .transform("Trade_Amount_Range_3")}` + '</i> ',
        timer: 1000,
        width: 300,
        padding: 20,
        background: '#954553 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
        showConfirmButton: false,
        target: '#body_full_screen'
      });

    } /* else if (this.tradeAmount % this.selectedSymConfig.stepAmount > 0) {
			swal({
				html: `<i style="color:#ffffff;"> ${(new TranslatePipe(this.translateService))
					.transform("Trade_Amount_Multiples_Of")}` + this.selectedSymConfig.stepAmount + `${(new TranslatePipe(this.translateService))
						.transform("Trade_Amount_Multiples_Of_After")}` + '</i> ',
				timer: 1000,
				width: 300,
				padding: 20,
				background: '#954553 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
				showConfirmButton: false,
				target: '#body_full_screen'
			});
		} */ // commented code as always the amount allowed is not multiple of stepamount. its is amount * stepAmount +minamount
    else {
      if (this.wtStorageService.oneclickSetting == true) {
        this.sendTrade(tradeAction);
      }
      else {
        this.modalService.open(oneclick, { backdrop: 'static', windowClass: 'oneClick bounceIn animated md', keyboard: false, container: '#body_full_screen' }).
          result.then((result: any) => {
          },
            (reason: any) => {
              this.sendTrade(tradeAction);
            });;
      }
    }
  }
  sendTrade(tradeAction: any) {
    this.tradeAction = tradeAction;
    this.trade = {
      "action": tradeAction,
      "amount": this.tradeAmount,
      "symbol": this.wtStorageService.selectedSymbol,
      "interval": this.selectedSymConfig.interval,
      "payout": this.selectedSymConfig.winPayout,
      "appType": this.wtUtilService.config.appType,
    }
    this.tradeshubService.sendTrade(this.trade);
  }
  logOut() {
    this.isLoggingOut = true;
    this.tradeService.logOut()
      .subscribe((logoutInfo: any) => {
        this.showLogoutMessage();
      }, (err: any) => {
        this.showLogoutMessage();
      });
  }
  showLogoutMessage() {
    let msg = (new TranslatePipe(this.translateService)).transform("Logout_Success_Message");
    this.isLoggingOut = false;
    sessionStorage.clear();
    this.wtStorageService.clearStorage();
    this.pricehubService.stopPricesHub();
    this.tradeshubService.stopTradesHub();
    this.idle.stop();
    this.wtCrmService.fetchRefreshToken = false;
    swal({
      html: `<i style="color:#ffffff;">${msg}</i>`,
      timer: 1000,
      width: 300,
      padding: 20,
      allowOutsideClick: false,
      allowEscapeKey: false,
      background: '#030608 url(/assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
      showConfirmButton: false,
      target: '#body_full_screen'
    })
    setTimeout(() => {
      this.router.navigate(['../']);
      // window.location.reload();
    }, 1000);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  showReloginMessage() {
    let msg = (new TranslatePipe(this.translateService)).transform("Trade_History_Relogin_Message");
    swal({
      html: `<i style="color:#ffffff;">${msg}</i>`,
      timer: 3000,
      width: 300,
      padding: 20,
      allowOutsideClick: false,
      allowEscapeKey: false,
      background: '#030608 url(/assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
      showConfirmButton: false,
      target: '#body_full_screen'
    });
  }
  showErrorMessage(message) {
    let msg = (new TranslatePipe(this.translateService)).transform(message);
    swal({
      html: `<i style="color:#ffffff;">${msg}</i>`,
      timer: 3000,
      width: 300,
      padding: 20,
      allowOutsideClick: false,
      allowEscapeKey: false,
      background: '#030608 url(/assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
      showConfirmButton: false,
      target: '#body_full_screen'
    });
  }

  setTimeFrame(timeFrame: any) {
    this.showCal = false;
    if (this.wtStorageService.selectedTimeFrame === timeFrame.toLowerCase()) return;
    this.wtStorageService.selectedTimeFrame = timeFrame.toLowerCase();
    this.pricehubService.priceHubProxy.invoke('subscribe', this.wtStorageService.selectedSymbol, this.wtStorageService.selectedTimeFrame);
    this.fetchOldPrice();
    this.indicatorStorage.resetIndicatorStorage();
    let currentSymbolTimeframe = {
      "symbol": this.wtStorageService.selectedSymbol,
      "timeFrame": this.wtStorageService.selectedTimeFrame.toUpperCase()
    }
    this.selectedChartIndex = this.getIndex(this.chartTab, currentSymbolTimeframe);
    this.saveToLocalStorage(currentSymbolTimeframe, 'chart_tab_selected', this.wtStorageService.selectedMt4Account.accountId);

  }

  showUserProfile(userProfileRef: any) {
    this.modalService.open(UserProfileComponent, { backdrop: 'static', keyboard: false, windowClass: 'depositModal' });
  }
  isShowOpenTrade() {
    this.openTradesComponent.tradeViewLoaded = true;
    this.openTradesComponent.showOpenTrades = this.openTradesComponent.showOpenTrades ? false : true;

    this.openTradesComponent.closeFromTradeComponent();

    this.toggleDpDown(this.openTradesComponent.showOpenTrades, 'skipCloseView');
    this.tradeHistoryComponent.showTradesHistory = false;
    this.chart.xAxis[0].setExtremes(this.chartData[this.chartData.length > 25 ? this.chartData.length - 25 : 0][0],
      this.chartData[this.chartData.length - 1][0], true, true);
  }
  ShowOpenTrade() {
    this.openTradesComponent.tradeViewLoaded = true;
    this.openTradesComponent.showOpenTrades =  true;

    this.openTradesComponent.closeFromTradeComponent();

    this.toggleDpDown(this.openTradesComponent.showOpenTrades, 'skipCloseView');
    this.tradeHistoryComponent.showTradesHistory = false;
    this.chart.xAxis[0].setExtremes(this.chartData[this.chartData.length > 25 ? this.chartData.length - 25 : 0][0],
      this.chartData[this.chartData.length - 1][0], true, true);
  }

  updateHistory(data: any) {
    this.tradeHistoryComponent.isDataOutdated = true;
    this.tradeHistoryComponent.totalRecordsCount++;
    this.tradeHistoryComponent.tradeRecordsCount++;
    if (this.tradeHistoryComponent.showTradesHistory) this.tradeHistoryComponent.openTradeClosed(data);
  }

  isShowTradeHistory(show: any) {
    if (show && (this.wtStorageService.crmToken == null || this.wtStorageService.crmToken == undefined)) {
      this.showReloginMessage();
      return;
    }
    this.tradeHistoryComponent.tradeViewLoaded = true;
    // this.tradeHistoryComponent.isHistoryEmpty = false;
    if (this.tradeHistoryComponent.showTradesHistory && show) return;
    if (!this.tradeHistoryComponent.showTradesHistory) {
      this.tradeHistoryComponent.initTradeHistory();
    }
    this.tradeHistoryComponent.showTradesHistory = show;
    if (this.tradeHistoryComponent.showTradesHistory) {
      this.toggleDpDown(this.tradeHistoryComponent.showTradesHistory, 'skipCloseView');
      this.openTradesComponent.showOpenTrades = false;
      this.chart.xAxis[0].setExtremes(this.chartData[this.chartData.length - 10][0],
        this.chartData[this.chartData.length - 1][0], true, true);
    } else {
      this.chart.xAxis[0].setExtremes(this.chartData[this.chartData.length > 25 ? this.chartData.length - 25 : 0][0],
        this.chartData[this.chartData.length - 1][0], true, true);
    }
  }
  showSettings(setting: any) {
    this.modalService.open(setting, { container: '#body_full_screen', backdrop: 'static' });
    this.lang = localStorage.getItem('lanGuage');
  }
  toggleDpDown(data: any, opType: any) {

    if (!data && opType && this.dropDowns[opType] !== undefined) {
      if (opType === 'closeSymSel') this.searchInput = null;
      this.dropDowns[opType] = !data;
    }
    if (!data) return;
    if (opType != 'calcSel' && this.isMobileDevice) {
      this.showCal = false;
      this.dropDowns.calcSel = true;
    }
    if (opType != 'skipCloseView' && (this.tradeHistoryComponent.showTradesHistory || this.openTradesComponent.showOpenTrades)) {
      this.closeViews()
    }
    for (let key in this.dropDowns) {
      if (key === opType) {
        this.dropDowns[key] = !data;
      } else {
        this.dropDowns[key] = true;
      }
    }
  }
  private closeViews() {
    let time = 350;
    if (this.tradeHistoryComponent.showTradesHistory) time = 450;
    this.tradeHistoryComponent.showTradesHistory = false;
    this.openTradesComponent.showOpenTrades = false;
    this.chart.xAxis[0].setExtremes(this.chartData[this.chartData.length > 25 ? this.chartData.length - 25 : 0][0],
      this.chartData[this.chartData.length - 1][0], true, true);
  }
  changeSymbol(selectedSymbol: any, selSymbolIntervalList: any, isChartTabUpdate: any) {
    this.dropDowns.closeSymSel = true;
    if (this.wtStorageService.selectedSymbol === selectedSymbol && isChartTabUpdate) return;
    this.currentPrice = '';
    this.wtStorageService.selectedSymbol = selectedSymbol;
    this.intervalList = selSymbolIntervalList;
    this.selectedSymConfig = this.clone(selSymbolIntervalList[0]);
    this.tradeAmount = parseInt(this.selectedSymConfig.minAmount);
    this.preAmount = this.tradeAmount;
    this.pricehubService.subscribeNewSymPrice()
    this.chart.yAxis[0].removePlotLine('currentPrice');
    if (this.labelText) this.labelText.destroy();
    this.openTradesComponent.removeAllPlotLines();
    this.addPlot = false;
    this.fetchOldPrice();
    this.indicatorStorage.resetIndicatorStorage();
    let currentSymbolTimeframe = {
      "symbol": this.wtStorageService.selectedSymbol,
      "timeFrame": this.wtStorageService.selectedTimeFrame.toUpperCase()
    }
    this.saveToLocalStorage(currentSymbolTimeframe, 'chart_tab_selected', this.wtStorageService.selectedMt4Account.accountId);
    this.selectedChartIndex = this.getIndex(this.chartTab, currentSymbolTimeframe);
  }

  removeFromFav(fav: any) {
    this.favCount = this.favCount - 1;
    this.favorites[fav]['isFav'] = false;
    let favLocal = this.getFavoritesLocal(this.wtStorageService.selectedMt4Account.accountId);
    favLocal[fav] = undefined;
    favLocal = this.filterEmptyVal(favLocal);
    this.saveToLocalStorage(favLocal, 'favourites', this.wtStorageService.selectedMt4Account.accountId);
  }
  addToFavourite(selectedSymbol: any, opType: any) {
    let favLocal = this.getFavoritesLocal(this.wtStorageService.selectedMt4Account.accountId);
    if (!this.favorites[selectedSymbol]['isFav']) {
      this.favorites[selectedSymbol]['isFav'] = true;
      this.favCount = this.favCount + 1;
      favLocal[selectedSymbol] = opType;
    } else {
      this.favorites[selectedSymbol]['isFav'] = false;
      this.favCount = this.favCount - 1;
      delete favLocal[selectedSymbol];
    }
    this.saveToLocalStorage(favLocal, 'favourites', this.wtStorageService.selectedMt4Account.accountId);
  }
  private setFavorites(accId: any) {
    let favoritesData = JSON.parse(localStorage.getItem('favourites'));
    this.favorites = (favoritesData && favoritesData[accId]) || {};
    this.favorites = this.filterUnavailableSymbols(this.favorites);
  }
  private filterUnavailableSymbols(fav: any) {
    let symbols: Array<any> = [];
    let allSymbols: any = {};
    let favKeys = Object.keys(fav);
    this.favCount = 0;
    for (let security in this.symbolConfiguration) {
      let symbolKeys = (Object.keys(this.symbolConfiguration[security]))
      symbols.push(...symbolKeys);
      for (let symbol of symbolKeys) {
        if (this.symbolConfiguration[security][symbol] && this.symbolConfiguration[security][symbol].length > 0) {
          allSymbols[symbol] = { security }
          if (favKeys.indexOf(symbol) !== -1) {
            allSymbols[symbol]['isFav'] = true;
            this.favCount = this.favCount + 1;
          }
        }
      }
    }
    return allSymbols;
  }
  private getFavoritesLocal(accId: any) {
    let fav = JSON.parse(localStorage.getItem('favourites'));
    let data = (fav && fav[accId]) ? fav[accId] : {};
    return data;
  }
  private filterChartTabs(data: any) {
    let resultTabs: any = [];
    let mapSymbol: any = [];
    let availSymbol: any = [];
    for (let temp of data) {
      if (mapSymbol.indexOf(temp.symbol) === -1) mapSymbol.push(temp.symbol);
    }
    for (let security in this.symbolConfiguration) {
      let symbolKeys = (Object.keys(this.symbolConfiguration[security]))
      for (let symbol of symbolKeys) {
        if (this.symbolConfiguration[security][symbol]
          && this.symbolConfiguration[security][symbol].length > 0
          && mapSymbol.indexOf(symbol) !== -1) {
          availSymbol.push(symbol)
        }
      }
    }
    data.forEach((value: any, index: any) => {
      if (availSymbol.indexOf(value.symbol) !== -1) resultTabs.push(value);
    })
    return resultTabs;
  }
  private setCharts(accId: any) {
    let chartTabs = JSON.parse(localStorage.getItem('chart_tab'));
    if (chartTabs && chartTabs[accId] && (chartTabs[accId].length > 0)) {
      chartTabs[accId] = this.filterChartTabs(chartTabs[accId]);
      this.chartTab = chartTabs[accId].length > 5 ? chartTabs[accId].slice(chartTabs[accId].length - 5, chartTabs[accId].length) : chartTabs[accId];
      this.saveToLocalStorage(this.chartTab, 'chart_tab', accId);
    }
    if (JSON.parse(localStorage.getItem('chart_tab_selected'))) {
      this.selectedChart = (JSON.parse(localStorage.getItem('chart_tab_selected')))[accId];
    }
  }
  private getFromLocalStorage(getKey: any, updateKey: any) {
    let storedData = JSON.parse(localStorage.getItem(getKey)) || {};
    return storedData[updateKey] || {};
  }
  private saveToLocalStorage(data: any, getKey: any, updateKey: any) {
    let storedData = JSON.parse(localStorage.getItem(getKey)) || {};
    storedData[updateKey] = data;
    localStorage.setItem(getKey, JSON.stringify(storedData));
  }

  private updateLocalStorage(origArr: any, oldObj: any, newObj: any) {
    let storedData = this.getFromLocalStorage('chart_tab', this.wtStorageService.selectedMt4Account.accountId);
    let oldObjInChart = this.isInChartTab(origArr, oldObj || {});
    let newObjInLocal = this.isInChartTab(storedData, newObj || {});
    let updataLocalStorage = false;
    if (!oldObjInChart && oldObj != null) {
      storedData = this.removeFromArray(storedData, oldObj);
      updataLocalStorage = true;
    }
    if (!newObjInLocal && newObj != null) {
      storedData.push(this.chartTab[this.selectedChartIndex])
      updataLocalStorage = true;
    }
    if (updataLocalStorage) this.saveToLocalStorage(storedData, 'chart_tab', this.wtStorageService.selectedMt4Account.accountId);

  }

  goFS() {
    let document: any = window.document;
    let fs = document.getElementById("body_full_screen");
    if (!document.fullscreenElement &&
      !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
      if (fs.requestFullscreen) {
        fs.requestFullscreen();
      } else if (fs.msRequestFullscreen) {
        fs.msRequestFullscreen();
      } else if (fs.mozRequestFullScreen) {
        fs.mozRequestFullScreen();
      } else if (fs.webkitRequestFullscreen) {
        fs.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  getProfileInfo() {

    if (this.wtStorageService.selectedMt4Account.accountId) {
      this.tradeService.getProfileInformation(this.wtStorageService.selectedMt4Account.accountId)
        .subscribe(
          result => {

            if (result.success && result.data) {
              this.profileData = result.data;
              this.userAvatar = this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + this.profileData['profileImage']);
              if (this.profileData['firstName']) {
                this.userName = this.profileData['firstName'] + ' ' + this.profileData['lastName'];
              } else {
                this.userName = this.profileData['name'] || '';
              }

            } else {
              // failed
              // alert("Error 1");
            }
          }, err => {
            // failed
            // alert("Error 2");
          }
        );
    }
  }
  ngOnInit() {

    this.windowHeight = window.innerHeight;
    this.headerWrapperHeight = this.windowHeight - (document.getElementsByClassName('header-wrapper')[0].clientHeight);
    if (localStorage.getItem('lanGuage') === 'zh') {
      this.group = 13;
      this.countryFlagUrl = 'assets/images/zh-flag.png';
    } else if (localStorage.getItem('lanGuage') === 'en') {
      this.countryFlagUrl = 'assets/images/us-flag.jpg';
      this.group = 19;
    }
    // const custom_variables = [
    // 	{ name: wtconfig.WT_CONFIG.mtVersion.mtx + 'ID', value: this.wtStorageService.selectedMt4Account.accountId },
    // 	{ name: 'Email', value: this.wtStorageService.email },
    // 	{ name: 'Name', value: this.wtStorageService.firstName + ' ' + this.wtStorageService.lastName },
    // 	{ name: 'Preferred Language', value: this.translateService.currentLang() }
    // ];
    // if (wtconfig.WT_CONFIG.liveChatLicenseId) {
    // 	const lcAPI = setInterval(() => {
    // 		if (window['LC_API']) {
    // 			window['LC_API'].set_custom_variables(custom_variables);
    // 			clearInterval(lcAPI);
    // 		}
    // 	}, 3000);
    // }
    // this.getDashboardInformation();
    this.getProfileInfo();

    // setTimeout(() => {
    // 	this.openReferral(this.referralRef);
    // }, 15 * 60 * 1000);



    setTimeout(() => {
      if (this.accountInfo.balance === undefined || this.accountInfo.balance === null || this.accountInfo.balance <= 0) {
        this.openDeposit(this.depositRef);
      }
    }, 30000);

    setInterval(() => {
      // this.loginService.refreshToken(this.wtStorageService.crmToken);
    }, 840000);

  }

  onResize(event) {
    this.windowHeight = window.innerHeight;
    this.headerWrapperHeight = this.windowHeight - (document.getElementsByClassName('header-wrapper')[0].clientHeight);
    // window width
  }


  mapBalance() {
    let balances = [];
    this.tradeService.getBalance()
      .subscribe(
        result => {
          if (result.success && result.data) {
            balances = result.data;
            this.wtStorageService.mt4AccountsList.forEach((mt4Account, i) => {
              balances.forEach((account) => {
                if (account.login == mt4Account.accountId) {
                  this.wtStorageService.mt4AccountsList[i]['balance'] = account.balance;
                }
              });
            });
          } else {

          }
        }, err => {

        }
      );
  }

  openFS() {

    let document: any = window.document;
    let fs = document.getElementById("body_full_screen");

    if (!document.fullscreenElement &&
      !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
      if (fs.requestFullscreen) {
        fs.requestFullscreen();
      } else if (fs.msRequestFullscreen) {
        fs.msRequestFullscreen();
      } else if (fs.mozRequestFullScreen) {
        fs.mozRequestFullScreen();
      } else if (fs.webkitRequestFullscreen) {
        fs.webkitRequestFullscreen();
      }
      this.inFullScreenMode = true;
    }
    this.closeFullscreen();
  }
  exitFS() {
    if (this.inFullScreenMode) {
      let document: any = window.document;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
  closeFullscreen() {
    if (this.openFullscreenRef) {
      this.openFullscreenRef.close();
      this.openFullscreenRef = null;
    }
  }


  // openReferral(referralRef: any) {
  //   if (this.wtStorageService.selectedMt4Account.demo) {
  //     swal({
  //       html: `<span style="color:#ffffff">${(new TranslatePipe(this.translateService))
  //         .transform('demoAccountCannotProceed')}<span>`,
  //       width: 400,
  //       timer: 1000,
  //       allowOutsideClick: false,
  //       allowEscapeKey: false,
  //       background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
  //       showConfirmButton: false,
  //       target: '#body_full_screen'
  //     });
  //     return false;
  //   }
  //   if (!this.openReferralRef) {
  //     this.referralData = this.mt4Accounts.find((o) => {
  //       return o['loginId'] === this.wtStorageService.selectedMt4Account.accountId
  //     });
  //     this.imageData = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.referralData.qrCode);
  //     this.affiliateLinkEnglish = this.wtUtilService.config.CRM_APP + '/affiliates/en/' + this.wtStorageService.selectedMt4Account.accountId;
  //     this.affiliateLinkChinese = this.wtUtilService.config.CRM_APP + '/affiliates/zh/' + this.wtStorageService.selectedMt4Account.accountId;
  //     const refLink = this.translateService.currentLang() === 'zh' ? this.affiliateLinkChinese : this.affiliateLinkEnglish;
  //     if (refLink) {
  //       const description = this.translateService.currentLang() == 'en' ? 'Check out the quick and easy way to earn profits' : '查看快速简便的赚取利润的方法';
  //       this.sinaReferral = 'http://service.weibo.com/share/share.php?url=' + refLink + '&appkey=&title=' + description + '&pic=&ralateUid=&language=zh_cn';
  //       this.facebookReferral = 'https://www.facebook.com/sharer/sharer.php?u=' + refLink;
  //       this.whatsappReferral = 'https://wa.me/?text=' + encodeURIComponent(description) + ' ' + refLink;
  //     }
  //     this.openReferralRef = this.modalService.open(referralRef, {
  //       backdrop: 'static',
  //       windowClass: 'sm referral-modal',
  //       keyboard: false,
  //       container: '#body_full_screen'
  //     });
  //   }
  // }
  // setTimeout(function(){
  //   this.openReferral(this.referralRef);
  //  }, 15 * 60 * 1000);


  intervalChange(selectedInterval: any) {
    this.selectedSymConfig.interval = selectedInterval.interval;
    this.selectedSymConfig.winPayout = selectedInterval.winPayout;
    this.selectedSymConfig.minAmount = selectedInterval.minAmount;
    this.selectedSymConfig.maxAmount = selectedInterval.maxAmount;
    this.tradeAmount = parseInt(selectedInterval.minAmount);
  }
  private setAccount(accId: any, validated: any) {
    if (!validated) {
      let selectedAuth = false;
      for (var i = 0; i < this.wtStorageService.mt4AccountsList.length; i++) {
        if (this.wtStorageService.mt4AccountsList[i].validate) {
          if (accId == this.wtStorageService.mt4AccountsList[i].accountId) {
            selectedAuth = true;
            break;
          }
        }
      }
      if (!selectedAuth) {
        accId = this.wtStorageService.selectedMt4Account.accountId;
      }
    }
    this.accountSwitch = true;
    this.setFavorites(accId);
    this.chartTab = [];
    // this.setCharts(accId);
    this.tradeHistoryComponent.currentRecords = [];
    this.tradeHistoryComponent.historyRecords = {};
    this.restartSignalr();
    if (JSON.parse(localStorage.getItem('settings')) == null || !JSON.parse(localStorage.getItem('settings'))[this.wtStorageService.selectedMt4Account.accountId]) {
      this.wtStorageService.soundSetting = true;
      this.wtStorageService.oneclickSetting = false;
    } else {
      this.wtStorageService.soundSetting = JSON.parse(localStorage.getItem('settings'))[this.wtStorageService.selectedMt4Account.accountId].sound;
      this.wtStorageService.oneclickSetting = JSON.parse(localStorage.getItem('settings'))[this.wtStorageService.selectedMt4Account.accountId].oneClick;
    }


  }
  changeMt4Account(mt4Account: any, index: number) {
    if (mt4Account.accountId === this.wtStorageService.selectedMt4Account.accountId) return;
    for (var i = 0; i < this.wtStorageService.mt4AccountsList.length; i++) {
      this.wtStorageService.mt4AccountsList[i].isSelected = false;
    }
    if (mt4Account.validate) {
      // this.isMt4Switched = true;
      this.wtStorageService.selectedMt4Account = mt4Account;
      mt4Account.isSelected = true;
      sessionStorage.setItem('mt4Account', JSON.stringify(mt4Account));
      this.setAccount(mt4Account.accountId, true);

    } else {
      mt4Account.isSelected = true;
      //  this.isMt4Switched = true;
      this.modalService.open(MT4AuthenticateComponent, this.modalOption).result.then(
        (result: any) => {
          //closing mt4 popup w/o any action
        }, (reason: any) => {
          this.setAccount(mt4Account.accountId, false);

        });
    }
  }
  private restartSignalr() {
    this.pricehubService.stopPricesHub();
    this.tradeshubService.stopTradesHub();
    this.pricehubService.startPricesHub();
    this.tradeshubService.startTradesHub();
    this.isMt4Switched = false;
  }
  incraseAmount() {
    this.selectedSymConfig.stepAmount = this.selectedSymConfig.stepAmount;
    this.tradeAmount = this.tradeAmount + this.selectedSymConfig.stepAmount;
    this.preAmount = this.tradeAmount;
    this.maxAmountCheck();
  }

  decreaseAmount() {
    this.tradeAmount = Math.max(0, this.tradeAmount - this.selectedSymConfig.stepAmount);
    this.preAmount = this.tradeAmount;
    this.maxAmountCheck();
  }
  onKey(event: any) {
    if (this.preAmount > this.selectedSymConfig.maxAmount && this.tradeAmount > this.selectedSymConfig.maxAmount) {
      this.tradeAmount = this.preAmount;
    }
    this.maxAmountCheck();
    this.preAmount = this.tradeAmount;
  }
  addCalculator(num: any) {
    this.tradeAmount = this.tradeAmount + num;
    this.preAmount = this.tradeAmount;
    this.maxAmountCheck();
  }
  subCalculator(num: any) {
    this.tradeAmount = Math.floor(this.tradeAmount - num);
    if (this.tradeAmount < 0) this.tradeAmount = 0;
    this.preAmount = this.tradeAmount;
    this.maxAmountCheck();
  }
  mulCalculator(num: any) {
    if (num != 2 && (this.tradeAmount * 10) + num >= this.selectedSymConfig.maxAmount * 10) return
    this.tradeAmount = this.tradeAmount * num;
    this.preAmount = this.tradeAmount;
    this.maxAmountCheck();
  }
  addString(num: any) {
    if ((this.tradeAmount * 10) + num >= this.selectedSymConfig.maxAmount * 10) return
    this.tradeAmount = this.tradeAmount * 10 + num;
    this.preAmount = this.tradeAmount;
    this.maxAmountCheck();
  }
  removeLast() {
    this.tradeAmount = Math.floor(this.tradeAmount / 10);
    this.preAmount = this.tradeAmount;
    this.maxAmountCheck();
  }
  private maxAmountCheck() {
    if (this.tradeAmount > this.selectedSymConfig.maxAmount) this.maxAmountFlag = true;
    else if (this.tradeAmount <= this.selectedSymConfig.maxAmount) this.maxAmountFlag = false;
  }
  setChartIndex(index: any) {
    this.toggleDpDown(true, null);
    this.selectedChartIndex = index;
    this.updateChart();
  }
  addChartTab() {
    this.toggleDpDown(true, null);
    if (this.chartTab.length < 5) {
      let newChart = {
        "symbol": this.wtStorageService.selectedSymbol,
        "timeFrame": this.wtStorageService.selectedTimeFrame.toUpperCase()
      }
      if (this.getIndex(this.chartTab, newChart) !== -1) {
        swal({
          html: `<i style="color:#ffffff;">${(new TranslatePipe(this.translateService))
            .transform("Trade_Chart_Bookmarked")}</i>`,
          timer: 1000,
          width: 300,
          padding: 20,
          background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
          showConfirmButton: false,
          target: '#body_full_screen'
        });
      } else {
        this.chartTab.push(newChart);
        this.selectedChartIndex = this.chartTab.length - 1;
        this.saveToLocalStorage(this.chartTab, 'chart_tab', this.wtStorageService.selectedMt4Account.accountId)
        this.updateChart();
        swal({
          html: `<i style="color:#ffffff;">${(new TranslatePipe(this.translateService))
            .transform("Bookmark_Added")}</i>`,
          timer: 1000,
          width: 300,
          padding: 20,
          background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
          showConfirmButton: false,
          target: '#body_full_screen'
        });
      }
    } else {
      swal({
        html: `<i style="color:#ffffff;">${(new TranslatePipe(this.translateService))
          .transform("Trade_Reached_Max_Chart_Tabs")}</i>`,
        timer: 1000,
        width: 300,
        padding: 20,
        background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
        showConfirmButton: false,
        target: '#body_full_screen'
      });
    }
  }
  removeChartTab(removeIndex: any) {
    this.toggleDpDown(true, null);
    let tempTab = this.chartTab[removeIndex];
    this.chartTab.splice(removeIndex, 1);
    let symbTime = {
      "symbol": this.wtStorageService.selectedSymbol,
      "timeFrame": this.wtStorageService.selectedTimeFrame.toUpperCase()
    }
    this.selectedChartIndex = this.getIndex(this.chartTab, symbTime);
    this.updateLocalStorage(this.chartTab, tempTab, null);
  }
  private alignPriceSymbols() {
    if (this.tradeAmount) {
      switch (this.tradeAmount.toString().length) {
        case 0:
          this.amountIpWidth = 40;
          this.currencySymbolPadding = 42;
          break;
        case 1:
          this.amountIpWidth = 45;
          this.currencySymbolPadding = 38;
          break;
        case 2:
          this.amountIpWidth = 50;
          this.currencySymbolPadding = 34;
          break;
        case 3:
          this.amountIpWidth = 55;
          this.currencySymbolPadding = 30;
          break;
        case 4:
          this.amountIpWidth = 60;
          this.currencySymbolPadding = 26;
          break;
      }
    } else {
      if (this.tradeAmount == null || (this.tradeAmount && this.tradeAmount.toString().length == 0)) {
        this.amountIpWidth = 40;
        this.currencySymbolPadding = 42;
      }
    }
  }
  private updateChart() {
    let selectedTimeFrame = this.wtStorageService.selectedTimeFrame;
    if (this.wtStorageService.selectedTimeFrame.toLowerCase() != this.chartTab[this.selectedChartIndex].timeFrame.toLowerCase()) {
      selectedTimeFrame = this.chartTab[this.selectedChartIndex].timeFrame.toLowerCase();;
    }
    if (this.wtStorageService.selectedSymbol != this.chartTab[this.selectedChartIndex].symbol) {
      this.wtStorageService.selectedTimeFrame = selectedTimeFrame;
      let selectedSymbol = this.chartTab[this.selectedChartIndex].symbol;
      for (let security in this.symbolConfiguration) {
        if (this.symbolConfiguration[security][selectedSymbol] != undefined && this.symbolConfiguration[security][selectedSymbol].length > 0) {
          this.changeSymbol(selectedSymbol, this.symbolConfiguration[security][selectedSymbol], true);
          break;
        }
      }
      // this.saveToLocalStorage(this.chartTab[this.selectedChartIndex],'chart_tab_selected',this.wtStorageService.selectedMt4Account.accountId);
    } else if (selectedTimeFrame !== this.wtStorageService.selectedTimeFrame) {
      this.setTimeFrame(selectedTimeFrame);
    }
  }
  private generateSymbolList() {
    this.symbolConfiguration = { symbols: {} };
    let securities: any = {};
    if (this.configuration && this.configuration.securities && this.configuration.symbols && this.configuration.payoutConfigurations) {
      this.configuration.securities.forEach((security: any) => {
        this.symbolConfiguration[security.name] = {};
        securities[security.name] = false;
      });
      this.configuration.symbols.forEach((symbol: any) => {
        if (this.symbolConfiguration[symbol.security]) {
          this.symbolConfiguration[symbol.security][symbol.name] = [];
          this.symbolConfiguration.symbols[symbol.name] = {}
          this.symbolConfiguration.symbols[symbol.name]['amountStep'] = symbol.amountStep;
        } else {
          console.log("security symbol: " + symbol.security + " symbol name: " + symbol.name);
        }
      });
      for (let j = 0; j < Object.keys(this.symbolConfiguration).length; j++) {
        if (Object.keys(this.symbolConfiguration)[j] == 'symbols') continue;
        for (let symbol in this.symbolConfiguration[Object.keys(this.symbolConfiguration)[j]]) {
          for (let i = 0; i < this.configuration.payoutConfigurations.length; i++) {
            if (this.configuration.payoutConfigurations[i].symbols.indexOf(symbol) > -1) {
              securities[Object.keys(this.symbolConfiguration)[j]] = true;
              for (let interval of this.configuration.payoutConfigurations[i].intervals) {
                if (this.checkForInterval(this.symbolConfiguration[Object.keys(this.symbolConfiguration)[j]][symbol], interval,
                  this.configuration.payoutConfigurations[i].payoutInfo.winPayout)) {
                  let symbolIndex = this.getSymbolIndex(this.configuration.symbols, symbol);
                  this.symbolConfiguration[Object.keys(this.symbolConfiguration)[j]][symbol].push({
                    interval: interval,
                    winPayout: this.configuration.payoutConfigurations[i].payoutInfo.winPayout,
                    minAmount: this.configuration.payoutConfigurations[i].amountRange.min > this.configuration.symbols[symbolIndex].amountMin ?
                      this.configuration.payoutConfigurations[i].amountRange.min : this.configuration.symbols[symbolIndex].amountMin,
                    maxAmount: this.configuration.payoutConfigurations[i].amountRange.max < this.configuration.symbols[symbolIndex].amountMax ?
                      this.configuration.payoutConfigurations[i].amountRange.max : this.configuration.symbols[symbolIndex].amountMax,
                    stepAmount: this.symbolConfiguration.symbols[symbol].amountStep
                  });
                }
              }
            }
          }
        }
      }
      for (let security in securities) {
        if (securities[security] === false) {
          delete this.symbolConfiguration[security];
        }
      }
    }
    this.isSymbolLoaded = true;
    delete this.symbolConfiguration.symbols;
  }
  private checkForInterval(arr: any, interval: any, winPayout: any) {
    let index = arr.findIndex((ele: any) => ele.interval == interval && ele.winPayout == winPayout);
    if (index > -1) return false;
    else return true;
  }
  private getSymbolIndex(arr: any, symbol: any) {
    return arr.findIndex((ele: any) => ele.name == symbol);
  }
  private updatePlotLine(yValue: any) {
    let plotLine = this.chart.yAxis[0].plotLinesAndBands[this.getPlotLineIndex()].svgElem;
    let label = this.chart.yAxis[0].plotLinesAndBands[this.getPlotLineIndex()].label;
    let d = plotLine.d.split(' ');
    let newY = this.chart.yAxis[0].toPixels(yValue) - d[2];
    if (label) {
      label.attr('text', '<span class="current-price-indicator">' + yValue.toString().substr(0, 4) +
        '<span class="dec-bold">' + yValue.toString().substr(4, yValue.toString().length) +
        '</span></span>');
    }
  }
  private alignCurrentPriceLabel() {
    if (this.chart.yAxis[0].plotLinesAndBands[this.getPlotLineIndex()]) {
      let plotLine = this.chart.yAxis[0].plotLinesAndBands[this.getPlotLineIndex()].svgElem;
      let label = this.chart.yAxis[0].plotLinesAndBands[this.getPlotLineIndex()].label;
      let d = plotLine.d.split(' ');
      let newY = this.chart.yAxis[0].toPixels(this.currentPrice) - d[2];
      if (d && label) {
        plotLine.animate({
          translateY: newY,
        }, 0);
        // label.animate({
        // 	translateY: this.chart.yAxis[0].toPixels(this.currentPrice) - this.chart.yAxis[0].toPixels(this.oldLabelVal)
        // },250);
				/*if (this.oldLabelVal)
					label.animate({ 'foo': this.chart.yAxis[0].toPixels(this.oldLabelVal) + 3 },
						{
							step: function (foo: any) {
								label.attr('y', foo);
							},
							duration: 0
						});
				else
					label.attr(this.chart.yAxis[0].toPixels(this.currentPrice) + 3);*/
        // label.translateY = this.chart.yAxis[0].toPixels(this.currentPrice) - this.chart.yAxis[0].toPixels(this.oldLabelVal);
        if (this.oldLabelVal) label.attr('y', this.chart.yAxis[0].toPixels(this.oldLabelVal) + 3);
        label.attr('x', +d[4] + 18);
        label.attr('text', '<span class="current-price-indicator">' + this.currentPrice.toString().substr(0, 4) +
          '<span class="dec-bold">' + this.currentPrice.toString().substr(4, this.currentPrice.toString().length) +
          '</span></span>');
        this.oldLabelVal = this.currentPrice;
      }
    }
  }
  private getPlotLineIndex() {
    for (var i = 0; i < this.chart.yAxis[0].plotLinesAndBands.length; i++) {
      if (this.chart.yAxis[0].plotLinesAndBands[i].id == 'currentPrice') {
        break;
      }
    }
    return i;
  }
  getMorePastData() {
    if (this.lastRecordDate && this.lastRecordDate != this.lastReqRecordDate) {
      this.lastReqRecordDate = this.lastRecordDate;
      this.chartLoaded = false;
      this.tradeService.getPastCandles(this.wtStorageService.selectedSymbol,
        this.wtStorageService.selectedTimeFrame, '60', this.lastRecordDate.substr(0, 19) + 'Z').subscribe(
          (previousData: any) => {
            if (previousData.bars.length > 0
              && this.wtStorageService.selectedSymbol === previousData.info.symbol
              && this.wtStorageService.selectedTimeFrame === previousData.info.timeframe.toLowerCase()) {
              this.lastRecordDate = previousData.bars[0].datetime;
              if (this.chartData[0][0] > new Date(previousData.bars[0].datetime).getTime()) {
                var priceList: any = [];
                previousData.bars.forEach((data: any) => {
                  priceList.push([
                    /*moment(data['datetime']).tz("Europe/Athens").isDST() ? new Date(data['datetime']).getTime() - 3600000
                      : new Date(data['datetime']).getTime(), // change required to handle daylight saving*/
                    new Date(data['datetime']).getTime(),
                    data['open'],
                    data['high'],
                    data['low'],
                    data['close']
                  ]);
                });
                this.chartData = priceList.concat(this.chartData);
                this.chart.series[0].setData(Object.assign([], this.chartData), true, true, true);
                this.indicatorService.updatePreviousPoints(this.chart, [this.chart.series[0].xData, this.chart.series[0].yData], this.indicatorsComponent);
              }
            }
            this.chartLoaded = true;
            this.initLoad = true;
            this.accountSwitch = false;
          }
        );
    }
  }

  clicked(event: any) {
    event.preventDefault();
  }

  private filterEmptyVal(obj: any) {
    if (null == obj || "object" != typeof obj) return obj;
    let copy = obj.constructor();
    for (let attr in obj) {
      if (obj.hasOwnProperty(attr) && obj[attr]) copy[attr] = obj[attr];
    }
    return copy;
  }
  private getIndex(arr: any, obj: any) {
    let index = -1;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].symbol === obj.symbol && arr[i].timeFrame === obj.timeFrame) {
        index = i;
        break;
      }
    }
    return index;
  }
  private isInChartTab(arr: any, obj: any) {
    if (arr.length === 0) return false;
    let isEleUnique = false;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].symbol === obj.symbol && arr[i].timeFrame === obj.timeFrame) {
        isEleUnique = true;
        break;
      }
    }
    return isEleUnique;
  }
  private isEmpty(obj: any) {

    if (obj == null) return true;

    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    if (typeof obj !== "object") return true;

    for (var key in obj) {
      if (hasOwnProperty.call(obj, key)) return false;
    }
    return true;
  }

  private removeFromArray(originalObj: any, obj: any) {
    return originalObj.filter((item: any) => {
      return (item.symbol === obj.symbol && item.timeFrame === obj.timeFrame) ? false : true;
    })

  }

  private clone(obj: any) {
    let copy: any;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.clone(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = this.clone(obj[attr]);
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  soundClick(event: any) {
    let settings = JSON.parse(localStorage.getItem('settings'));
    if (settings == null || !settings[this.wtStorageService.selectedMt4Account.accountId]) {
      if (settings == null)
        settings = {
          [this.wtStorageService.selectedMt4Account.accountId]:
            { sound: this.wtStorageService.soundSetting, oneClick: this.wtStorageService.oneclickSetting }
        };
      else
        settings[this.wtStorageService.selectedMt4Account.accountId] =
          { sound: this.wtStorageService.soundSetting, oneClick: this.wtStorageService.oneclickSetting };
    }
    this.wtStorageService.soundSetting = this.wtStorageService.soundSetting ? false : true;
    settings[this.wtStorageService.selectedMt4Account.accountId].sound = this.wtStorageService.soundSetting;
    localStorage.setItem('settings', JSON.stringify(settings));
  }

  oneClickSettingTogggle(event: any) {
    let settings = JSON.parse(localStorage.getItem('settings'));
    if (settings == null || !settings[this.wtStorageService.selectedMt4Account.accountId]) {
      if (settings == null)
        settings = {
          [this.wtStorageService.selectedMt4Account.accountId]:
            { sound: this.wtStorageService.soundSetting, oneClick: this.wtStorageService.oneclickSetting }
        };
      else
        settings[this.wtStorageService.selectedMt4Account.accountId] =
          { sound: this.wtStorageService.soundSetting, oneClick: this.wtStorageService.oneclickSetting };
    }
    this.wtStorageService.oneclickSetting = this.wtStorageService.oneclickSetting ? false : true;
    settings[this.wtStorageService.selectedMt4Account.accountId].oneClick = this.wtStorageService.oneclickSetting;
    localStorage.setItem('settings', JSON.stringify(settings));
  }


  usClicked(event: any) {
    event.preventDefault();
    this.lang = 'en';
    this.translateService.selectLang('en');
    this.countryFlagUrl = 'assets/images/us-flag.jpg';
    // localStorage.setItem('lanGuage','en');
  }

  chiClicked(event: any) {
    event.preventDefault();
    this.lang = 'zh';
    this.translateService.selectLang('zh');
    this.countryFlagUrl = 'assets/images/zh-flag.png';
    // localStorage.setItem('lanGuage','zh');

  }

  saveLang() {
    localStorage.setItem('lanGuage', this.lang);
  }

  closeLang() {
    this.translateService.selectLang(localStorage.getItem('lanGuage'));
    this.lang = localStorage.getItem('lanGuage');
    var zhFlag = 'assets/images/zh-flag.png';
		var enFlag = 'assets/images/us-flag.png';
		this.countryFlagUrl = localStorage.getItem('lanGuage') == 'zh' ? zhFlag : enFlag;
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    this.getConfirmation();
  }

  getConfirmation() {
    var mssg = new TranslatePipe(this.translateService).transform("Log_Out_Mssg");
    var retVal = confirm(mssg);
    let self = this;
    self.ignoreHashChange = true;
    window.onhashchange = function () {
      if (!self.ignoreHashChange) {
        self.ignoreHashChange = true;
      } else {
        self.ignoreHashChange = false;
      }
    };
    // var retVal = confirm("You will be logged out, Do you want to continue ?");
    if (retVal == true) {
      this.logOut();
      //   return true;
    }
    else {
      //   return false;
    }
  }
  hideKeyPad(amtInput: any) {
    if (this.device === "ipad" || this.device === "android" || this.device === "iphone") amtInput.blur();
  }
  // @HostListener('window:keydown', ['$event'])
  //      keyboardInput(event: any) {
  //             if(event.keyCode == 116){
  // 				this.getConfirmation();
  //                 // alert('No Refresh Dude..!!');
  //                 // this.toggleDpDown(true,null);
  //             }
  //      }
  // 	 getConfirmation(){
  // 		 var retVal = confirm("You will be logged out, Do you want to continue ?");
  //        if( retVal == true ){
  // 		  this.logOut();
  //         //   return true;
  //        }
  //        else{
  //         //   return false;
  // 	 }
  // 	 }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'webtrader-chart');
  }

}

