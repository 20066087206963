import {InjectionToken} from '@angular/core';

// import translations
import {LANG_EN_NAME, LANG_EN_TRANS} from './lang-en';
import {LANG_ZH_NAME, LANG_ZH_TRANS} from './lang-zh';

// translation token
export const TRANSLATIONS = new InjectionToken<any>('translations');

// all translations
export const dictionary: any = {};
dictionary[LANG_EN_NAME] = LANG_EN_TRANS;
dictionary[LANG_ZH_NAME] = LANG_ZH_TRANS;

// providers
export const TRANSLATION_PROVIDERS = [
  {provide: TRANSLATIONS, useValue: dictionary},
];
