import {Injectable} from '@angular/core';
import {Headers, RequestOptions} from '@angular/http';
import {WTHttpService} from '../../shared/service/wt-http.service';
import {WTStorageService} from '../../shared/service/wt-storage.service';
import {WTUtilService} from '../../shared/service/wt-util.service';

@Injectable()
export class LoginService {

  isAuthenticate: boolean = false;

  constructor(private wthttpService: WTHttpService,
              private wtstorageService: WTStorageService, private WTUtil: WTUtilService) {
    this.wtstorageService.crmBaseUrl = WTUtil.config.CRM_BASE_URL;
    this.wtstorageService.boBaseUrl = WTUtil.config.BO_BASE_URL;
    this.wtstorageService.apiBaseUrl = WTUtil.config.FOX_BASE_URL;
  }

  authUser(userName: string, pswrd: string): any {
    this.isAuthenticate = false;
    this.wtstorageService.userName = userName;
    this.wtstorageService.passWord = pswrd;

    var postParams = 'email=' + userName + '&password=' + pswrd;
    var loginHeaders = new Headers({});
    loginHeaders.append('Domain', this.WTUtil.config.DOMAIN);
    loginHeaders.append('MerchantKey', this.WTUtil.config.MERCHANT_KEY);
    loginHeaders.append('lang', this.wtstorageService.selected_lang);
    loginHeaders.append('Content-type', 'application/x-www-form-urlencoded; charset=UTF-8');
    loginHeaders.append('IP', this.wtstorageService.ip);
    let options = new RequestOptions({headers: loginHeaders});
    return this.callAuthAPI(this.wtstorageService.crmBaseUrl + this.WTUtil.config.LOGIN_URI, postParams, options);
  }

  getAppConfig() {
    var headers = new Headers({});
    headers.append('Domain', this.WTUtil.config.DOMAIN);
    headers.append('MerchantKey', this.WTUtil.config.MERCHANT_KEY);
    headers.append('lang', this.wtstorageService.selected_lang);
    headers.append('Content-type', 'application/x-www-form-urlencoded; charset=UTF-8');
    headers.append('IP', this.wtstorageService.ip);
    return this.wthttpService.get(`${this.wtstorageService.crmBaseUrl}${this.WTUtil.config.APP_CONFIG_URI}`,
      {headers: headers});
  }

  forgotCrmPwd(emailid: string): any {
    var postParams = {email: emailid};
    var loginHeaders = new Headers({});
    loginHeaders.append('Domain', this.WTUtil.config.DOMAIN);
    loginHeaders.append('MerchantKey', this.WTUtil.config.MERCHANT_KEY);
    loginHeaders.append('lang', this.wtstorageService.selected_lang);
    loginHeaders.append('IP', this.wtstorageService.ip);
    let options = new RequestOptions({headers: loginHeaders});
    return this.callAuthAPI(this.wtstorageService.crmBaseUrl + this.WTUtil.config.FORGOT_PSWRD_URI, postParams, options);
  }

  authUserFromFoxApi(userName:string, password:string): any {
		this.isAuthenticate = false;
		this.wtstorageService.userName = userName;
		this.wtstorageService.passWord = password;

		const postParams = {
			'login': userName,
			'password': password
		  };
    	var loginHeaders = new Headers({});
    	loginHeaders.append('Content-type','application/json');
    	let options = new RequestOptions({ headers: loginHeaders });
        return this.callAuthAPI(this.wtstorageService.apiBaseUrl+this.WTUtil.config.FOX_LOGIN_URI, postParams, options);
	}

  private callAuthAPI(url: string, body: any, headers: Object) {
    return this.wthttpService.post(url, body, headers);
  }
}
