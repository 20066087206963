export const WT_CONFIG = {
  CRM_BASE_URL: '',
  BO_BASE_URL: 'http://options.fcxtrade.com:16390',
  //BO_BASE_URL: 'http://localhost:5998',
  FOX_BASE_URL: 'http://options.fcxtrade.com:2709',
  //FOX_BASE_URL: 'http://boapitest.mt4.space:9018'
  TERMS_CONDITIONS: '#',
  ABOUT_US: 'https://infinityplus.ai/member/wallet/convert',
  CRM_APP: '',
  LOGGING_BASE_URL: '',
  SECRET_KEY: '',

  BRAND_NAME: 'GDEX',
  MERCHANT_KEY: "12345",
  DOMAIN: '',
  appType : 'web',

    REGISTER_URI: '#',
    DEPOSIT_URI: 'https://infinityplus.ai/member/wallet/convert',
    WITHDRAWAL_URI : 'https://infinityplus.ai/member/wallet/convert',
    REFRESH_URI: '/api/v1/refresh',
    HISTORY_URI: '/api/v1/mt4/tradee?',
    HISTORY_ARCHIVE_URI: '/api/v1/mt4/archive-trade?',
    LOGIN_URI: '/api/v1/login?',
    LOGOUT_URI: '/api/v1/logout',
    FORGOT_PSWRD_URI: '/api/v1/forgot-password',
    APP_CONFIG_URI: '/api/v1/app-config',
    UPDATE_TOKEN:'/api/v1/update-token',
    USER_PROFILE:'/api/v1/profile',

    DEPOSIT_UPLOAD_URI: '/api/deposit/upload',
    DEPOSIT_CARD_URI: '/api/deposit/card',
    WITHDRAWAL_WT_URI: '/api/withdrawal',

    FOX_LOGIN_URI: '/api/auth',
    FOX_LOGOUT_URI: '/api/logout',
    FOX_HISTORY_URI: '/api/mt4/trade?',
    FOX_HISTORY_ARCHIVE_URI: '/api/mt4/archive-trade?',
    FOX_REFRESH_URI: '/api/refresh',

    IMAGE_ROOT: '',
    GA_TRACKING_CODE: 'UA-123757204-4'
 };

