import { Component, Input, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment-timezone';
import {TradeshubService} from '../shared/tradeshub.service';
import {PricehubService} from '../shared/pricehub.service';
import {OpenTradeStatusPipe} from './opentrade-status.pipe';
import {CountDownTimerPipe} from './countdown-timer.pipe';
import {OpenTradeOrderbyIdPipe} from './opentrade-orderbyId.pipe';
import {WTStorageService} from '../../shared/service/wt-storage.service';
import {TranslateService} from '../../shared/translate/translate.service';
import {TranslatePipe} from '../../shared/translate/translate.pipe';
import {WTCurrencyPipe} from '../../shared/pipes/wt-currency.pipe';
import { WTUtilService } from '../../shared/service/wt-util.service';

/**
 * This class represents the lazy loaded OpenTradeComponent.
 */
@Component({
  moduleId: module.id,
  selector: 'open-trade',
  templateUrl: 'open-trades.component.html',
  styleUrls: ['open-trades.component.scss'],
  providers: [OpenTradeStatusPipe, CountDownTimerPipe , OpenTradeOrderbyIdPipe]
})

export class OpenTradesComponent {
  @Input() chart:any;
	showOpenTrades:boolean = false;
	disableClose:boolean = false;
	tradeViewLoaded:boolean = false;
	openTrades: Array<any> = [];
	date:Date;
	serverTime:any;
	openPositions:number =0;
	totalExpArray:Array<number> = [];
	totalExpAmount:number = 0;
	updateHistory:boolean = false;


	serverAthens : any;
	currentAthens : any;



	@Output() closeOpenTradeView:EventEmitter<any> = new EventEmitter<any>();
	@Output() openTradeClosed:EventEmitter<any> = new EventEmitter<any>();

	constructor(private tradeshubService: TradeshubService,
				private pricehubService: PricehubService,
				private wtStorageService: WTStorageService, public wtUtilService: WTUtilService){
		setInterval(() => {
	        this.date =  moment().tz("Europe/Athens").valueOf() + this.wtStorageService.offsetValue;
	}, 1000);
		this.pricehubService.getPrice()
			.subscribe(
				(data:any) => {this.updatePrice(data);
				});
		this.tradeshubService.getOpenTrades().subscribe(
				(openTrades:any) => {
					if(openTrades.serverTime && openTrades.serverTime != undefined ){
						this.serverAthens = moment(openTrades.serverTime).tz("Europe/Athens").valueOf();
						this.currentAthens = moment(new Date()).tz("Europe/Athens").valueOf();
						// if(moment.tz('Europe/Athens').isDST()){
						// 	this.currentAthens = this.currentAthens + 3600000;
						// }
					this.wtStorageService.offsetValue = this.serverAthens-this.currentAthens;
					}
					this.totalExpAmount = 0;
					this.totalExpArray = [];
					this.openPositions = 0;

					for(var i=0; i< this.openTrades.length && this.chart; i++){
							this.chart.chartStock.yAxis[0].removePlotLine(this.openTrades[i].id.toString());
					}

					this.openTrades = openTrades.trades || [];
					if(this.openTrades && this.openTrades.length > 0){
						this.openPositions = this.openTrades.length;

						for(var i=0; i< this.openTrades.length; i++){
								this.totalExpArray.unshift(this.openTrades[i].amount);
								if(this.chart){
            					 	 if(this.openTrades[i].closeTime == null ){
              							  this.addPlotLine(this.openTrades[i]);
           			  			 	 } else {
              						 	 this.chart.chartStock.yAxis[0].removePlotLine(this.openTrades[i].id.toString());
             		  			 	 }
								}
							this.subscribeForSymbol(this.openTrades[i].symbol);
           				 }
						for(let i = 0 ; i < this.totalExpArray.length ; ++i){
							this.totalExpAmount = this.totalExpAmount + this.totalExpArray[i];
						}
					}
				}
			);
		this.tradeshubService.getTradeUpdate().subscribe(
			(openTrade:any)=> {
				if(openTrade.trade){
					this.setupOpenTradeView(openTrade.trade);
					if(openTrade.trade.closeTime == null){
						this.subscribeForSymbol(openTrade.trade.symbol);
						if(this.chart){
            				this.addPlotLine(openTrade.trade);
						}
					} else {
						this.openTradeClosed.emit(openTrade.trade);
						if(this.chart){
            				this.chart.chartStock.yAxis[0].removePlotLine(openTrade.trade.id.toString());
						}
        		    }
				}
			});


	}
	/**
	 * For fixing the issue now
	 */
	closeFromTradeComponent(){
		for(var i=0; i< this.openTrades.length; i++){
					if(this.openTrades[i].closeTime != null) {
						this.openTrades.splice(i--,1);
					}
				}
	}
	close() {
		this.disableClose = true;
		this.closeOpenTradeView.emit();

				for(var i=0; i< this.openTrades.length; i++){
					if(this.openTrades[i].closeTime != null) {
						this.openTrades.splice(i--,1);
					}
				}

	}
	//closeOpenTrades(){
			//for(var i=0; i< this.openTrades.length; i++){
		//	if(this.openTrades[i].closeTime != null) {
		//		this.openTrades.splice(i,1);
		//	}
	//}
	removeAllPlotLines(){
		if(this.chart){
			for(let i=0; i < this.openTrades.length; i++){
				if(this.wtStorageService.selectedSymbol != this.openTrades[i].symbol){
					this.chart.chartStock.yAxis[0].removePlotLine(this.openTrades[i].id.toString());
				}
			}
		}
	}
	addAllPlotLines(){
		if(this.chart){
			for(let i=0; i < this.openTrades.length; i++){
				if(this.wtStorageService.selectedSymbol == this.openTrades[i].symbol && this.openTrades[i].closeTime == null){
					this.addPlotLine(this.openTrades[i]);
				}
			}
		}
	}
	private setupOpenTradeView(tradeUpdate:any){
		var tradeLocated = false;
	//	if(this.openTrades.length > 0) {
			for(var i=0; i< this.openTrades.length; i++){
				if(this.openTrades[i].id == tradeUpdate.id){
					this.openTrades[i] = tradeUpdate;
					tradeLocated = true;
					if(tradeUpdate.closeTime != null){
						if(this.openPositions > 0){
							this.openPositions = this.openPositions - 1;
						}
						if(this.totalExpAmount > 0 && this.totalExpArray.length > 0){
							this.totalExpArray.shift();
							this.totalExpAmount = this.totalExpAmount - tradeUpdate.amount;
						}
					}
				}
			}

		//}
			if(!tradeLocated && tradeUpdate.closeTime == null){
				this.openTrades.unshift(tradeUpdate);
				this.openPositions = this.openPositions + 1;
				this.totalExpArray.unshift(tradeUpdate.amount);
				this.totalExpAmount = this.totalExpAmount + tradeUpdate.amount;
			}
	//	} else {
	//		this.openTrades.push(tradeUpdate);
	//		this.openPositions = this.openPositions + 1;
	//	}
	}
	private updatePrice(data:any){
		if(data.bar && data.bar.close)
			for(var i=0; i< this.openTrades.length; i++){
				if(this.openTrades[i].closeTime == null && this.openTrades[i].symbol == data.info.symbol)
					this.openTrades[i].closePrice = data.bar.close;
			}
	}
	private subscribeForSymbol(symbol:string){
		this.pricehubService.priceHubProxy.invoke('subscribe', symbol, 'M1');
	}
	private addPlotLine(trade:any){
		if(this.wtStorageService.selectedSymbol == trade.symbol && this.chart.chartStock.xAxis[0].getExtremes().max
				&& isNaN(this.getPlotLineIndex(trade.id.toString()))){
			this.chart.chartStock.yAxis[0].addPlotLine({
						value: trade.openPrice,
						color: this.wtUtilService.colorsFlipped ? (trade.action =='Up' ? '#1fd6a4' : '#f25d65') : (trade.action =='Up' ? '#f25d65':'#1fd6a4'),
						arrow: true,
						label: {
							backgroundColor: this.wtUtilService.colorsFlipped ? (trade.action =='Up' ? '#1fd6a4' : '#f25d65') : (trade.action =='Up' ? '#f25d65':'#1fd6a4'),
							align: 'right',
							textAlign: 'left',
							text : '<span style="color:white;margin-top:9px;margin-left:13px">'+
									trade.openPrice
									+'</span>',
							//useHTML: true,
							//verticalAlign: 'middle',
							zIndex: 9
						},
						width: 2,
						id: trade.id.toString(),
						zIndex: 8,
					});
				let index = this.getPlotLineIndex(trade.id.toString());
				let plotLine = this.chart.chartStock.yAxis[0].plotLinesAndBands[index].svgElem;
				let label = this.chart.chartStock.yAxis[0].plotLinesAndBands[index].label;
				if(plotLine.d && label){
					let d = plotLine.d.split(' ');
					label.attr('y', +d[2]+5);
					label.attr('x', +d[8]+15);
				}
		}
	}
	private getPlotLineIndex(id:string){
		for(let i=0; i < this.chart.chartStock.yAxis[0].plotLinesAndBands.length; i++){
			if(this.chart.chartStock.yAxis[0].plotLinesAndBands[i].id ==id){
				return i ;
			}
		}
		return NaN;
	}
	alignPlotlineLabel(){
		for(let i=0; i < this.openTrades.length; i++){
			if(this.wtStorageService.selectedSymbol == this.openTrades[i].symbol && !isNaN(this.getPlotLineIndex(this.openTrades[i].id.toString()))){
				let plotLine = this.chart.chartStock.yAxis[0].plotLinesAndBands[this.getPlotLineIndex(this.openTrades[i].id.toString())].svgElem;
				let label = this.chart.chartStock.yAxis[0].plotLinesAndBands[this.getPlotLineIndex(this.openTrades[i].id.toString())].label;
				if(plotLine.d && label){
					let d = plotLine.d.split(' ');
					label.attr('y', +d[2]+5);
					label.attr('x', +d[8]+15);
				}
			}
		}
	}

	counter(){
		return !this.openTrades.length;
	}

}
