import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PageNotFoundComponent} from './shared/page-not-found/page-not-found.component';
import {AuthGuard} from './shared/auth-guard/auth-guard.service';
import {LoginRoutes} from './login/login.routes';
import {TradeRoutes} from './trade/trade.routes';

const routes: Routes = [...LoginRoutes, ...TradeRoutes, {
  path: '**', component: PageNotFoundComponent,
  canActivate: [AuthGuard]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
