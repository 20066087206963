/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./deposit.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../dynamic-form/containers/dynamic-form/dynamic-form.component.ngfactory";
import * as i4 from "../dynamic-form/containers/dynamic-form/dynamic-form.component";
import * as i5 from "@angular/forms";
import * as i6 from "../pipes/key-value.pipe";
import * as i7 from "./deposit.component";
import * as i8 from "./deposit.service";
import * as i9 from "../service/wt-storage.service";
import * as i10 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import * as i11 from "../translate/translate.service";
import * as i12 from "../service/wt-util.service";
var styles_DepositComponent = [i0.styles];
var RenderType_DepositComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DepositComponent, data: {} });
export { RenderType_DepositComponent as RenderType_DepositComponent };
function View_DepositComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["class", " modal-title"], ["id", "myModalLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.componentFields.title; _ck(_v, 1, 0, currVal_0); }); }
function View_DepositComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["class", " modal-title"], ["id", "myModalLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedGateway.form.title; _ck(_v, 1, 0, currVal_0); }); }
function View_DepositComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "image-overlay loader-bkg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "loader__bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "loader__bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "loader__bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "loader__bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "loader__bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "loader__ball"]], null, null, null, null, null))], null, null); }
function View_DepositComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "col-3 banktype-block "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "img-fluid payimg"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn bt-second-color"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectGateway(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.image; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_1); }); }
function View_DepositComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "col-12 info-text "]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-12 d-flex select-banktype"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositComponent_6)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "col-12 note-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.componentFields.gateways; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.componentFields.body; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.componentFields.note.title; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.componentFields.note.body; _ck(_v, 10, 0, currVal_3); }); }
function View_DepositComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", "\u00A0"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_DepositComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", "\u00A0"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_DepositComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_DepositComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "alert-danger"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositComponent_11)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessages; _ck(_v, 2, 0, currVal_0); }, null); }
function View_DepositComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "input", [["type", "hidden"]], [[8, "name", 0], [8, "value", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit; var currVal_1 = _co.gateWayRedirect.elements[_v.parent.context.$implicit]; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DepositComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "input", [["type", "hidden"]], [[8, "name", 0], [8, "value", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit; var currVal_1 = _co.gateWayRedirect.returnUrl; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DepositComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositComponent_13)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositComponent_14)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit != "returnUrl"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit == "returnUrl"); _ck(_v, 4, 0, currVal_1); }, null); }
function View_DepositComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "col-12 fee-text-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "deposit-notetext pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositComponent_8)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositComponent_9)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "dynamic-form", [["class", "pull-left"]], null, [[null, "submit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (_co.gatewayFormSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DynamicFormComponent_0, i3.RenderType_DynamicFormComponent)), i1.ɵdid(7, 638976, [[1, 4], ["form", 4]], 0, i4.DynamicFormComponent, [i5.FormBuilder], { config: [0, "config"] }, { submit: "submit" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositComponent_10)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, [[2, 0], ["gatewayRedirect", 1]], null, 7, "form", [["class", "zero-height"], ["novalidate", ""]], [[8, "method", 0], [8, "action", 4], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i5.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(12, 4210688, null, 0, i5.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i5.ControlContainer, null, [i5.NgForm]), i1.ɵdid(14, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_DepositComponent_12)), i1.ɵdid(16, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵppd(17, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedGateway.form.body.p; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.selectedGateway.form.body.li; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.config; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.showErrorMessage; _ck(_v, 9, 0, currVal_3); var currVal_13 = i1.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i1.ɵnov(_v.parent.parent, 0), _co.gateWayRedirect.elements)); _ck(_v, 16, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.gateWayRedirect.method; var currVal_5 = _co.gateWayRedirect.url; var currVal_6 = i1.ɵnov(_v, 14).ngClassUntouched; var currVal_7 = i1.ɵnov(_v, 14).ngClassTouched; var currVal_8 = i1.ɵnov(_v, 14).ngClassPristine; var currVal_9 = i1.ɵnov(_v, 14).ngClassDirty; var currVal_10 = i1.ɵnov(_v, 14).ngClassValid; var currVal_11 = i1.ɵnov(_v, 14).ngClassInvalid; var currVal_12 = i1.ɵnov(_v, 14).ngClassPending; _ck(_v, 10, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); }); }
function View_DepositComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-body d-flex depositform"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.gatewaySelected; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.gatewaySelected; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_DepositComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.KeyValuePipe, []), i1.ɵqud(671088640, 1, { depositForm: 0 }), i1.ɵqud(671088640, 2, { gatewayRedirectForm: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "modal-header modal-headerbg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["aria-label", "Close"], ["class", " close-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeView() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["src", "assets/images/backbt.png"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositComponent_3)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositComponent_4)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [["class", "modal-footer nopadding"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.componentFields && !_co.gatewaySelected); _ck(_v, 7, 0, currVal_0); var currVal_1 = (_co.componentFields && _co.gatewaySelected); _ck(_v, 9, 0, currVal_1); var currVal_2 = (!_co.componentFields || _co.depositFormSubmitting); _ck(_v, 11, 0, currVal_2); var currVal_3 = _co.componentFields; _ck(_v, 13, 0, currVal_3); }, null); }
export function View_DepositComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "deposit", [], null, null, null, View_DepositComponent_0, RenderType_DepositComponent)), i1.ɵdid(1, 49152, null, 0, i7.DepositComponent, [i8.DepositService, i9.WTStorageService, i10.NgbActiveModal, i11.TranslateService, i12.WTUtilService], null, null)], null, null); }
var DepositComponentNgFactory = i1.ɵccf("deposit", i7.DepositComponent, View_DepositComponent_Host_0, {}, {}, []);
export { DepositComponentNgFactory as DepositComponentNgFactory };
