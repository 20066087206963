/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chart.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./chart.component";
var styles_ChartComponent = [i0.styles];
var RenderType_ChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChartComponent, data: {} });
export { RenderType_ChartComponent as RenderType_ChartComponent };
export function View_ChartComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { chartContainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "chart-control"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["id", "zoomIn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.chartZoomIn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["+"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["id", "zoomOut"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.chartZoomOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["-"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "button", [["id", "resetZoom"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.chartResetZoom() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["chartContainer", 1]], null, 2, "div", [["id", "chartContainer"]], null, null, null, null, null)), i1.ɵdid(9, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(10, { height: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 10, 0, (_co.headerWrapperHeight + "px")); _ck(_v, 9, 0, currVal_0); }, null); }
export function View_ChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "chart-container", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ChartComponent_0, RenderType_ChartComponent)), i1.ɵdid(1, 4308992, null, 0, i3.ChartComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChartComponentNgFactory = i1.ɵccf("chart-container", i3.ChartComponent, View_ChartComponent_Host_0, { chartData: "chartData" }, { getMoreCandles: "getMoreCandles" }, []);
export { ChartComponentNgFactory as ChartComponentNgFactory };
