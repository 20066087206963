<div id="loginbg">

    <div class="loader-backdrop" *ngIf="!wtStorageService.showLogin">
        <div class="loader">
          <div class="loader__bar"></div>
          <div class="loader__bar"></div>
          <div class="loader__bar"></div>
          <div class="loader__bar"></div>
          <div class="loader__bar"></div>
          <div class="loader__ball"></div>
        </div>
      </div>
    <div class="container-fluid" *ngIf="wtStorageService.showLogin">
        <div class="row">
            <div class="col-md-12 col-sm-12 d-flex no-padding new-login-block">
                <!-->left part-->
                <div class="col-md-5 col-lg-4 col-sm-12 no-padding-down-sm">
                    <div class="col-md-12 col-sm-12 d-flex">
                        <div class="col-md-8 col-sm-8 logo-login">
                            <a href="#" (click)="clicked($event)"><img src="assets/images/logo-login.png" class="img-responsive" /></a>
                        </div>
                        <div class="col-md-4 col-sm-4 language-block">
                            <div ngbDropdown class="dropdown d-inline-block" *ngIf="!disableSubmit">
                                <div class="" *ngIf="wtStorageService.selected_lang == 'en' " id="dropdownMenu1" ngbDropdownToggle>
                                    <img src="./assets/images/us-flag.png" />
                                    <span class="caret"></span>
                                </div>
                                <div class="" *ngIf="wtStorageService.selected_lang == 'zh' " id="dropdownMenu1" ngbDropdownToggle>
                                    <img src="./assets/images/zh-flag.png" />
                                    <span class="caret"></span>
                                </div>
                                <div class="dropdown-menu  dropdown-menu-right animated flipInY" aria-labelledby="dropdownMenu1">
                                    <!--dropdown-menu dropdown-menu-right -->
                                    <ul>
                                        <a href="#" (click)="usClicked($event)" style="text-decoration: none;">
                                            <li [ngClass]="{'selected':wtStorageService.selected_lang == 'en'}">
                                                <img src="./assets/images/us-flag.png" /> &nbsp; English
                                            </li>
                                        </a>
                                        <a href="#" (click)="chiClicked($event)" style="text-decoration: none;">
                                            <li [ngClass]="{'selected':wtStorageService.selected_lang == 'zh'}">
                                                <img src="./assets/images/zh-flag.png" /> &nbsp; 中文
                                            </li>
                                        </a>
                                    </ul>
                                </div>
                            </div>
                            <div class="language pull-right">

                                <!--A replica division menu bar created for disabled feel when submit button clicked     -->
                                <div ngbDropdown class="dropdown d-inline-block " *ngIf="disableSubmit">
                                    <div class="" *ngIf="wtStorageService.selected_lang == 'en' " id="dropdownMenu1" ngbDropdownToggle>
                                        <img src="./assets/images/us-flag.png" />
                                        <span class="caret"></span>
                                    </div>
                                    <div class="" *ngIf="wtStorageService.selected_lang == 'zh' " id="dropdownMenu1" ngbDropdownToggle>
                                        <img src="./assets/images/zh-flag.png" />
                                        <span class="caret"></span>
                                    </div>

                                </div>
                                <!--End of replica menu bar  -->
                            </div>
                        </div>
                    </div>

                    <!--End of CRM Log in  -->

                    <div *ngIf="inLogin">
                        <form #form="ngForm" action="" (ngSubmit)=login() novalidate>
                            <div class="loginform col-12">
                                <h2 class="text-center">{{ 'Crm_Login_Header' | translate }}</h2>
                                <div class="flex-row">
                                    <div class="col-12">
                                        <input *ngIf="brandName!='GDEX'" #input type="text" class="" [ngClass]="{'error': (! isValid || isInputEmpty) }" placeholder="{{'Binding_MT4_Account' | translate}}" name="username" id="username" [(ngModel)]="loginId" autofocus="autofocus" tabindex="1">
                                        <input *ngIf="brandName=='GDEX'" #input type="text" class="" [ngClass]="{'error': (! isValid || isInputEmpty) }" placeholder="{{'Binding_Account_Number' | translate}}" name="username" id="username" [(ngModel)]="loginId" autofocus="autofocus" tabindex="1">
                                    </div>
                                </div>
                                <div class="flex-row">
                                    <div class="col-12">
                                        <input type="password" class="" autocomplete="new-password" [ngClass]="{'error': (! isValid || isInputEmpty) }" placeholder="{{'Crm_Password' | translate}}" name="password" id="password" [(ngModel)]="password" tabindex="2">
                                    </div>
                                </div>
                                <!-- validation div -->
                                <div *ngIf="!isValid" class="col-md-12">
                                    <div class="validation">
                                        {{'Validation_WrongAccountPwd' | translate}}
                                    </div>
                                </div>
                                <div *ngIf="isInputEmpty && isValid" class="col-md-12">
                                    <div class="validation">
                                        {{ validationMessage | translate}}
                                    </div>
                                </div>

                                <div class="flex-row clear-left">
                                    <div class="col-12">
                                        <button type="submit" class="login-submit" [disabled]="disableSubmit" tabindex="3">
                      <img src="./assets/images/login-lock.png">
                      {{'Crm_MT4_Submit' | translate}}
                      <i *ngIf="disableSubmit" class='fa fa-circle-o-notch fa-spin'></i>
                    </button>
                                    </div>
                                    <div class="disclaimer col-12 clear-left">
                                        {{'By_Logging_in_you_agree_to_our' | translate}}
                                        <a *ngIf="!disableSubmit" target="_blank" [attr.href]="wtUtilService.config.TERMS_CONDITIONS">
                      {{'Privacy_Policy' | translate}}
                    </a>
                                        <a *ngIf="disableSubmit" class="disabled" href="#">{{'Privacy_Policy' | translate}}</a>
                                    </div>
                                </div>

                                <div *ngIf="brandName!='GDEX'" class="col-sm-12 note-text">
                                    <p>{{'Crm_Footer_Message' | translate}}</p>
                                </div>

                            </div>

                        </form>
                    </div>

                    <!--End of CRM Log in  -->

                    <!--Forgot Password -->

                    <div *ngIf="! inLogin && ! isFpEmailVerified">
                        <form #form="ngForm" action="" name="fpForm" (ngSubmit)=forgotPwd() novalidate>
                            <div class="loginform col-12">
                                <h2 class="text-center">{{'Forgot_Password' | translate}}</h2>
                                <div class="flex-row">
                                    <div class="col-12">
                                        <input name="email" type="email" class="" [ngClass]="{'error':  isFpEmailValid }" placeholder="{{'Email-id' | translate}}" name="password" id="password" [(ngModel)]="emailId" value="" autofocus="autofocus">
                                    </div>
                                </div>
                                <!-- validation div -->
                                <div *ngIf="isFpEmailValid" class="col-md-12">
                                    <div class="validation">{{fpValidationMessage | translate}}</div>
                                </div>
                                <!-- end validation div -->

                                <div class="flex-row clear-left">
                                    <div class="col-12">
                                        <button type="submit" class="login-submit" [disabled]="disableFpSubmit">
                      <!--<img src="./assets/images/login-lock.png">-->
                      {{'Crm_MT4_Submit' | translate}}
                      <i *ngIf="disableFpSubmit" class='fa fa-circle-o-notch fa-spin'></i>
                    </button>
                                    </div>
                                    <div class="disclaimer col-12 clear-left"> {{'Back_to' | translate}}
                                        <a href="" *ngIf="!disableSubmit" target="_blank" (click)="backToLogin()">
                      {{'Crm_Login_Header' | translate}}
                    </a>
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>

                    <!--End of Forgot Password -->

                    <!--Forgot Password Reset Link -->

                    <div *ngIf="! inLogin && isFpEmailVerified">
                        <div class="flex-row">
                            <div class="loginform col-12">
                                <h2 class="text-center">{{'Verification_Link_Sent' | translate}}</h2>
                                <!--<h5  class="d-flex justify-content-center">{{'The_Reset_Password_Link_has_been_sent_to_your_email' | translate}}</h5>-->
                                <div class="col-sm-12 text-align-center verification-block">
                                    <p>{{'The_Reset_Password_Link_has_been_sent_to_your_email' | translate}}</p>
                                </div>

                                <div class="flex-row clear-left">
                                    <div class="col-12">
                                        <button type="submit" class="login-submit" [disabled]="disableFpSubmit" (click)="backToLoginReset()">
                      <img src="./assets/images/login-lock.png">{{'Back_to_Login' | translate}}
                      <i *ngIf="disableFpSubmit" class='fa fa-circle-o-notch fa-spin'></i>
                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--End of Forgot Password Reset Link -->
                    <section class="footer" *ngIf="wtStorageService.showLogin">
                        <div class="container">
                            <div class="col-sm-12 copyright-block text-align-center">
                            </div>
                        </div>

                    </section>
                </div>
                <!-->left part end-->
                <div class="col-md-7 col-lg-8 hidden-sm-down login-right-block carousel slide" data-ride="carousel">
                    <ngb-carousel interval="4000">
                        <ng-template ngbSlide>
                            <img class="slider-img" src="./assets/images/slider-1.jpg" alt="Random first slide">
                            <div class="carousel-caption">
                                <p>{{'Getting_started_with' | translate}}</p>
                                <h3>{{'web_tarder_carousel' | translate}}</h3>
                                <span>V2.1.22</span>

                            </div>
                        </ng-template>
                        <ng-template ngbSlide>
                            <img class="slider-img" src="./assets/images/slider-2.jpg" alt="Random second slide">
                            <div class="carousel-caption">
                                <p>{{'Light_up_your_phone_Trade' | translate}}</p>
                                <h3>{{'Anytime_Anywhere_carousel' | translate}}</h3>
                            </div>
                        </ng-template>
                        <ng-template ngbSlide>
                            <img class="slider-img" src="./assets/images/slider-3.jpg" alt="Random third slide">
                            <div class="carousel-caption">
                                <p>{{'The_Worlds_Most_Advanced' | translate}}</p>
                                <h3 *ngIf="brandName!='GDEX'">{{'MT4_FX_Derivatives' | translate}}</h3>
                                <h3 *ngIf="brandName=='GDEX'">{{'FX_Derivatives' | translate}}</h3>
                            </div>
                        </ng-template>
                    </ngb-carousel>
                </div>
            </div>
        </div>

        <!-- loginbox -->
        <div class="main">
            <div class="loginbox">
                <div class="loginlogo text-center"></div>
            </div>
        </div>
        <!-- / loginbox -->
        <!-- authentication box -->

    </div>
</div>
