/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-button.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./form-button.component";
var styles_FormButtonComponent = [i0.styles];
var RenderType_FormButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormButtonComponent, data: {} });
export { RenderType_FormButtonComponent as RenderType_FormButtonComponent };
export function View_FormButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "dynamic-field dynamic-bt-block form-button"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(3, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "btn bt-second-color"], ["type", "submit"]], [[8, "disabled", 0], [8, "name", 0]], null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.group; _ck(_v, 1, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 3).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 3).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 3).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 3).ngClassValid; var currVal_5 = i1.ɵnov(_v, 3).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 3).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.config.disabled; var currVal_9 = _co.config.name; _ck(_v, 4, 0, currVal_8, currVal_9); var currVal_10 = _co.config.label; _ck(_v, 5, 0, currVal_10); }); }
export function View_FormButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "form-button", [], null, null, null, View_FormButtonComponent_0, RenderType_FormButtonComponent)), i1.ɵdid(1, 49152, null, 0, i3.FormButtonComponent, [], null, null)], null, null); }
var FormButtonComponentNgFactory = i1.ɵccf("form-button", i3.FormButtonComponent, View_FormButtonComponent_Host_0, {}, {}, []);
export { FormButtonComponentNgFactory as FormButtonComponentNgFactory };
