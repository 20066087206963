import { Component, ViewChild, AfterViewInit, ElementRef, OnInit } from '@angular/core';
import {LoginService} from './shared/login.service';
import {FormBuilder} from '@angular/forms';
import {Injectable} from '@angular/core';
import {NgbModal, NgbModalOptions, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorModule, DeviceDetectorService } from 'ngx-device-detector';
import {Router} from '@angular/router';
import * as moment from 'moment-timezone';
import {MT4AuthenticateComponent} from '../shared/mt4-authenticate/mt4-authenticate.component';
import {WTStorageService} from '../shared/service/wt-storage.service';
import {WTUtilService} from '../shared/service/wt-util.service';
import {MobileDeviceComponent} from '../shared/mobile-device/mobile-device.component';
import {TranslateService} from '../shared/translate/translate.service';
import {Mt4LoginService} from '../shared/mt4-authenticate/mt4-authenticate.service';
import * as wtconfig from '../../config/wt.config';

@Component({
  moduleId: module.id,
  selector: 'sd-home',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css'],
})
@Injectable()
export class LoginComponent implements OnInit, AfterViewInit {
  dismissible = true;
  type = 'warning';
  loginId: string = '';
  checkBox: boolean = false;
  errorMessage: string;
  validationMessage: string = '';
  fpValidationMessage: string = '';
  password: string = '';
  tempdata: any;
  accounts: Object;
  emailId: string = '';
  // showLogin: boolean = true;
  isValid: boolean = true;
  isInputEmpty: boolean = false;
  isFpEmailValid: boolean = false;
  isFpEmailVerified: boolean = false;
  closeResult: string;
  disableSubmit: boolean = false;
  disableFpSubmit: boolean = false;
  inLogin: boolean = true;
  isAndriodAppAvailable: boolean = false;
  androidPopupShown: boolean = false;
  mt4Accounts: any = [];
  brandName: any = wtconfig.WT_CONFIG.BRAND_NAME;
  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    windowClass: 'bounceIn animated md'
  };

  /**
   * Creates an instance of the LoginComponent
   */
  constructor(fb: FormBuilder,
              private loginService: LoginService,
              public wtStorageService: WTStorageService,
              private modalService: NgbModal,
              public translateService: TranslateService,
              public wtUtilService: WTUtilService,
              private deviceService: DeviceDetectorService,
              private mt4loginservice: Mt4LoginService,
              private router: Router) {

  }

  ngOnInit() {
    this.initializeApp();
  }

  initializeApp() {
    this.wtStorageService.mt4AccountsList = this.wtStorageService.mt4AccountsList || JSON.parse(sessionStorage.getItem('mt4AccountList'));
    if (this.wtStorageService.mt4AccountsList && this.wtStorageService.mt4AccountsList[0] && this.wtStorageService.mt4AccountsList[0]['accessToken']) {
      this.wtStorageService.showLogin = false;
      this.mt4Accounts = this.wtStorageService.mt4AccountsList;
      this.wtStorageService.selectedMt4Account = this.mt4Accounts[0];
      this.router.navigate(['/trade']);
    }
  }

  /**
   * For AutoFocus on Username
   */
  @ViewChild('input') input: ElementRef;

  ngAfterViewInit() {
    this.input && this.input.nativeElement && this.input.nativeElement.focus();

    if (localStorage.getItem('lanGuage') != null) {
      this.wtStorageService.selected_lang = localStorage.getItem('lanGuage');
      this.translateService.selectLang(localStorage.getItem('lanGuage'));
    } else if (localStorage.getItem('lanGuage') == null) {
      localStorage.setItem('lanGuage', 'en');
      this.translateService.selectLang(localStorage.getItem('lanGuage'));
    }
  }

  /**
   * login to application
   * @return {boolean} false to prevent default form submit behavior to refresh the page.
   */
  login(): any {
    if ((this.loginId == '' || this.loginId == null) && (this.password == '' || this.password == null)) {
      this.validationMessage = 'Login_Please_Enter_Account_and_Password';
      this.isValid = true;
      this.isInputEmpty = true;
      return;
    } else if (this.loginId == '' || this.loginId == null) {
      this.validationMessage = 'Please_Enter_Account';
      this.isValid = true;
      this.isInputEmpty = true;
      return;
    } else if (this.password == '' || this.password == null) {
      this.validationMessage = 'Please_Enter_Password';
      this.isValid = true;
      this.isInputEmpty = true;
      return;
    }
    /*else if((this.loginId != '' || this.loginId != null) && (this.password != '' || this.password != null) && this.checkBox == false){
      this.validationMessage = "Please_Accept_Privacy_Policy";
      this.isValid =true;
      this.isInputEmpty = true;
      return;
    }*/

    this.disableSubmit = true;
    this.mt4Accounts = [
      {
        'accountId': this.loginId
      }
      ];
      this.loginService.authUserFromFoxApi(this.loginId, this.password)
      .subscribe(
      (data: any) => {
        if(data.success) {
          this.wtStorageService.crmToken = data.token;
          sessionStorage.setItem('crmToken', data.token);
          sessionStorage.setItem('crmTokenExpireTime', data.expire_time);
          this.wtStorageService.crmTokenExpireTime = data.expire_time;
          sessionStorage.setItem('crmTokenStartTime', data.start_time);
          this.wtStorageService.crmTokenStartTime = data.start_time;
        } else {
          console.log('unable to login on fox api');
        }
      },
    (err: any) => {
      console.log(err);
    });
    this.mt4loginservice.mt4AuthUser(this.loginId, this.password)
    .subscribe(
    (data: any) => {
      this.mt4Accounts.forEach(
        (data2: any, key2: any) => {
          if (data.login == data2.accountId && data.access_token) {
            this.mt4Accounts[key2].validate = true; // for trade screen to check status
            this.mt4Accounts[key2].validateStatus = true; // to display status in mt4 auth screen
            this.mt4Accounts[key2].accessToken = data.access_token;
            this.mt4Accounts[key2].issuedTime = data['.issued'];
            this.mt4Accounts[key2].expiryTime = data['.expires'];
            this.mt4Accounts[key2].expiryTimeUnixTS =  moment(data['.expires']).unix();
            this.mt4Accounts[key2].expiresIn = data.expires_in;
            this.mt4Accounts[key2].tokenType = data.token_type;
          }
        });
        this.wtStorageService.mt4AccountsList = this.mt4Accounts;
        this.wtStorageService.selectedMt4Account = this.mt4Accounts[0];

      sessionStorage.setItem('mt4AccountList', JSON.stringify(this.mt4Accounts));
      sessionStorage.removeItem('tempMt4Accounts');
      this.router.navigate(['/trade']);

      let serverAthens = moment(data.server_time).tz("Europe/Athens").valueOf();
      let currentAthens = moment(new Date()).tz("Europe/Athens").valueOf();
      this.wtStorageService.crmOffsetValue = serverAthens - currentAthens;
      sessionStorage.setItem('crmOffsetValue', this.wtStorageService.crmOffsetValue.toString());
      this.tempdata = data;
      this.accounts = data['mt4accounts'];

      if (data['token'] === void 0) {
      } else {
        this.wtStorageService.showLogin = false;
       // const modalRef = this.modalService.open(MT4AuthenticateComponent, this.modalOptions);
        this.disableSubmit = false;
        this.loginId = '';
        this.password = '';
        this.checkBox = false;
        this.isValid = true;
        this.isInputEmpty = false;
      }
    },
    (err: any) => {
      this.disableSubmit = false;
      this.isValid = false;
      this.loginId = null;
      this.password = null;
    });
  return false;

  }

  openForgot() {
    this.inLogin = false;
  }

  backToLogin() {
    this.inLogin = true;
    this.loginId = null;
    this.password = null;
    this.isFpEmailValid = false;
    this.emailId = '';
    this.isInputEmpty = false;
    this.isValid = true;
  }

  backToLoginReset() {
    this.inLogin = true;
    this.isFpEmailVerified = false;
    this.loginId = null;
    this.password = null;
    this.isValid = true;
    this.isInputEmpty = false;
    this.isFpEmailValid = false;
  }

  forgotPwd() {
    // this.inLogin = false;
    let re = /\S+@\S+\.\S+/;
    if (this.emailId == null || this.emailId == '') {
      this.fpValidationMessage = 'Enter_Email';
      this.isFpEmailValid = true;
    } else if (!re.test(this.emailId)) {
      this.isFpEmailValid = true;
      this.fpValidationMessage = 'Enter_valid_email';
    } else {
      this.disableFpSubmit = true;
      this.loginService.forgotCrmPwd(this.emailId).subscribe(
        (data: any) => {
          this.isFpEmailValid = false;
          this.isFpEmailVerified = true;
          this.emailId = null;
          this.disableFpSubmit = false;
        },
        (err: any) => {
          this.disableFpSubmit = false;
          this.fpValidationMessage = 'Please_enter_your_registered_email';
          this.isFpEmailValid = true;
          this.isFpEmailVerified = false;
          this.emailId = '';
        });
    }
  }

  openFP(event: any, content: any) {
    event.preventDefault();
    this.modalService.open(content, this.modalOptions).result.then((result) => {
      this.isFpEmailVerified = false;
      this.isFpEmailValid = false;
    }, (reason) => {
      this.disableFpSubmit = false;
      this.isFpEmailVerified = false;
      this.emailId = '';
      this.isFpEmailValid = false;
    });
  }

  clicked(event: any) {
    event.preventDefault();
  }

  usClicked(event: any) {
    event.preventDefault();
    this.translateService.selectLang('en');
    localStorage.setItem('lanGuage', 'en');
  }

  chiClicked(event: any) {
    event.preventDefault();
    this.translateService.selectLang('zh');
    localStorage.setItem('lanGuage', 'zh');
  }

}
