<div *ngIf="tradeViewLoaded" [perfectScrollbar] class="trading-history side-collapse animated" [ngClass]="{
  'in_trading': showTradesHistory, 'slideInLeft': showTradesHistory, 'fadeOutLeftBig': !showTradesHistory}"
  id="tradeHistoryView">
  <div class="d-flex justify-content-between align-items-center trading-strip">


    <div class="heading d-flex align-items-center">
    <a href="" (click)="close()">
        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-left" role="img"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-angle-left fa-w-6 fa-9x">
        <path fill="currentColor"
          d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z"
          class=""></path>
      </svg>
    </a>
      {{'Hist_Full_Trade_History' | translate}}
    </div>


    <div *ngIf="isDataInitialized &&  (pageList.length > 1  || (currentRecords.length > 7 && pageList.length == 1))">

      <nav aria-label="..." class="d-flex align-items-center">
        <div class="history_total_pages"> {{'Hist_Showing' | translate}}  {{'Page_number' | translate}} {{currentPageNumber}} {{'Hist_of' | translate}} {{totalPages}}
          </div>
        <div class="pagination-symbol">
          <ul class="">
            <li class=" page-item">
              <button class="page-link" [disabled]="currentPageNumber == 1 || isRequestProcessing"
                (click)="showPreviousPage()">
                <i class="fa fa-angle-left"></i>
              </button>
            </li>
            <li class=" page-item" [ngClass]="{'active': currentPageNumber == page}" *ngFor="let page of pageList">
              <a class="page-link" (click)="updateCurrentPage(page)">{{page}}</a>
            </li>
            <li class=" page-item">
              <button class="page-link" [disabled]="(currentPageNumber == totalPages || isRequestProcessing)"
                (click)="showNextPage();">
                <i class="fa fa-angle-right"></i>
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
  <div *ngIf="!isHistoryEmpty && currentRecords.length == 0">
    <div class="loader-overlay">
      <div class="loader">
        <div class="loader__bar"></div>
        <div class="loader__bar"></div>
        <div class="loader__bar"></div>
        <div class="loader__bar"></div>
        <div class="loader__bar"></div>
        <div class="loader__ball"></div>
      </div>
    </div>
  </div>
  <!-- history Row -->
  <div *ngIf="isHistoryEmpty && !isRequestProcessing" class="history-empty">
    {{'Hist_No_History' | translate}}
  </div>
  <table class="table table-striped table-hover table-th-align trading-table" cellspacing="0" width="100%">

    <thead *ngIf="!isHistoryEmpty && currentRecords.length > 0">
      <tr class="tradehistory_h6">
        <!-- <th>
          <h6 style="color:white;">{{'Hist_Date' | translate}}</h6>
        </th> -->
        <th>
          <h6 style="color:white;">{{'Hist_Open_Date' | translate}}</h6>
        </th>
        <!-- <th>
          <h6 style="color:white;">{{'Hist_Close_Date' | translate}}</h6>
        </th> -->
        <th>
          <h6 style="color:white;">{{'Hist_Symbol' | translate}}</h6>
        </th>
        <th>
          <h6 style="color:white;">{{'Hist_Action' | translate}}</h6>
        </th>
        <th>
          <h6 style="color:white;">{{'Hist_Open' | translate}}</h6>
        </th>
        <th>
          <h6 style="color:white;">{{'Hist_Close' | translate}}</h6>
        </th>
        <th>
          <h6 style="color:white;">{{'Hist_Amount' | translate}}</h6>
        </th>
        <th>
          <h6 style="color:white;">{{'Hist_Profit' | translate}}</h6>
        </th>
        <th>
          <h6 style="color:white;">{{'Hist_Interval' | translate}}</h6>
        </th>
        <th>
          <h6 style="color:white;">{{'Hist_Result' | translate}}</h6>
        </th>
      </tr>
    </thead>
    <!--<tbody>
    </tbody>-->
    <tbody *ngIf="!isRequestProcessing">
      <tr class="tradehistory_h6" *ngFor="let record of currentRecords">
        <!-- <td style="color:white;">{{ record.date }}</td> -->
        <td style="color:white;">{{ record.open_time }}</td>
        <!-- <td style="color:white;">{{ record.close_time }}</td> -->

        <!--<td>{{ record.date | date:'yMd'}}-->
        <!--<span>{{record.date  | date:'HH:mm:ss'}}</span></td>-->
        <td class="yellowtxt">{{record.symbol}}
          <!-- <h6 class="yellowtxt">{{record.symbol}}</h6> -->
        </td>
        <td>
          {{record.action}}
          <!-- <h6><i class="fa iconsize"
              [ngClass]="{'fa-angle-double-down wintxt' : record.action == 'Down', 'fa-angle-double-up losetxt' : record.action == 'Up'}"></i>
          </h6> -->
        </td>
        <td style="color:white;">{{record.open_price}}</td>
        <td style="color:white;">{{record.close_price}}</td>
        <td style="color:white;">{{record.amount}}</td>
        <td [ngClass]="selectedLang(record.result)">
          {{record.profit}}
          <!-- <h6 class=""
            [ngClass]="{'wintxt' : record.result == 'Loss', 'losetxt' : record.result == 'Win', 'yellowtxt' : record.result == 'Draw' }">
            {{record.profit}}</h6> -->
        </td>
        <td style="color:white;">{{record.interval}}</td>
          <!-- <h6 class=""
            [ngClass]="{'wintxt' : record.result == 'Loss', 'losetxt' : record.result == 'Win', 'yellowtxt' : record.result == 'Draw'}">
            {{record.result | translate}}</h6> -->
            <td [ngClass]="selectedLang(record.result)">{{record.result}}</td>
      </tr>
    </tbody>
    <tfoot *ngIf="!isRequestProcessing" class="trading-strip">
      <tr>
        <td colspan="9">
          <nav class="pagination-symbol" aria-label="..."
            *ngIf="(isDataInitialized && ((currentRecords.length > 7 && !this.wtStorageService.isPortraitMode) || (currentRecords.length > 15 && this.wtStorageService.isPortraitMode)))">
            <ul class="">
              <li class="float-left page-item">
                <button class="page-link" [disabled]="currentPageNumber == 1 || isRequestProcessing"
                  (click)="showPreviousPage();scrollToTop();">
                  <i class="fa fa-angle-left"></i>
                </button>
              </li>
              <li class="float-left page-item" [ngClass]="{'active': currentPageNumber==page}"
                *ngFor="let page of pageList">
                <a class="page-link" (click)="updateCurrentPage(page);scrollToTop();">{{page}}</a>
              </li>
              <li class="float-left page-item">
                <button class="page-link" [disabled]="(currentPageNumber == totalPages || isRequestProcessing)"
                  (click)="showNextPage();scrollToTop();">
                  <i class="fa fa-angle-right"></i>
                </button>
              </li>
            </ul>
          </nav>
          <!-- <ngb-pagination [collectionSize]="totalRecords" [pageSize]="10"
                [(page)]="page" [boundaryLinks]="true" [maxSize]="3" [rotate]="true"
                (pageChange)="updateCurrentPage(page)" [ellipses]="false"
                ></ngb-pagination> -->
        </td>
      </tr>
    </tfoot>
  </table>
  <div class="loader-overlay" *ngIf="isRequestProcessing && !isHistoryEmpty && currentRecords.length !== 0">
    <div class="loader">
      <div class="loader__bar"></div>
      <div class="loader__bar"></div>
      <div class="loader__bar"></div>
      <div class="loader__bar"></div>
      <div class="loader__bar"></div>
      <div class="loader__ball"></div>
    </div>
  </div>


  <!-- end history Row -->
</div>
