/* To handle DST changes time 'Europe/Athens' time is used to calculate time.
 * However there is an issue with time conversion at BOAPI end. When DST is on, offset is not changed to +300,
 * in the response what we get. Hence if DST is on subtracting 1hr i.e., 3600000 from time, to display proper time in
 * highchart.
 */
import { ElementRef, OnInit, AfterViewInit, AfterViewChecked, Renderer2, OnDestroy } from '@angular/core';
// import '../../../node_modules/intl/locale-data/jsonp/en.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as moment from 'moment-timezone';
import swal from 'sweetalert2';
import { PricehubService } from './shared/pricehub.service';
import { TradeshubService } from './shared/tradeshub.service';
import { TradeService } from './shared/trade.service';
import { ChartComponent } from './chart/chart.component';
import { OpenTradesComponent } from './openTrades/open-trades.component';
import { TradeHistoryComponent } from './history/history.component';
import { IndicatorsComponent } from './indicators/indicators.component';
import { IndicatorService } from './indicators/indicator.service';
import { WTStorageService } from '../shared/service/wt-storage.service';
import { WTUtilService } from '../shared/service/wt-util.service';
import { TranslatePipe } from '../shared/translate/translate.pipe';
import { MT4AuthenticateComponent } from '../shared/mt4-authenticate/mt4-authenticate.component';
import { WTCrmService } from '../shared/service/wt-crm.service';
import { UserProfileComponent } from '../shared/user-profile/user-profile.component';
import { IndicatorsStorageService } from '../shared/indicators/indicators-storage.service';
import { TranslateService } from '../shared/translate/translate.service';
import * as wtconfig from '../../config/wt.config';
import { DomSanitizer } from '@angular/platform-browser';
var noop = function () { };
var ɵ0 = noop;
var hasOwnProperty = Object.prototype.hasOwnProperty;
/**
 * This class represents the lazy loaded TradeComponent.
 */
var TradeComponent = /** @class */ (function () {
    function TradeComponent(tradeshubService, renderer, sanitizer, tradeService, pricehubService, modalService, wtCrmService, router, idle, wtStorageService, translateService, wtUtilService, indicatorStorage, indicatorService, deviceService) {
        var _this = this;
        this.tradeshubService = tradeshubService;
        this.renderer = renderer;
        this.sanitizer = sanitizer;
        this.tradeService = tradeService;
        this.pricehubService = pricehubService;
        this.modalService = modalService;
        this.wtCrmService = wtCrmService;
        this.router = router;
        this.idle = idle;
        this.wtStorageService = wtStorageService;
        this.translateService = translateService;
        this.wtUtilService = wtUtilService;
        this.indicatorStorage = indicatorStorage;
        this.indicatorService = indicatorService;
        this.deviceService = deviceService;
        this.lang = localStorage.getItem('lanGuage');
        this.brandName = wtconfig.WT_CONFIG.BRAND_NAME;
        this.group = 13;
        this.chartTab = [];
        this.dropDowns = {};
        this.intervalList = [];
        this.isFavListEmpty = true;
        this.selectedSymConfig = [];
        this.selectedChartIndex = -1;
        this.favCount = 0;
        this.removeIndex = 0;
        this.maxAmountFlag = false;
        this.openPositions = 0;
        this.currencySymbolPadding = 34;
        this.amountIpWidth = 50;
        this.statusDescription = '';
        this.chartLoaded = false;
        this.showFSbutton = false;
        // fullScrView : boolean = false;
        this.initLoad = false;
        // isPortraitMode: boolean = false;
        this.isLoggingOut = false;
        this.accountSwitch = false;
        this.showCal = false;
        this.modalOption = {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'fadeInDown animated md',
            container: '#body_full_screen'
        };
        this.tradeValidate = false;
        this.isSymbolLoaded = false;
        this.isMt4Switched = false;
        this.disableClose = false;
        this.secLeft = 60;
        this.orientationModalOpened = false;
        this.isMobileDevice = false;
        this.countryFlagUrl = 'assets/images/us-flag.jpg';
        this.isDocumentLoaded = false;
        this.inFullScreenMode = false;
        this.loadIndicator = true;
        this.addPlot = false;
        this.ignoreHashChange = false;
        this.renderer.addClass(document.body, 'webtrader-chart');
        var self = this;
        this.wtStorageService.deviceInfo = this.deviceService.getDeviceInfo();
        this.device = this.wtStorageService.deviceInfo && this.wtStorageService.deviceInfo.device || null;
        if (this.device === "android"
            || this.device === "iphone"
            || (window.innerHeight > window.innerWidth && window.innerWidth < 640)) {
            this.isMobileDevice = true;
        }
        window.addEventListener("orientationchange", function () {
            self.applyOrientation();
        }, false);
        this.applyOrientation();
        this.isConnected = Observable.merge(Observable.of(navigator.onLine), Observable.fromEvent(window, 'online').map(function () { return true; }), Observable.fromEvent(window, 'offline').map(function () { return false; }));
        this.isHidden = Observable.fromEvent(document, 'visibilitychange').map(function () { return document.visibilityState; });
        this.selectedSymConfig.winPayout = 0;
        this.tradeAmount = 0;
        this.preAmount = 0;
        this.wtStorageService.selectedSymbol = 'Symbols';
        this.selectedSymConfig.interval = 0;
        this.isConnected.subscribe(function (data) {
            if (data == false) {
                swal({
                    html: "<span style=\"color:#ffffff\">" + (new TranslatePipe(_this.translateService))
                        .transform("Internet_connection_is_lost_Reconnecting") + "<span>",
                    width: 400,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
                    showConfirmButton: false,
                    target: '#body_full_screen'
                });
            }
            else if (data == true) {
                swal.close();
                if (_this.wtStorageService.selectedSymbol !== 'Symbols') {
                    _this.fetchOldPrice();
                    _this.indicatorStorage.resetIndicatorStorage();
                }
            }
        });
        this.isHidden.subscribe(function (data) {
            if (document.hidden) {
                _this.pricehubService.stopPricesHub();
                _this.tradeshubService.stopTradesHub();
            }
            else {
                _this.pricehubService.startPricesHub();
                _this.tradeshubService.startTradesHub();
                if (_this.wtStorageService.selectedSymbol !== 'Symbols') {
                    _this.fetchOldPrice();
                    _this.indicatorStorage.resetIndicatorStorage();
                }
            }
        });
        this.crmLogin = this.wtUtilService.config.CRM_APP;
        this.dropDowns = {
            closeSymSel: true,
            indicSel: true,
            calcSel: true
        };
        // sets an idle timeout of 14min.
        idle.setIdle(86400);
        // sets a timeout period of 60 seconds. after 15min of inactivity, the user will be considered timed out.
        idle.setTimeout(86400);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        idle.onIdleEnd.subscribe(function () {
            if (_this.sessionExtendDialog)
                _this.sessionExtendDialog.dismiss('closing');
            _this.secLeft = 60;
            _this.idle.watch();
        });
        idle.onTimeout.subscribe(function () {
            _this.sessionExtendDialog.dismiss('closing');
            _this.secLeft = 60;
            _this.logOut();
        });
        idle.onIdleStart.subscribe(function () { return noop; });
        idle.onTimeoutWarning.subscribe(function () {
            if (_this.secLeft == 60) {
                _this.sessionExtendDialog = _this.modalService.open(_this.extendSession, { backdrop: 'static', windowClass: 'md', keyboard: false, container: '#body_full_screen' });
            }
            if (_this.secLeft > 0)
                _this.secLeft--;
        });
        this.pricehubService.getPrice()
            .subscribe(function (data) {
            _this.newPriceUpdate(data);
        });
        setInterval(function () {
            _this.date = moment(moment().tz("Europe/Athens").valueOf() + _this.wtStorageService.offsetValue).tz("Europe/Athens").format('DD-MMM-YYYY hh:mm:ss');
        }, 1000);
        this.tradeshubService.getConfiguration().subscribe(function (config) {
            _this.configuration = config;
            if (_this.configuration.payoutConfigurations && _this.configuration.payoutConfigurations.length > 0) {
                _this.isSymbolLoaded = false;
                _this.generateSymbolList();
                _this.setFavorites(_this.wtStorageService.selectedMt4Account.accountId);
                _this.setCharts(_this.wtStorageService.selectedMt4Account.accountId);
                var symbolName = null;
                var selectedSymbFound = false;
                var securityName = null;
                if (_this.chart) {
                    if (_this.symbolConfiguration) {
                        for (var security in _this.symbolConfiguration) {
                            for (var symbol in _this.symbolConfiguration[security]) {
                                if (_this.symbolConfiguration[security][symbol].length > 0 && !selectedSymbFound) {
                                    if (!symbolName) {
                                        symbolName = symbol;
                                        securityName = security;
                                        if (!_this.selectedChart)
                                            break;
                                    }
                                    if (_this.selectedChart && _this.selectedChart.symbol === symbol) {
                                        selectedSymbFound = true;
                                        symbolName = symbol;
                                        securityName = security;
                                        break;
                                    }
                                }
                            }
                            ;
                        }
                        if (_this.selectedChart && selectedSymbFound) {
                            if ((!_this.initLoad || _this.accountSwitch || _this.selectedChart.symbol !== _this.wtStorageService.selectedSymbol)
                                && (_this.selectedChart.timeFrame.toLowerCase() !== _this.wtStorageService.selectedTimeFrame)) {
                                _this.wtStorageService.selectedTimeFrame = _this.selectedChart.timeFrame.toLowerCase();
                                if (_this.symbolConfiguration.constructor === Object && Object.keys(_this.symbolConfiguration).length !== 0) {
                                    _this.changeSymbol(_this.selectedChart.symbol, _this.symbolConfiguration[securityName][_this.selectedChart.symbol], false);
                                }
                            }
                            else if (_this.wtStorageService.selectedSymbol !== 'Symbols') {
                                _this.intervalList = _this.symbolConfiguration[securityName][_this.wtStorageService.selectedSymbol];
                                _this.selectedSymConfig = _this.clone(_this.intervalList[0]);
                                _this.accountSwitch = false;
                            }
                            else {
                                _this.wtStorageService.selectedSymbol = symbolName;
                                _this.wtStorageService.selectedTimeFrame = _this.wtStorageService.defaultTimeFrame;
                                _this.changeSymbol(_this.wtStorageService.selectedSymbol, _this.symbolConfiguration[securityName][_this.wtStorageService.selectedSymbol], false);
                            }
                        }
                        else {
                            _this.wtStorageService.selectedSymbol = symbolName;
                            _this.wtStorageService.selectedTimeFrame = _this.wtStorageService.defaultTimeFrame;
                            _this.changeSymbol(_this.wtStorageService.selectedSymbol, _this.symbolConfiguration[securityName][_this.wtStorageService.selectedSymbol], false);
                        }
                    }
                    ;
                    //	this.fetchOldPrice();
                }
            }
            else {
                _this.chartLoaded = true;
                _this.initLoad = true;
                _this.accountSwitch = false;
                _this.isSymbolLoaded = true;
                if (typeof _this.configuration.payoutConfigurations !== 'undefined') {
                    _this.showErrorMessage('Trade_No_Symbols_Found');
                }
            }
        });
        this.tradeshubService.getAccountInfoUpdate().subscribe(function (accntInfo) {
            _this.accountInfo = accntInfo;
        });
        this.tradeshubService.getSendTradeStatus().subscribe(function (sendTradeStatus) {
            if (sendTradeStatus.status != undefined && sendTradeStatus.status == 134) {
                _this.statusDescription = (new TranslatePipe(_this.translateService)).transform("Trade_Insufficient_Account_Balance");
            }
            else if (sendTradeStatus.status != undefined && sendTradeStatus.status == 148) {
                _this.statusDescription = (new TranslatePipe(_this.translateService)).transform("Trade_Too_many_orders");
            }
            else if (sendTradeStatus.status != undefined && sendTradeStatus.status == 132) {
                _this.statusDescription = (new TranslatePipe(_this.translateService)).transform("Trade_Market_is_closed");
            }
            else if (sendTradeStatus.status != undefined && sendTradeStatus.status == 3.0) {
                _this.statusDescription = (new TranslatePipe(_this.translateService)).transform("Trade_Invalid_parameters");
            }
            else if (sendTradeStatus.status != undefined && sendTradeStatus.status == -100) {
                _this.statusDescription = (new TranslatePipe(_this.translateService)).transform("Trade_Submit_order_failure");
            }
            else if (sendTradeStatus.status != undefined && sendTradeStatus.status == 131) {
                _this.statusDescription = (new TranslatePipe(_this.translateService)).transform("Trade_Invalid_Amount");
            }
            else if (sendTradeStatus.status != undefined && sendTradeStatus.status == -1) {
                _this.statusDescription = (new TranslatePipe(_this.translateService)).transform("Trade_Submit_order_failure");
            }
            else if (sendTradeStatus.status != undefined && sendTradeStatus.status == 64) {
                _this.statusDescription = (new TranslatePipe(_this.translateService)).transform("Trade_Account_Inactive");
            }
            if (sendTradeStatus.status != undefined && sendTradeStatus.status == 0) {
                swal({
                    html: "<i style=\"color:#ffffff;\">" + (new TranslatePipe(_this.translateService))
                        .transform("Trade_Order_placed_successfully") + "</i>",
                    timer: 1000,
                    width: 300,
                    padding: 20,
                    background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
                    showConfirmButton: false,
                    target: '#body_full_screen'
                });
                _this.ShowOpenTrade();
            }
            else if (sendTradeStatus.status != undefined && sendTradeStatus.status != 0) {
                swal({
                    html: '<i style="color:#ffffff;">' + _this.statusDescription + '</i> ',
                    timer: 1000,
                    width: 300,
                    padding: 20,
                    background: '#954553 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
                    showConfirmButton: false,
                    target: '#body_full_screen'
                });
            }
        });
        this.tradeshubService.getTradeUpdate().subscribe(function (tradeUpdate) {
            _this.tradeUpdate = tradeUpdate;
            if (_this.tradeUpdate && _this.tradeUpdate.accountInfo)
                _this.accountInfo.balance = _this.tradeUpdate.accountInfo.balance;
        });
        // this.tradeshubService.getSignalrStatus().subscribe(
        // 	(signalrStatus:any) => {
        // 		if(signalrStatus == "Internet connection is slow"){
        // 			swal({
        // 					text:`<span style="color:#ffffff">${(new TranslatePipe(this.translateService))
        // 								.transform("Internet_connection_is_slow")}<span>`,
        // 					timer:2000,
        // 					width: 400,
        // 					background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
        // 					showConfirmButton:false
        // 				});
        // 		}
        // 		else if(signalrStatus == "Internet connection is lost. Reconnecting....") {
        // 			swal({
        // 					text:`<span style="color:#ffffff">${(new TranslatePipe(this.translateService))
        // 								.transform("Internet_connection_is_lost_Reconnecting")}<span>`,
        // 					width: 400,
        // 					allowOutsideClick: false,
        // 					allowEscapeKey :false,
        // 					background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
        // 					showConfirmButton:false
        // 				});
        // 			swal.disableButtons();
        // 		}
        // 		else if(signalrStatus == "Connecting...."){
        // 			swal({
        // 					text:`<span style="color:#ffffff">${(new TranslatePipe(this.translateService))
        // 								.transform("Connecting")}<span>`,
        // 					width: 400,
        // 					allowOutsideClick: false,
        // 					allowEscapeKey :false,
        // 					background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
        // 					showConfirmButton:false
        // 				});
        // 			swal.disableButtons();
        // 		}else if (signalrStatus == "Connection established."){
        // 			swal.close();
        // 		}
        // 	}
        // );
    }
    TradeComponent.prototype.applyOrientation = function () {
        if (window.innerHeight > window.innerWidth || window.orientation === 0) {
            this.wtStorageService.isPortraitMode = true;
        }
        else {
            this.wtStorageService.isPortraitMode = false;
        }
    };
    TradeComponent.prototype.ngAfterViewChecked = function () {
        if (this.chartContainerWidth != this.tradeScreenDiv.nativeElement.offsetWidth && this.chart) {
            this.chart.setSize();
        }
        this.chartContainerWidth = this.tradeScreenDiv.nativeElement.offsetWidth;
    };
    TradeComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var _a;
        var self = this;
        // if (this.device === "android" || this.device === "iphone") {
        window.onresize = function (event) {
            var document = window.document;
            if (!document.fullscreenElement &&
                !document.mozFullScreenElement &&
                !document.webkitFullscreenElement &&
                !document.msFullscreenElement) {
                self.applyOrientation();
            }
        };
        // this.applyOrientation();
        // }
        this.chart = this.chartComponent.getChart();
        // Storing settings in localstorage for the first time
        var settings = JSON.parse(localStorage.getItem('settings'));
        if (settings == null || !settings[this.wtStorageService.selectedMt4Account.accountId]) {
            var settings_1 = (_a = {},
                _a[this.wtStorageService.selectedMt4Account.accountId] = { sound: this.wtStorageService.soundSetting, oneClick: this.wtStorageService.oneclickSetting },
                _a);
            localStorage.setItem('settings', JSON.stringify(settings_1));
        }
        else {
            this.wtStorageService.oneclickSetting = settings[this.wtStorageService.selectedMt4Account.accountId].oneClick;
            this.wtStorageService.soundSetting = settings[this.wtStorageService.selectedMt4Account.accountId].sound;
        }
        if (localStorage.getItem('lanGuage') != null) {
            this.wtStorageService.selected_lang = localStorage.getItem('lanGuage');
            this.translateService.selectLang(localStorage.getItem('lanGuage'));
        }
        if (localStorage.getItem('soundSetting') != null) {
            this.wtStorageService.soundSetting = JSON.parse(localStorage.getItem("soundSetting"));
        }
        if (localStorage.getItem('oneclickSetting') != null) {
            this.wtStorageService.oneclickSetting = JSON.parse(localStorage.getItem("oneclickSetting"));
        }
        var symbolName = null;
        var selectedSymbFound = false;
        var securityName = null;
        if (this.isSymbolLoaded) {
            for (var security in this.symbolConfiguration) {
                for (var symbol in this.symbolConfiguration[security]) {
                    if (this.symbolConfiguration[security][symbol].length > 0 && !selectedSymbFound) {
                        if (!symbolName) {
                            symbolName = symbol;
                            securityName = security;
                            this.wtStorageService.selectedTimeFrame = this.wtStorageService.defaultTimeFrame;
                            if (!this.selectedChart)
                                break;
                        }
                        if (this.selectedChart && this.selectedChart.symbol === symbol) {
                            selectedSymbFound = true;
                            symbolName = symbol;
                            securityName = security;
                            this.wtStorageService.selectedTimeFrame = this.selectedChart.timeFrame.toLowerCase();
                            break;
                        }
                    }
                }
                ;
            }
            if (symbolName) {
                this.changeSymbol(symbolName, this.symbolConfiguration[securityName][symbolName], false);
            }
        }
        Highcharts.addEvent(this.chart, 'redraw', function (event) {
            if (_this.chart.xAxis) {
                var extremes = _this.chart.xAxis[0].getExtremes();
                if (extremes && (extremes.min == extremes.dataMin || extremes.min < _this.chart.series[0].xData[1]) && _this.chartLoaded) {
                    _this.getMorePastData();
                }
            }
            _this.alignCurrentPriceLabel();
            _this.openTradesComponent.alignPlotlineLabel();
        });
        Highcharts.addEvent(this.chart.xAxis[0], 'setExtremes', function (event) {
            _this.openTradesComponent.alignPlotlineLabel();
            _this.alignCurrentPriceLabel();
        });
        this.wtUtilService.flipColors();
        this.chartComponent.flipColors();
        this.idle.watch();
    };
    TradeComponent.prototype.resetCandlesView = function () {
        if (this.chartData.length > 0) {
            this.chart.xAxis[0].setExtremes(this.chartData[this.chartData.length > 25 ? this.chartData.length - 25 : 0][0], this.chartData[this.chartData.length - 1][0], true, true);
            this.chart.yAxis[0].setExtremes(this.chart.yAxis[0].getExtremes().dataMin, this.chart.yAxis[0].getExtremes().dataMax, true, true);
        }
    };
    TradeComponent.prototype.newPriceUpdate = function (newPrice) {
        if (newPrice.bar && newPrice.bar.close && newPrice.info &&
            newPrice.info.symbol == this.wtStorageService.selectedSymbol &&
            newPrice.info.timeframe.toLowerCase() == this.wtStorageService.selectedTimeFrame.toLowerCase()) {
            this.currentPrice = this.doPrecision(newPrice.bar.close);
            try {
                if (this.chartData && this.chartData.length > 0 && this.chart) {
                    // if(this.chartData[this.chartData.length-1][0] == (moment.tz("Europe/Athens").isDST()?
                    // 						new Date(newPrice.bar.datetime).getTime()-3600000 :new Date(newPrice.bar.datetime).getTime())) {
                    if (this.chartData[this.chartData.length - 1][0] == new Date(newPrice.bar.datetime).getTime()) {
                        this.chartData[this.chartData.length - 1] = [
                            /*moment.tz("Europe/Athens").isDST()?
                                new Date(newPrice.bar.datetime).getTime()-3600000
                                        :new Date(newPrice.bar.datetime).getTime(),*/
                            new Date(newPrice.bar.datetime).getTime(),
                            newPrice.bar.open,
                            newPrice.bar.high,
                            newPrice.bar.low,
                            newPrice.bar.close
                        ];
                        this.chart.series[0].data[this.chart.series[0].data.length - 1].update(Object.assign(this.chartData[this.chartData.length - 1]));
                        this.indicatorService.updateNewPoint(this.chart, [this.chart.series[0].xData, this.chart.series[0].yData], this.indicatorsComponent, 'update');
                    }
                    else {
                        this.chartData.push([
                            /*moment.tz("Europe/Athens").isDST()?
                                                    new Date(newPrice.bar.datetime).getTime()-3600000
                                                            :new Date(newPrice.bar.datetime).getTime(),*/
                            new Date(newPrice.bar.datetime).getTime(),
                            newPrice.bar.open,
                            newPrice.bar.high,
                            newPrice.bar.low,
                            newPrice.bar.close
                        ]);
                        this.chart.series[0].addPoint(Object.assign([], this.chartData[this.chartData.length - 1]), true, false, true);
                        this.indicatorService.updateNewPoint(this.chart, [this.chart.series[0].xData, this.chart.series[0].yData], this.indicatorsComponent, 'addpoint');
                    }
                    this.chart.yAxis[0].setExtremes(this.chart.yAxis[0].getExtremes().dataMin, this.chart.yAxis[0].getExtremes().dataMax, true, true);
                    if (!this.addPlot) {
                        var price = this.doPrecision(this.chartData[this.chartData.length - 1][4]);
                        this.chart.yAxis[0].addPlotLine({
                            value: newPrice.bar.close,
                            color: '#d6d41f',
                            arrow: true,
                            width: 2,
                            id: 'currentPrice',
                            zIndex: 999,
                            label: {
                                align: 'right',
                                backgroundColor: '#d6d41f',
                                textAlign: 'left',
                                verticalAlign: 'middle',
                                text: '<span class="current-price-indicator">' +
                                    price.toString().substr(0, 4) + '<span class="dec-bold">' +
                                    price.toString().substr(4, price.toString().length) + '</span></span>',
                            },
                        });
                        this.chart.yAxis[0].plotLinesAndBands[this.getPlotLineIndex()].label
                            .yPosition = this.chart.yAxis[0].toPixels(newPrice.bar.close);
                        this.addPlot = true;
                        this.alignCurrentPriceLabel();
                    }
                }
            }
            catch (error) {
            }
        }
    };
    TradeComponent.prototype.doPrecision = function (num) {
        var numString, splitNum = [], precNum = '';
        if (!num)
            return '';
        numString = num.toString();
        if (numString.indexOf('.') > -1) {
            splitNum = numString.split('.');
            if (splitNum[1]) {
                precNum = splitNum[1] + '0'.repeat(5 - splitNum[1].length);
            }
        }
        else if (numString.indexOf('.') == -1) {
            splitNum[0] = numString;
            precNum = '00000';
        }
        return splitNum[0] + "." + precNum;
    };
    TradeComponent.prototype.fetchOldPrice = function () {
        var _this = this;
        this.chartLoaded = false;
        this.chartData = [];
        if (this.wtStorageService.selectedSymbol !== 'Symbols') {
            this.tradeService.getPastCandles(this.wtStorageService.selectedSymbol, this.wtStorageService.selectedTimeFrame, '200', null).subscribe(function (oldPriceList) {
                if (_this.wtStorageService.selectedSymbol === oldPriceList.info.symbol
                    && _this.wtStorageService.selectedTimeFrame.toLowerCase() === oldPriceList.info.timeframe.toLowerCase()) {
                    var priceList = [];
                    for (var i = 0; i < oldPriceList.bars.length; i++) {
                        priceList[i] = [
                            /*moment(oldPriceList.bars[i]['datetime']).tz("Europe/Athens").isDST() ? new Date(oldPriceList.bars[i]['datetime']).getTime() - 3600000
                              : new Date(oldPriceList.bars[i]['datetime']).getTime(), // change required to handle daylight saving*/
                            new Date(oldPriceList.bars[i]['datetime']).getTime(),
                            oldPriceList.bars[i]['open'],
                            oldPriceList.bars[i]['high'],
                            oldPriceList.bars[i]['low'],
                            oldPriceList.bars[i]['close']
                        ];
                    }
                    _this.lastRecordDate = oldPriceList.bars[0].datetime;
                    _this.chartData = priceList;
                    _this.chart.series[0].setData(Object.assign([], _this.chartData), true, true, true);
                    _this.indicatorService.updatePreviousPoints(_this.chart, [_this.chart.series[0].xData, _this.chart.series[0].yData], _this.indicatorsComponent);
                    if (_this.chartData[_this.chartData.length - 25])
                        _this.resetCandlesView();
                    _this.chartLoaded = true;
                    _this.initLoad = true;
                    _this.accountSwitch = false;
                    _this.chartComponent.zoomCount = 0;
                    _this.openTradesComponent.addAllPlotLines();
                    // this.chart.update({
                    // 	mapNavigation: { enableButtons: true }
                    // });
                    if (_this.wtStorageService.deviceInfo.device === "android" || _this.wtStorageService.deviceInfo.device === "iphone" || _this.wtStorageService.deviceInfo.device === "ipad") {
                        /* this.chart.update({
                          exporting: {
                            buttons: {
                              customButton: {
                                y: -65
                              }
                            }
                          }
                        }) */
                    }
                    if (_this.loadIndicator) {
                        _this.indicatorsComponent.checkIndicators();
                        _this.loadIndicator = false;
                    }
                }
            });
        }
        else {
            this.chartLoaded = true;
        }
    };
    TradeComponent.prototype.openDeposit = function (depositRef) {
        this.showCal = false;
        //this.modalService.open(DepositComponent, { backdrop: 'static', keyboard: false, windowClass: 'depositModal' });
        this.modalService.open(depositRef, { backdrop: 'static', windowClass: 'sm', keyboard: false, container: '#body_full_screen' });
        //this.modalService.open(DepositComponent, { backdrop: 'static', keyboard: false, windowClass: 'depositModal' });
    };
    TradeComponent.prototype.openWithdraw = function (withdrawRef) {
        if (this.wtStorageService.selectedMt4Account.demo) {
            swal({
                html: "<span style=\"color:#ffffff\">" + (new TranslatePipe(this.translateService))
                    .transform('demoAccountCannotProceed') + "<span>",
                width: 400,
                timer: 1000,
                allowOutsideClick: false,
                allowEscapeKey: false,
                background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
                showConfirmButton: false,
                target: '#body_full_screen'
            });
            return false;
        }
        this.modalService.open(withdrawRef, {
            backdrop: 'static',
            windowClass: 'sm deposit-modal',
            keyboard: false,
            container: '#body_full_screen'
        });
    };
    // openWithdraw(withdrawRef: any) {
    //   this.modalService.open(WithdrawalComponent, { backdrop: 'static', keyboard: false, windowClass: 'depositModal' });
    // }
    // changeOrientation() {
    //this.modalService.open(DepositComponent);
    // if (this.device == "iphone" && !this.wtStorageService.isPortraitMode) {
    // if (this.orientationModalOpened) {
    // 	this.orientationModalRef.close();
    // 	this.orientationModalOpened = false;
    // }
    // } else if (!this.orientationModalOpened) {
    // this.orientationModalOpened = true;
    //this.orientationModalRef = this.modalService.open(OrientationComponent, { backdrop: 'static', windowClass: 'sm', keyboard: false, container: '#body_full_screen' });
    // this.orientationModalRef.result.then((result: any) => {
    // 	this.orientationModalOpened = false;
    // }, (reason: any) => {
    // 	this.orientationModalOpened = false;
    // });
    // }
    // this.modalService.open(orientationRef,{backdrop:'static', windowClass:'sm', keyboard:false, container:'#body_full_screen'});
    // }
    TradeComponent.prototype.makeTrade = function (tradeAction, oneclick) {
        var _this = this;
        this.tradeAction = tradeAction;
        this.showCal = false;
        if (this.accountInfo.balance < this.selectedSymConfig.minAmount || this.tradeAmount > this.accountInfo.balance) {
            swal({
                html: "<i style=\"color:#ffffff;\">" + (new TranslatePipe(this.translateService))
                    .transform("Trade_Not_enough_money") + "</i>",
                timer: 1000,
                width: 300,
                padding: 20,
                background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
                showConfirmButton: false,
                target: '#body_full_screen'
            });
        }
        else if (this.tradeAmount < this.selectedSymConfig.minAmount || this.tradeAmount > this.selectedSymConfig.maxAmount) {
            swal({
                html: "<i style=\"color:#ffffff;\"> " + (new TranslatePipe(this.translateService))
                    .transform("Trade_Amount_Range_1") + " " + this.selectedSymConfig.minAmount + ("" + (new TranslatePipe(this.translateService))
                    .transform("Trade_Amount_Range_2")) + this.selectedSymConfig.maxAmount + ("" + (new TranslatePipe(this.translateService))
                    .transform("Trade_Amount_Range_3")) + '</i> ',
                timer: 1000,
                width: 300,
                padding: 20,
                background: '#954553 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
                showConfirmButton: false,
                target: '#body_full_screen'
            });
        } /* else if (this.tradeAmount % this.selectedSymConfig.stepAmount > 0) {
                swal({
                    html: `<i style="color:#ffffff;"> ${(new TranslatePipe(this.translateService))
                        .transform("Trade_Amount_Multiples_Of")}` + this.selectedSymConfig.stepAmount + `${(new TranslatePipe(this.translateService))
                            .transform("Trade_Amount_Multiples_Of_After")}` + '</i> ',
                    timer: 1000,
                    width: 300,
                    padding: 20,
                    background: '#954553 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
                    showConfirmButton: false,
                    target: '#body_full_screen'
                });
            } */ // commented code as always the amount allowed is not multiple of stepamount. its is amount * stepAmount +minamount
        else {
            if (this.wtStorageService.oneclickSetting == true) {
                this.sendTrade(tradeAction);
            }
            else {
                this.modalService.open(oneclick, { backdrop: 'static', windowClass: 'oneClick bounceIn animated md', keyboard: false, container: '#body_full_screen' }).
                    result.then(function (result) {
                }, function (reason) {
                    _this.sendTrade(tradeAction);
                });
                ;
            }
        }
    };
    TradeComponent.prototype.sendTrade = function (tradeAction) {
        this.tradeAction = tradeAction;
        this.trade = {
            "action": tradeAction,
            "amount": this.tradeAmount,
            "symbol": this.wtStorageService.selectedSymbol,
            "interval": this.selectedSymConfig.interval,
            "payout": this.selectedSymConfig.winPayout,
            "appType": this.wtUtilService.config.appType,
        };
        this.tradeshubService.sendTrade(this.trade);
    };
    TradeComponent.prototype.logOut = function () {
        var _this = this;
        this.isLoggingOut = true;
        this.tradeService.logOut()
            .subscribe(function (logoutInfo) {
            _this.showLogoutMessage();
        }, function (err) {
            _this.showLogoutMessage();
        });
    };
    TradeComponent.prototype.showLogoutMessage = function () {
        var _this = this;
        var msg = (new TranslatePipe(this.translateService)).transform("Logout_Success_Message");
        this.isLoggingOut = false;
        sessionStorage.clear();
        this.wtStorageService.clearStorage();
        this.pricehubService.stopPricesHub();
        this.tradeshubService.stopTradesHub();
        this.idle.stop();
        this.wtCrmService.fetchRefreshToken = false;
        swal({
            html: "<i style=\"color:#ffffff;\">" + msg + "</i>",
            timer: 1000,
            width: 300,
            padding: 20,
            allowOutsideClick: false,
            allowEscapeKey: false,
            background: '#030608 url(/assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
            showConfirmButton: false,
            target: '#body_full_screen'
        });
        setTimeout(function () {
            _this.router.navigate(['../']);
            // window.location.reload();
        }, 1000);
        setTimeout(function () {
            window.location.reload();
        }, 1000);
    };
    TradeComponent.prototype.showReloginMessage = function () {
        var msg = (new TranslatePipe(this.translateService)).transform("Trade_History_Relogin_Message");
        swal({
            html: "<i style=\"color:#ffffff;\">" + msg + "</i>",
            timer: 3000,
            width: 300,
            padding: 20,
            allowOutsideClick: false,
            allowEscapeKey: false,
            background: '#030608 url(/assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
            showConfirmButton: false,
            target: '#body_full_screen'
        });
    };
    TradeComponent.prototype.showErrorMessage = function (message) {
        var msg = (new TranslatePipe(this.translateService)).transform(message);
        swal({
            html: "<i style=\"color:#ffffff;\">" + msg + "</i>",
            timer: 3000,
            width: 300,
            padding: 20,
            allowOutsideClick: false,
            allowEscapeKey: false,
            background: '#030608 url(/assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
            showConfirmButton: false,
            target: '#body_full_screen'
        });
    };
    TradeComponent.prototype.setTimeFrame = function (timeFrame) {
        this.showCal = false;
        if (this.wtStorageService.selectedTimeFrame === timeFrame.toLowerCase())
            return;
        this.wtStorageService.selectedTimeFrame = timeFrame.toLowerCase();
        this.pricehubService.priceHubProxy.invoke('subscribe', this.wtStorageService.selectedSymbol, this.wtStorageService.selectedTimeFrame);
        this.fetchOldPrice();
        this.indicatorStorage.resetIndicatorStorage();
        var currentSymbolTimeframe = {
            "symbol": this.wtStorageService.selectedSymbol,
            "timeFrame": this.wtStorageService.selectedTimeFrame.toUpperCase()
        };
        this.selectedChartIndex = this.getIndex(this.chartTab, currentSymbolTimeframe);
        this.saveToLocalStorage(currentSymbolTimeframe, 'chart_tab_selected', this.wtStorageService.selectedMt4Account.accountId);
    };
    TradeComponent.prototype.showUserProfile = function (userProfileRef) {
        this.modalService.open(UserProfileComponent, { backdrop: 'static', keyboard: false, windowClass: 'depositModal' });
    };
    TradeComponent.prototype.isShowOpenTrade = function () {
        this.openTradesComponent.tradeViewLoaded = true;
        this.openTradesComponent.showOpenTrades = this.openTradesComponent.showOpenTrades ? false : true;
        this.openTradesComponent.closeFromTradeComponent();
        this.toggleDpDown(this.openTradesComponent.showOpenTrades, 'skipCloseView');
        this.tradeHistoryComponent.showTradesHistory = false;
        this.chart.xAxis[0].setExtremes(this.chartData[this.chartData.length > 25 ? this.chartData.length - 25 : 0][0], this.chartData[this.chartData.length - 1][0], true, true);
    };
    TradeComponent.prototype.ShowOpenTrade = function () {
        this.openTradesComponent.tradeViewLoaded = true;
        this.openTradesComponent.showOpenTrades = true;
        this.openTradesComponent.closeFromTradeComponent();
        this.toggleDpDown(this.openTradesComponent.showOpenTrades, 'skipCloseView');
        this.tradeHistoryComponent.showTradesHistory = false;
        this.chart.xAxis[0].setExtremes(this.chartData[this.chartData.length > 25 ? this.chartData.length - 25 : 0][0], this.chartData[this.chartData.length - 1][0], true, true);
    };
    TradeComponent.prototype.updateHistory = function (data) {
        this.tradeHistoryComponent.isDataOutdated = true;
        this.tradeHistoryComponent.totalRecordsCount++;
        this.tradeHistoryComponent.tradeRecordsCount++;
        if (this.tradeHistoryComponent.showTradesHistory)
            this.tradeHistoryComponent.openTradeClosed(data);
    };
    TradeComponent.prototype.isShowTradeHistory = function (show) {
        if (show && (this.wtStorageService.crmToken == null || this.wtStorageService.crmToken == undefined)) {
            this.showReloginMessage();
            return;
        }
        this.tradeHistoryComponent.tradeViewLoaded = true;
        // this.tradeHistoryComponent.isHistoryEmpty = false;
        if (this.tradeHistoryComponent.showTradesHistory && show)
            return;
        if (!this.tradeHistoryComponent.showTradesHistory) {
            this.tradeHistoryComponent.initTradeHistory();
        }
        this.tradeHistoryComponent.showTradesHistory = show;
        if (this.tradeHistoryComponent.showTradesHistory) {
            this.toggleDpDown(this.tradeHistoryComponent.showTradesHistory, 'skipCloseView');
            this.openTradesComponent.showOpenTrades = false;
            this.chart.xAxis[0].setExtremes(this.chartData[this.chartData.length - 10][0], this.chartData[this.chartData.length - 1][0], true, true);
        }
        else {
            this.chart.xAxis[0].setExtremes(this.chartData[this.chartData.length > 25 ? this.chartData.length - 25 : 0][0], this.chartData[this.chartData.length - 1][0], true, true);
        }
    };
    TradeComponent.prototype.showSettings = function (setting) {
        this.modalService.open(setting, { container: '#body_full_screen', backdrop: 'static' });
        this.lang = localStorage.getItem('lanGuage');
    };
    TradeComponent.prototype.toggleDpDown = function (data, opType) {
        if (!data && opType && this.dropDowns[opType] !== undefined) {
            if (opType === 'closeSymSel')
                this.searchInput = null;
            this.dropDowns[opType] = !data;
        }
        if (!data)
            return;
        if (opType != 'calcSel' && this.isMobileDevice) {
            this.showCal = false;
            this.dropDowns.calcSel = true;
        }
        if (opType != 'skipCloseView' && (this.tradeHistoryComponent.showTradesHistory || this.openTradesComponent.showOpenTrades)) {
            this.closeViews();
        }
        for (var key in this.dropDowns) {
            if (key === opType) {
                this.dropDowns[key] = !data;
            }
            else {
                this.dropDowns[key] = true;
            }
        }
    };
    TradeComponent.prototype.closeViews = function () {
        var time = 350;
        if (this.tradeHistoryComponent.showTradesHistory)
            time = 450;
        this.tradeHistoryComponent.showTradesHistory = false;
        this.openTradesComponent.showOpenTrades = false;
        this.chart.xAxis[0].setExtremes(this.chartData[this.chartData.length > 25 ? this.chartData.length - 25 : 0][0], this.chartData[this.chartData.length - 1][0], true, true);
    };
    TradeComponent.prototype.changeSymbol = function (selectedSymbol, selSymbolIntervalList, isChartTabUpdate) {
        this.dropDowns.closeSymSel = true;
        if (this.wtStorageService.selectedSymbol === selectedSymbol && isChartTabUpdate)
            return;
        this.currentPrice = '';
        this.wtStorageService.selectedSymbol = selectedSymbol;
        this.intervalList = selSymbolIntervalList;
        this.selectedSymConfig = this.clone(selSymbolIntervalList[0]);
        this.tradeAmount = parseInt(this.selectedSymConfig.minAmount);
        this.preAmount = this.tradeAmount;
        this.pricehubService.subscribeNewSymPrice();
        this.chart.yAxis[0].removePlotLine('currentPrice');
        if (this.labelText)
            this.labelText.destroy();
        this.openTradesComponent.removeAllPlotLines();
        this.addPlot = false;
        this.fetchOldPrice();
        this.indicatorStorage.resetIndicatorStorage();
        var currentSymbolTimeframe = {
            "symbol": this.wtStorageService.selectedSymbol,
            "timeFrame": this.wtStorageService.selectedTimeFrame.toUpperCase()
        };
        this.saveToLocalStorage(currentSymbolTimeframe, 'chart_tab_selected', this.wtStorageService.selectedMt4Account.accountId);
        this.selectedChartIndex = this.getIndex(this.chartTab, currentSymbolTimeframe);
    };
    TradeComponent.prototype.removeFromFav = function (fav) {
        this.favCount = this.favCount - 1;
        this.favorites[fav]['isFav'] = false;
        var favLocal = this.getFavoritesLocal(this.wtStorageService.selectedMt4Account.accountId);
        favLocal[fav] = undefined;
        favLocal = this.filterEmptyVal(favLocal);
        this.saveToLocalStorage(favLocal, 'favourites', this.wtStorageService.selectedMt4Account.accountId);
    };
    TradeComponent.prototype.addToFavourite = function (selectedSymbol, opType) {
        var favLocal = this.getFavoritesLocal(this.wtStorageService.selectedMt4Account.accountId);
        if (!this.favorites[selectedSymbol]['isFav']) {
            this.favorites[selectedSymbol]['isFav'] = true;
            this.favCount = this.favCount + 1;
            favLocal[selectedSymbol] = opType;
        }
        else {
            this.favorites[selectedSymbol]['isFav'] = false;
            this.favCount = this.favCount - 1;
            delete favLocal[selectedSymbol];
        }
        this.saveToLocalStorage(favLocal, 'favourites', this.wtStorageService.selectedMt4Account.accountId);
    };
    TradeComponent.prototype.setFavorites = function (accId) {
        var favoritesData = JSON.parse(localStorage.getItem('favourites'));
        this.favorites = (favoritesData && favoritesData[accId]) || {};
        this.favorites = this.filterUnavailableSymbols(this.favorites);
    };
    TradeComponent.prototype.filterUnavailableSymbols = function (fav) {
        var symbols = [];
        var allSymbols = {};
        var favKeys = Object.keys(fav);
        this.favCount = 0;
        for (var security in this.symbolConfiguration) {
            var symbolKeys = (Object.keys(this.symbolConfiguration[security]));
            symbols.push.apply(symbols, symbolKeys);
            for (var _i = 0, symbolKeys_1 = symbolKeys; _i < symbolKeys_1.length; _i++) {
                var symbol = symbolKeys_1[_i];
                if (this.symbolConfiguration[security][symbol] && this.symbolConfiguration[security][symbol].length > 0) {
                    allSymbols[symbol] = { security: security };
                    if (favKeys.indexOf(symbol) !== -1) {
                        allSymbols[symbol]['isFav'] = true;
                        this.favCount = this.favCount + 1;
                    }
                }
            }
        }
        return allSymbols;
    };
    TradeComponent.prototype.getFavoritesLocal = function (accId) {
        var fav = JSON.parse(localStorage.getItem('favourites'));
        var data = (fav && fav[accId]) ? fav[accId] : {};
        return data;
    };
    TradeComponent.prototype.filterChartTabs = function (data) {
        var resultTabs = [];
        var mapSymbol = [];
        var availSymbol = [];
        for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
            var temp = data_1[_i];
            if (mapSymbol.indexOf(temp.symbol) === -1)
                mapSymbol.push(temp.symbol);
        }
        for (var security in this.symbolConfiguration) {
            var symbolKeys = (Object.keys(this.symbolConfiguration[security]));
            for (var _a = 0, symbolKeys_2 = symbolKeys; _a < symbolKeys_2.length; _a++) {
                var symbol = symbolKeys_2[_a];
                if (this.symbolConfiguration[security][symbol]
                    && this.symbolConfiguration[security][symbol].length > 0
                    && mapSymbol.indexOf(symbol) !== -1) {
                    availSymbol.push(symbol);
                }
            }
        }
        data.forEach(function (value, index) {
            if (availSymbol.indexOf(value.symbol) !== -1)
                resultTabs.push(value);
        });
        return resultTabs;
    };
    TradeComponent.prototype.setCharts = function (accId) {
        var chartTabs = JSON.parse(localStorage.getItem('chart_tab'));
        if (chartTabs && chartTabs[accId] && (chartTabs[accId].length > 0)) {
            chartTabs[accId] = this.filterChartTabs(chartTabs[accId]);
            this.chartTab = chartTabs[accId].length > 5 ? chartTabs[accId].slice(chartTabs[accId].length - 5, chartTabs[accId].length) : chartTabs[accId];
            this.saveToLocalStorage(this.chartTab, 'chart_tab', accId);
        }
        if (JSON.parse(localStorage.getItem('chart_tab_selected'))) {
            this.selectedChart = (JSON.parse(localStorage.getItem('chart_tab_selected')))[accId];
        }
    };
    TradeComponent.prototype.getFromLocalStorage = function (getKey, updateKey) {
        var storedData = JSON.parse(localStorage.getItem(getKey)) || {};
        return storedData[updateKey] || {};
    };
    TradeComponent.prototype.saveToLocalStorage = function (data, getKey, updateKey) {
        var storedData = JSON.parse(localStorage.getItem(getKey)) || {};
        storedData[updateKey] = data;
        localStorage.setItem(getKey, JSON.stringify(storedData));
    };
    TradeComponent.prototype.updateLocalStorage = function (origArr, oldObj, newObj) {
        var storedData = this.getFromLocalStorage('chart_tab', this.wtStorageService.selectedMt4Account.accountId);
        var oldObjInChart = this.isInChartTab(origArr, oldObj || {});
        var newObjInLocal = this.isInChartTab(storedData, newObj || {});
        var updataLocalStorage = false;
        if (!oldObjInChart && oldObj != null) {
            storedData = this.removeFromArray(storedData, oldObj);
            updataLocalStorage = true;
        }
        if (!newObjInLocal && newObj != null) {
            storedData.push(this.chartTab[this.selectedChartIndex]);
            updataLocalStorage = true;
        }
        if (updataLocalStorage)
            this.saveToLocalStorage(storedData, 'chart_tab', this.wtStorageService.selectedMt4Account.accountId);
    };
    TradeComponent.prototype.goFS = function () {
        var document = window.document;
        var fs = document.getElementById("body_full_screen");
        if (!document.fullscreenElement &&
            !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
            if (fs.requestFullscreen) {
                fs.requestFullscreen();
            }
            else if (fs.msRequestFullscreen) {
                fs.msRequestFullscreen();
            }
            else if (fs.mozRequestFullScreen) {
                fs.mozRequestFullScreen();
            }
            else if (fs.webkitRequestFullscreen) {
                fs.webkitRequestFullscreen();
            }
        }
        else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
            else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
            else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            }
            else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    };
    TradeComponent.prototype.getProfileInfo = function () {
        var _this = this;
        if (this.wtStorageService.selectedMt4Account.accountId) {
            this.tradeService.getProfileInformation(this.wtStorageService.selectedMt4Account.accountId)
                .subscribe(function (result) {
                if (result.success && result.data) {
                    _this.profileData = result.data;
                    _this.userAvatar = _this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + _this.profileData['profileImage']);
                    if (_this.profileData['firstName']) {
                        _this.userName = _this.profileData['firstName'] + ' ' + _this.profileData['lastName'];
                    }
                    else {
                        _this.userName = _this.profileData['name'] || '';
                    }
                }
                else {
                    // failed
                    // alert("Error 1");
                }
            }, function (err) {
                // failed
                // alert("Error 2");
            });
        }
    };
    TradeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.windowHeight = window.innerHeight;
        this.headerWrapperHeight = this.windowHeight - (document.getElementsByClassName('header-wrapper')[0].clientHeight);
        if (localStorage.getItem('lanGuage') === 'zh') {
            this.group = 13;
            this.countryFlagUrl = 'assets/images/zh-flag.png';
        }
        else if (localStorage.getItem('lanGuage') === 'en') {
            this.countryFlagUrl = 'assets/images/us-flag.jpg';
            this.group = 19;
        }
        // const custom_variables = [
        // 	{ name: wtconfig.WT_CONFIG.mtVersion.mtx + 'ID', value: this.wtStorageService.selectedMt4Account.accountId },
        // 	{ name: 'Email', value: this.wtStorageService.email },
        // 	{ name: 'Name', value: this.wtStorageService.firstName + ' ' + this.wtStorageService.lastName },
        // 	{ name: 'Preferred Language', value: this.translateService.currentLang() }
        // ];
        // if (wtconfig.WT_CONFIG.liveChatLicenseId) {
        // 	const lcAPI = setInterval(() => {
        // 		if (window['LC_API']) {
        // 			window['LC_API'].set_custom_variables(custom_variables);
        // 			clearInterval(lcAPI);
        // 		}
        // 	}, 3000);
        // }
        // this.getDashboardInformation();
        this.getProfileInfo();
        // setTimeout(() => {
        // 	this.openReferral(this.referralRef);
        // }, 15 * 60 * 1000);
        setTimeout(function () {
            if (_this.accountInfo.balance === undefined || _this.accountInfo.balance === null || _this.accountInfo.balance <= 0) {
                _this.openDeposit(_this.depositRef);
            }
        }, 30000);
        setInterval(function () {
            // this.loginService.refreshToken(this.wtStorageService.crmToken);
        }, 840000);
    };
    TradeComponent.prototype.onResize = function (event) {
        this.windowHeight = window.innerHeight;
        this.headerWrapperHeight = this.windowHeight - (document.getElementsByClassName('header-wrapper')[0].clientHeight);
        // window width
    };
    TradeComponent.prototype.mapBalance = function () {
        var _this = this;
        var balances = [];
        this.tradeService.getBalance()
            .subscribe(function (result) {
            if (result.success && result.data) {
                balances = result.data;
                _this.wtStorageService.mt4AccountsList.forEach(function (mt4Account, i) {
                    balances.forEach(function (account) {
                        if (account.login == mt4Account.accountId) {
                            _this.wtStorageService.mt4AccountsList[i]['balance'] = account.balance;
                        }
                    });
                });
            }
            else {
            }
        }, function (err) {
        });
    };
    TradeComponent.prototype.openFS = function () {
        var document = window.document;
        var fs = document.getElementById("body_full_screen");
        if (!document.fullscreenElement &&
            !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
            if (fs.requestFullscreen) {
                fs.requestFullscreen();
            }
            else if (fs.msRequestFullscreen) {
                fs.msRequestFullscreen();
            }
            else if (fs.mozRequestFullScreen) {
                fs.mozRequestFullScreen();
            }
            else if (fs.webkitRequestFullscreen) {
                fs.webkitRequestFullscreen();
            }
            this.inFullScreenMode = true;
        }
        this.closeFullscreen();
    };
    TradeComponent.prototype.exitFS = function () {
        if (this.inFullScreenMode) {
            var document_1 = window.document;
            if (document_1.exitFullscreen) {
                document_1.exitFullscreen();
            }
            else if (document_1.msExitFullscreen) {
                document_1.msExitFullscreen();
            }
            else if (document_1.mozCancelFullScreen) {
                document_1.mozCancelFullScreen();
            }
            else if (document_1.webkitExitFullscreen) {
                document_1.webkitExitFullscreen();
            }
        }
    };
    TradeComponent.prototype.closeFullscreen = function () {
        if (this.openFullscreenRef) {
            this.openFullscreenRef.close();
            this.openFullscreenRef = null;
        }
    };
    // openReferral(referralRef: any) {
    //   if (this.wtStorageService.selectedMt4Account.demo) {
    //     swal({
    //       html: `<span style="color:#ffffff">${(new TranslatePipe(this.translateService))
    //         .transform('demoAccountCannotProceed')}<span>`,
    //       width: 400,
    //       timer: 1000,
    //       allowOutsideClick: false,
    //       allowEscapeKey: false,
    //       background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
    //       showConfirmButton: false,
    //       target: '#body_full_screen'
    //     });
    //     return false;
    //   }
    //   if (!this.openReferralRef) {
    //     this.referralData = this.mt4Accounts.find((o) => {
    //       return o['loginId'] === this.wtStorageService.selectedMt4Account.accountId
    //     });
    //     this.imageData = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.referralData.qrCode);
    //     this.affiliateLinkEnglish = this.wtUtilService.config.CRM_APP + '/affiliates/en/' + this.wtStorageService.selectedMt4Account.accountId;
    //     this.affiliateLinkChinese = this.wtUtilService.config.CRM_APP + '/affiliates/zh/' + this.wtStorageService.selectedMt4Account.accountId;
    //     const refLink = this.translateService.currentLang() === 'zh' ? this.affiliateLinkChinese : this.affiliateLinkEnglish;
    //     if (refLink) {
    //       const description = this.translateService.currentLang() == 'en' ? 'Check out the quick and easy way to earn profits' : '查看快速简便的赚取利润的方法';
    //       this.sinaReferral = 'http://service.weibo.com/share/share.php?url=' + refLink + '&appkey=&title=' + description + '&pic=&ralateUid=&language=zh_cn';
    //       this.facebookReferral = 'https://www.facebook.com/sharer/sharer.php?u=' + refLink;
    //       this.whatsappReferral = 'https://wa.me/?text=' + encodeURIComponent(description) + ' ' + refLink;
    //     }
    //     this.openReferralRef = this.modalService.open(referralRef, {
    //       backdrop: 'static',
    //       windowClass: 'sm referral-modal',
    //       keyboard: false,
    //       container: '#body_full_screen'
    //     });
    //   }
    // }
    // setTimeout(function(){
    //   this.openReferral(this.referralRef);
    //  }, 15 * 60 * 1000);
    TradeComponent.prototype.intervalChange = function (selectedInterval) {
        this.selectedSymConfig.interval = selectedInterval.interval;
        this.selectedSymConfig.winPayout = selectedInterval.winPayout;
        this.selectedSymConfig.minAmount = selectedInterval.minAmount;
        this.selectedSymConfig.maxAmount = selectedInterval.maxAmount;
        this.tradeAmount = parseInt(selectedInterval.minAmount);
    };
    TradeComponent.prototype.setAccount = function (accId, validated) {
        if (!validated) {
            var selectedAuth = false;
            for (var i = 0; i < this.wtStorageService.mt4AccountsList.length; i++) {
                if (this.wtStorageService.mt4AccountsList[i].validate) {
                    if (accId == this.wtStorageService.mt4AccountsList[i].accountId) {
                        selectedAuth = true;
                        break;
                    }
                }
            }
            if (!selectedAuth) {
                accId = this.wtStorageService.selectedMt4Account.accountId;
            }
        }
        this.accountSwitch = true;
        this.setFavorites(accId);
        this.chartTab = [];
        // this.setCharts(accId);
        this.tradeHistoryComponent.currentRecords = [];
        this.tradeHistoryComponent.historyRecords = {};
        this.restartSignalr();
        if (JSON.parse(localStorage.getItem('settings')) == null || !JSON.parse(localStorage.getItem('settings'))[this.wtStorageService.selectedMt4Account.accountId]) {
            this.wtStorageService.soundSetting = true;
            this.wtStorageService.oneclickSetting = false;
        }
        else {
            this.wtStorageService.soundSetting = JSON.parse(localStorage.getItem('settings'))[this.wtStorageService.selectedMt4Account.accountId].sound;
            this.wtStorageService.oneclickSetting = JSON.parse(localStorage.getItem('settings'))[this.wtStorageService.selectedMt4Account.accountId].oneClick;
        }
    };
    TradeComponent.prototype.changeMt4Account = function (mt4Account, index) {
        var _this = this;
        if (mt4Account.accountId === this.wtStorageService.selectedMt4Account.accountId)
            return;
        for (var i = 0; i < this.wtStorageService.mt4AccountsList.length; i++) {
            this.wtStorageService.mt4AccountsList[i].isSelected = false;
        }
        if (mt4Account.validate) {
            // this.isMt4Switched = true;
            this.wtStorageService.selectedMt4Account = mt4Account;
            mt4Account.isSelected = true;
            sessionStorage.setItem('mt4Account', JSON.stringify(mt4Account));
            this.setAccount(mt4Account.accountId, true);
        }
        else {
            mt4Account.isSelected = true;
            //  this.isMt4Switched = true;
            this.modalService.open(MT4AuthenticateComponent, this.modalOption).result.then(function (result) {
                //closing mt4 popup w/o any action
            }, function (reason) {
                _this.setAccount(mt4Account.accountId, false);
            });
        }
    };
    TradeComponent.prototype.restartSignalr = function () {
        this.pricehubService.stopPricesHub();
        this.tradeshubService.stopTradesHub();
        this.pricehubService.startPricesHub();
        this.tradeshubService.startTradesHub();
        this.isMt4Switched = false;
    };
    TradeComponent.prototype.incraseAmount = function () {
        this.selectedSymConfig.stepAmount = this.selectedSymConfig.stepAmount;
        this.tradeAmount = this.tradeAmount + this.selectedSymConfig.stepAmount;
        this.preAmount = this.tradeAmount;
        this.maxAmountCheck();
    };
    TradeComponent.prototype.decreaseAmount = function () {
        this.tradeAmount = Math.max(0, this.tradeAmount - this.selectedSymConfig.stepAmount);
        this.preAmount = this.tradeAmount;
        this.maxAmountCheck();
    };
    TradeComponent.prototype.onKey = function (event) {
        if (this.preAmount > this.selectedSymConfig.maxAmount && this.tradeAmount > this.selectedSymConfig.maxAmount) {
            this.tradeAmount = this.preAmount;
        }
        this.maxAmountCheck();
        this.preAmount = this.tradeAmount;
    };
    TradeComponent.prototype.addCalculator = function (num) {
        this.tradeAmount = this.tradeAmount + num;
        this.preAmount = this.tradeAmount;
        this.maxAmountCheck();
    };
    TradeComponent.prototype.subCalculator = function (num) {
        this.tradeAmount = Math.floor(this.tradeAmount - num);
        if (this.tradeAmount < 0)
            this.tradeAmount = 0;
        this.preAmount = this.tradeAmount;
        this.maxAmountCheck();
    };
    TradeComponent.prototype.mulCalculator = function (num) {
        if (num != 2 && (this.tradeAmount * 10) + num >= this.selectedSymConfig.maxAmount * 10)
            return;
        this.tradeAmount = this.tradeAmount * num;
        this.preAmount = this.tradeAmount;
        this.maxAmountCheck();
    };
    TradeComponent.prototype.addString = function (num) {
        if ((this.tradeAmount * 10) + num >= this.selectedSymConfig.maxAmount * 10)
            return;
        this.tradeAmount = this.tradeAmount * 10 + num;
        this.preAmount = this.tradeAmount;
        this.maxAmountCheck();
    };
    TradeComponent.prototype.removeLast = function () {
        this.tradeAmount = Math.floor(this.tradeAmount / 10);
        this.preAmount = this.tradeAmount;
        this.maxAmountCheck();
    };
    TradeComponent.prototype.maxAmountCheck = function () {
        if (this.tradeAmount > this.selectedSymConfig.maxAmount)
            this.maxAmountFlag = true;
        else if (this.tradeAmount <= this.selectedSymConfig.maxAmount)
            this.maxAmountFlag = false;
    };
    TradeComponent.prototype.setChartIndex = function (index) {
        this.toggleDpDown(true, null);
        this.selectedChartIndex = index;
        this.updateChart();
    };
    TradeComponent.prototype.addChartTab = function () {
        this.toggleDpDown(true, null);
        if (this.chartTab.length < 5) {
            var newChart = {
                "symbol": this.wtStorageService.selectedSymbol,
                "timeFrame": this.wtStorageService.selectedTimeFrame.toUpperCase()
            };
            if (this.getIndex(this.chartTab, newChart) !== -1) {
                swal({
                    html: "<i style=\"color:#ffffff;\">" + (new TranslatePipe(this.translateService))
                        .transform("Trade_Chart_Bookmarked") + "</i>",
                    timer: 1000,
                    width: 300,
                    padding: 20,
                    background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
                    showConfirmButton: false,
                    target: '#body_full_screen'
                });
            }
            else {
                this.chartTab.push(newChart);
                this.selectedChartIndex = this.chartTab.length - 1;
                this.saveToLocalStorage(this.chartTab, 'chart_tab', this.wtStorageService.selectedMt4Account.accountId);
                this.updateChart();
                swal({
                    html: "<i style=\"color:#ffffff;\">" + (new TranslatePipe(this.translateService))
                        .transform("Bookmark_Added") + "</i>",
                    timer: 1000,
                    width: 300,
                    padding: 20,
                    background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
                    showConfirmButton: false,
                    target: '#body_full_screen'
                });
            }
        }
        else {
            swal({
                html: "<i style=\"color:#ffffff;\">" + (new TranslatePipe(this.translateService))
                    .transform("Trade_Reached_Max_Chart_Tabs") + "</i>",
                timer: 1000,
                width: 300,
                padding: 20,
                background: '#030608 url(./assets/images/trade-dropdown-bg.jpg) 100% 100% no-repeat',
                showConfirmButton: false,
                target: '#body_full_screen'
            });
        }
    };
    TradeComponent.prototype.removeChartTab = function (removeIndex) {
        this.toggleDpDown(true, null);
        var tempTab = this.chartTab[removeIndex];
        this.chartTab.splice(removeIndex, 1);
        var symbTime = {
            "symbol": this.wtStorageService.selectedSymbol,
            "timeFrame": this.wtStorageService.selectedTimeFrame.toUpperCase()
        };
        this.selectedChartIndex = this.getIndex(this.chartTab, symbTime);
        this.updateLocalStorage(this.chartTab, tempTab, null);
    };
    TradeComponent.prototype.alignPriceSymbols = function () {
        if (this.tradeAmount) {
            switch (this.tradeAmount.toString().length) {
                case 0:
                    this.amountIpWidth = 40;
                    this.currencySymbolPadding = 42;
                    break;
                case 1:
                    this.amountIpWidth = 45;
                    this.currencySymbolPadding = 38;
                    break;
                case 2:
                    this.amountIpWidth = 50;
                    this.currencySymbolPadding = 34;
                    break;
                case 3:
                    this.amountIpWidth = 55;
                    this.currencySymbolPadding = 30;
                    break;
                case 4:
                    this.amountIpWidth = 60;
                    this.currencySymbolPadding = 26;
                    break;
            }
        }
        else {
            if (this.tradeAmount == null || (this.tradeAmount && this.tradeAmount.toString().length == 0)) {
                this.amountIpWidth = 40;
                this.currencySymbolPadding = 42;
            }
        }
    };
    TradeComponent.prototype.updateChart = function () {
        var selectedTimeFrame = this.wtStorageService.selectedTimeFrame;
        if (this.wtStorageService.selectedTimeFrame.toLowerCase() != this.chartTab[this.selectedChartIndex].timeFrame.toLowerCase()) {
            selectedTimeFrame = this.chartTab[this.selectedChartIndex].timeFrame.toLowerCase();
            ;
        }
        if (this.wtStorageService.selectedSymbol != this.chartTab[this.selectedChartIndex].symbol) {
            this.wtStorageService.selectedTimeFrame = selectedTimeFrame;
            var selectedSymbol = this.chartTab[this.selectedChartIndex].symbol;
            for (var security in this.symbolConfiguration) {
                if (this.symbolConfiguration[security][selectedSymbol] != undefined && this.symbolConfiguration[security][selectedSymbol].length > 0) {
                    this.changeSymbol(selectedSymbol, this.symbolConfiguration[security][selectedSymbol], true);
                    break;
                }
            }
            // this.saveToLocalStorage(this.chartTab[this.selectedChartIndex],'chart_tab_selected',this.wtStorageService.selectedMt4Account.accountId);
        }
        else if (selectedTimeFrame !== this.wtStorageService.selectedTimeFrame) {
            this.setTimeFrame(selectedTimeFrame);
        }
    };
    TradeComponent.prototype.generateSymbolList = function () {
        var _this = this;
        this.symbolConfiguration = { symbols: {} };
        var securities = {};
        if (this.configuration && this.configuration.securities && this.configuration.symbols && this.configuration.payoutConfigurations) {
            this.configuration.securities.forEach(function (security) {
                _this.symbolConfiguration[security.name] = {};
                securities[security.name] = false;
            });
            this.configuration.symbols.forEach(function (symbol) {
                if (_this.symbolConfiguration[symbol.security]) {
                    _this.symbolConfiguration[symbol.security][symbol.name] = [];
                    _this.symbolConfiguration.symbols[symbol.name] = {};
                    _this.symbolConfiguration.symbols[symbol.name]['amountStep'] = symbol.amountStep;
                }
                else {
                    console.log("security symbol: " + symbol.security + " symbol name: " + symbol.name);
                }
            });
            for (var j = 0; j < Object.keys(this.symbolConfiguration).length; j++) {
                if (Object.keys(this.symbolConfiguration)[j] == 'symbols')
                    continue;
                for (var symbol in this.symbolConfiguration[Object.keys(this.symbolConfiguration)[j]]) {
                    for (var i = 0; i < this.configuration.payoutConfigurations.length; i++) {
                        if (this.configuration.payoutConfigurations[i].symbols.indexOf(symbol) > -1) {
                            securities[Object.keys(this.symbolConfiguration)[j]] = true;
                            for (var _i = 0, _a = this.configuration.payoutConfigurations[i].intervals; _i < _a.length; _i++) {
                                var interval = _a[_i];
                                if (this.checkForInterval(this.symbolConfiguration[Object.keys(this.symbolConfiguration)[j]][symbol], interval, this.configuration.payoutConfigurations[i].payoutInfo.winPayout)) {
                                    var symbolIndex = this.getSymbolIndex(this.configuration.symbols, symbol);
                                    this.symbolConfiguration[Object.keys(this.symbolConfiguration)[j]][symbol].push({
                                        interval: interval,
                                        winPayout: this.configuration.payoutConfigurations[i].payoutInfo.winPayout,
                                        minAmount: this.configuration.payoutConfigurations[i].amountRange.min > this.configuration.symbols[symbolIndex].amountMin ?
                                            this.configuration.payoutConfigurations[i].amountRange.min : this.configuration.symbols[symbolIndex].amountMin,
                                        maxAmount: this.configuration.payoutConfigurations[i].amountRange.max < this.configuration.symbols[symbolIndex].amountMax ?
                                            this.configuration.payoutConfigurations[i].amountRange.max : this.configuration.symbols[symbolIndex].amountMax,
                                        stepAmount: this.symbolConfiguration.symbols[symbol].amountStep
                                    });
                                }
                            }
                        }
                    }
                }
            }
            for (var security in securities) {
                if (securities[security] === false) {
                    delete this.symbolConfiguration[security];
                }
            }
        }
        this.isSymbolLoaded = true;
        delete this.symbolConfiguration.symbols;
    };
    TradeComponent.prototype.checkForInterval = function (arr, interval, winPayout) {
        var index = arr.findIndex(function (ele) { return ele.interval == interval && ele.winPayout == winPayout; });
        if (index > -1)
            return false;
        else
            return true;
    };
    TradeComponent.prototype.getSymbolIndex = function (arr, symbol) {
        return arr.findIndex(function (ele) { return ele.name == symbol; });
    };
    TradeComponent.prototype.updatePlotLine = function (yValue) {
        var plotLine = this.chart.yAxis[0].plotLinesAndBands[this.getPlotLineIndex()].svgElem;
        var label = this.chart.yAxis[0].plotLinesAndBands[this.getPlotLineIndex()].label;
        var d = plotLine.d.split(' ');
        var newY = this.chart.yAxis[0].toPixels(yValue) - d[2];
        if (label) {
            label.attr('text', '<span class="current-price-indicator">' + yValue.toString().substr(0, 4) +
                '<span class="dec-bold">' + yValue.toString().substr(4, yValue.toString().length) +
                '</span></span>');
        }
    };
    TradeComponent.prototype.alignCurrentPriceLabel = function () {
        if (this.chart.yAxis[0].plotLinesAndBands[this.getPlotLineIndex()]) {
            var plotLine = this.chart.yAxis[0].plotLinesAndBands[this.getPlotLineIndex()].svgElem;
            var label = this.chart.yAxis[0].plotLinesAndBands[this.getPlotLineIndex()].label;
            var d = plotLine.d.split(' ');
            var newY = this.chart.yAxis[0].toPixels(this.currentPrice) - d[2];
            if (d && label) {
                plotLine.animate({
                    translateY: newY,
                }, 0);
                // label.animate({
                // 	translateY: this.chart.yAxis[0].toPixels(this.currentPrice) - this.chart.yAxis[0].toPixels(this.oldLabelVal)
                // },250);
                /*if (this.oldLabelVal)
                    label.animate({ 'foo': this.chart.yAxis[0].toPixels(this.oldLabelVal) + 3 },
                        {
                            step: function (foo: any) {
                                label.attr('y', foo);
                            },
                            duration: 0
                        });
                else
                    label.attr(this.chart.yAxis[0].toPixels(this.currentPrice) + 3);*/
                // label.translateY = this.chart.yAxis[0].toPixels(this.currentPrice) - this.chart.yAxis[0].toPixels(this.oldLabelVal);
                if (this.oldLabelVal)
                    label.attr('y', this.chart.yAxis[0].toPixels(this.oldLabelVal) + 3);
                label.attr('x', +d[4] + 18);
                label.attr('text', '<span class="current-price-indicator">' + this.currentPrice.toString().substr(0, 4) +
                    '<span class="dec-bold">' + this.currentPrice.toString().substr(4, this.currentPrice.toString().length) +
                    '</span></span>');
                this.oldLabelVal = this.currentPrice;
            }
        }
    };
    TradeComponent.prototype.getPlotLineIndex = function () {
        for (var i = 0; i < this.chart.yAxis[0].plotLinesAndBands.length; i++) {
            if (this.chart.yAxis[0].plotLinesAndBands[i].id == 'currentPrice') {
                break;
            }
        }
        return i;
    };
    TradeComponent.prototype.getMorePastData = function () {
        var _this = this;
        if (this.lastRecordDate && this.lastRecordDate != this.lastReqRecordDate) {
            this.lastReqRecordDate = this.lastRecordDate;
            this.chartLoaded = false;
            this.tradeService.getPastCandles(this.wtStorageService.selectedSymbol, this.wtStorageService.selectedTimeFrame, '60', this.lastRecordDate.substr(0, 19) + 'Z').subscribe(function (previousData) {
                if (previousData.bars.length > 0
                    && _this.wtStorageService.selectedSymbol === previousData.info.symbol
                    && _this.wtStorageService.selectedTimeFrame === previousData.info.timeframe.toLowerCase()) {
                    _this.lastRecordDate = previousData.bars[0].datetime;
                    if (_this.chartData[0][0] > new Date(previousData.bars[0].datetime).getTime()) {
                        var priceList = [];
                        previousData.bars.forEach(function (data) {
                            priceList.push([
                                /*moment(data['datetime']).tz("Europe/Athens").isDST() ? new Date(data['datetime']).getTime() - 3600000
                                  : new Date(data['datetime']).getTime(), // change required to handle daylight saving*/
                                new Date(data['datetime']).getTime(),
                                data['open'],
                                data['high'],
                                data['low'],
                                data['close']
                            ]);
                        });
                        _this.chartData = priceList.concat(_this.chartData);
                        _this.chart.series[0].setData(Object.assign([], _this.chartData), true, true, true);
                        _this.indicatorService.updatePreviousPoints(_this.chart, [_this.chart.series[0].xData, _this.chart.series[0].yData], _this.indicatorsComponent);
                    }
                }
                _this.chartLoaded = true;
                _this.initLoad = true;
                _this.accountSwitch = false;
            });
        }
    };
    TradeComponent.prototype.clicked = function (event) {
        event.preventDefault();
    };
    TradeComponent.prototype.filterEmptyVal = function (obj) {
        if (null == obj || "object" != typeof obj)
            return obj;
        var copy = obj.constructor();
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr) && obj[attr])
                copy[attr] = obj[attr];
        }
        return copy;
    };
    TradeComponent.prototype.getIndex = function (arr, obj) {
        var index = -1;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].symbol === obj.symbol && arr[i].timeFrame === obj.timeFrame) {
                index = i;
                break;
            }
        }
        return index;
    };
    TradeComponent.prototype.isInChartTab = function (arr, obj) {
        if (arr.length === 0)
            return false;
        var isEleUnique = false;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].symbol === obj.symbol && arr[i].timeFrame === obj.timeFrame) {
                isEleUnique = true;
                break;
            }
        }
        return isEleUnique;
    };
    TradeComponent.prototype.isEmpty = function (obj) {
        if (obj == null)
            return true;
        if (obj.length > 0)
            return false;
        if (obj.length === 0)
            return true;
        if (typeof obj !== "object")
            return true;
        for (var key in obj) {
            if (hasOwnProperty.call(obj, key))
                return false;
        }
        return true;
    };
    TradeComponent.prototype.removeFromArray = function (originalObj, obj) {
        return originalObj.filter(function (item) {
            return (item.symbol === obj.symbol && item.timeFrame === obj.timeFrame) ? false : true;
        });
    };
    TradeComponent.prototype.clone = function (obj) {
        var copy;
        // Handle the 3 simple types, and null or undefined
        if (null == obj || "object" != typeof obj)
            return obj;
        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }
        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (var i = 0, len = obj.length; i < len; i++) {
                copy[i] = this.clone(obj[i]);
            }
            return copy;
        }
        // Handle Object
        if (obj instanceof Object) {
            copy = {};
            for (var attr in obj) {
                if (obj.hasOwnProperty(attr))
                    copy[attr] = this.clone(obj[attr]);
            }
            return copy;
        }
        throw new Error("Unable to copy obj! Its type isn't supported.");
    };
    TradeComponent.prototype.soundClick = function (event) {
        var _a;
        var settings = JSON.parse(localStorage.getItem('settings'));
        if (settings == null || !settings[this.wtStorageService.selectedMt4Account.accountId]) {
            if (settings == null)
                settings = (_a = {},
                    _a[this.wtStorageService.selectedMt4Account.accountId] = { sound: this.wtStorageService.soundSetting, oneClick: this.wtStorageService.oneclickSetting },
                    _a);
            else
                settings[this.wtStorageService.selectedMt4Account.accountId] =
                    { sound: this.wtStorageService.soundSetting, oneClick: this.wtStorageService.oneclickSetting };
        }
        this.wtStorageService.soundSetting = this.wtStorageService.soundSetting ? false : true;
        settings[this.wtStorageService.selectedMt4Account.accountId].sound = this.wtStorageService.soundSetting;
        localStorage.setItem('settings', JSON.stringify(settings));
    };
    TradeComponent.prototype.oneClickSettingTogggle = function (event) {
        var _a;
        var settings = JSON.parse(localStorage.getItem('settings'));
        if (settings == null || !settings[this.wtStorageService.selectedMt4Account.accountId]) {
            if (settings == null)
                settings = (_a = {},
                    _a[this.wtStorageService.selectedMt4Account.accountId] = { sound: this.wtStorageService.soundSetting, oneClick: this.wtStorageService.oneclickSetting },
                    _a);
            else
                settings[this.wtStorageService.selectedMt4Account.accountId] =
                    { sound: this.wtStorageService.soundSetting, oneClick: this.wtStorageService.oneclickSetting };
        }
        this.wtStorageService.oneclickSetting = this.wtStorageService.oneclickSetting ? false : true;
        settings[this.wtStorageService.selectedMt4Account.accountId].oneClick = this.wtStorageService.oneclickSetting;
        localStorage.setItem('settings', JSON.stringify(settings));
    };
    TradeComponent.prototype.usClicked = function (event) {
        event.preventDefault();
        this.lang = 'en';
        this.translateService.selectLang('en');
        this.countryFlagUrl = 'assets/images/us-flag.jpg';
        // localStorage.setItem('lanGuage','en');
    };
    TradeComponent.prototype.chiClicked = function (event) {
        event.preventDefault();
        this.lang = 'zh';
        this.translateService.selectLang('zh');
        this.countryFlagUrl = 'assets/images/zh-flag.png';
        // localStorage.setItem('lanGuage','zh');
    };
    TradeComponent.prototype.saveLang = function () {
        localStorage.setItem('lanGuage', this.lang);
    };
    TradeComponent.prototype.closeLang = function () {
        this.translateService.selectLang(localStorage.getItem('lanGuage'));
        this.lang = localStorage.getItem('lanGuage');
        var zhFlag = 'assets/images/zh-flag.png';
        var enFlag = 'assets/images/us-flag.png';
        this.countryFlagUrl = localStorage.getItem('lanGuage') == 'zh' ? zhFlag : enFlag;
    };
    TradeComponent.prototype.onPopState = function (event) {
        this.getConfirmation();
    };
    TradeComponent.prototype.getConfirmation = function () {
        var mssg = new TranslatePipe(this.translateService).transform("Log_Out_Mssg");
        var retVal = confirm(mssg);
        var self = this;
        self.ignoreHashChange = true;
        window.onhashchange = function () {
            if (!self.ignoreHashChange) {
                self.ignoreHashChange = true;
            }
            else {
                self.ignoreHashChange = false;
            }
        };
        // var retVal = confirm("You will be logged out, Do you want to continue ?");
        if (retVal == true) {
            this.logOut();
            //   return true;
        }
        else {
            //   return false;
        }
    };
    TradeComponent.prototype.hideKeyPad = function (amtInput) {
        if (this.device === "ipad" || this.device === "android" || this.device === "iphone")
            amtInput.blur();
    };
    // @HostListener('window:keydown', ['$event'])
    //      keyboardInput(event: any) {
    //             if(event.keyCode == 116){
    // 				this.getConfirmation();
    //                 // alert('No Refresh Dude..!!');
    //                 // this.toggleDpDown(true,null);
    //             }
    //      }
    // 	 getConfirmation(){
    // 		 var retVal = confirm("You will be logged out, Do you want to continue ?");
    //        if( retVal == true ){
    // 		  this.logOut();
    //         //   return true;
    //        }
    //        else{
    //         //   return false;
    // 	 }
    // 	 }
    TradeComponent.prototype.ngOnDestroy = function () {
        this.renderer.removeClass(document.body, 'webtrader-chart');
    };
    return TradeComponent;
}());
export { TradeComponent };
export { ɵ0 };
