import {Injectable} from '@angular/core';
import {Headers, RequestOptions} from '@angular/http';
import {BehaviorSubject} from 'rxjs/Rx';
import {WTStorageService} from './wt-storage.service';
import {WTUtilService} from './wt-util.service';
import {WTHttpService} from './wt-http.service';


@Injectable()
export class WTCrmService {

  fetchRefreshToken: boolean = false;
  private crmCheckSub: BehaviorSubject<Object> = new BehaviorSubject({});

  constructor(private wtStorageService: WTStorageService,
              private wthttpService: WTHttpService,
              private wtUtilService: WTUtilService) {
  }

  startTokenRefresh(){
		if(!this.fetchRefreshToken) return;
		
		if(!this.wtStorageService.crmTokenExpireTime && !this.wtStorageService.userName 
 			&& !this.wtStorageService.passWord ){
			this.wtStorageService.crmBaseUrl = this.wtUtilService.config.CRM_BASE_URL;
            this.wtStorageService.crmToken = sessionStorage.getItem('crmToken');
			this.wtStorageService.crmTokenExpireTime = parseInt(sessionStorage.getItem('crmTokenExpireTime'));
			this.wtStorageService.crmOffsetValue = parseInt(sessionStorage.getItem('crmOffsetValue'));
		 }
		 this.crmTokenExpiryCheck();
 		// if(+new Date().getTime()+this.wtStorageService.crmOffsetValue > (this.wtStorageService.crmTokenExpireTime*1000)) {
 		// 	this.authUser();
 		// } else {
 		// 	this.crmTokenExpiryCheck();
 		// }
	}
 	private crmTokenExpiryCheck(){
		if(!this.fetchRefreshToken) return;
		// if(this.wtStorageService == undefined || this.wtStorageService.crmTokenExpireTime == undefined
		// 	|| this.wtStorageService.crmToken == undefined) {
		// 		return;
		// 	}
 		setTimeout(
 			() => {
 				this.authUser();
 			}, (840000));
 	}
  private authUser(){ 
		if(!this.fetchRefreshToken) return;
		// if(this.wtStorageService == undefined || this.wtStorageService.crmTokenExpireTime == undefined
		// || this.wtStorageService.crmToken == undefined) {
		// 	return;
		// }
    	var loginHeaders = new Headers({});
    	// loginHeaders.append('Domain', this.wtUtilService.config.DOMAIN);
    	// loginHeaders.append('MerchantKey', this.wtUtilService.config.MERCHANT_KEY);
    	// loginHeaders.append('lang', this.wtStorageService.selected_lang);
    	// loginHeaders.append('Content-type','application/x-www-form-urlencoded; charset=UTF-8');
    	// loginHeaders.append('IP', this.wtStorageService.ip );
		// loginHeaders.append('authorization',`bearer ${this.wtStorageService.crmToken}`)
		loginHeaders.append('token', this.wtStorageService.crmToken);
    	let options = new RequestOptions({ headers: loginHeaders });
        this.wthttpService.post(`${this.wtStorageService.apiBaseUrl}${this.wtUtilService.config.FOX_REFRESH_URI}`, null, options).subscribe(
        		(data:any) => {
        			this.wtStorageService.crmToken = data.token;
        			this.wtStorageService.crmTokenStartTime = data.start_time;
            		this.wtStorageService.crmTokenExpireTime = data.expire_time;
		            sessionStorage.setItem('crmToken', data.token);
		            sessionStorage.setItem('crmTokenExpireTime', data.expire_time);
		            this.crmTokenExpiryCheck();
				}, err => {
					sessionStorage.removeItem('crmToken');
		            sessionStorage.removeItem('crmTokenExpireTime');
					console.log(err);
				});
	}
}
