import {Route} from '@angular/router';
import {AuthGuard} from '../shared/auth-guard/auth-guard.service';
import {LoginComponent} from './login.component';

export const LoginRoutes: Route[] = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [AuthGuard]
  }
];
