/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./round-progress.component";
import * as i2 from "./round-progress.service";
import * as i3 from "./round-progress.ease";
import * as i4 from "./round-progress.config";
var styles_RoundProgressComponent = ["[_nghost-%COMP%] {\n      display: block;\n      position: relative;\n      overflow: hidden;\n    }", ".responsive[_nghost-%COMP%] {\n      width: 100%;\n      padding-bottom: 100%;\n    }", ".responsive[_nghost-%COMP%]    > svg[_ngcontent-%COMP%] {\n      position: absolute;\n      width: 100%;\n      height: 100%;\n      top: 0;\n      left: 0;\n    }"];
var RenderType_RoundProgressComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_RoundProgressComponent, data: {} });
export { RenderType_RoundProgressComponent as RenderType_RoundProgressComponent };
export function View_RoundProgressComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { _path: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 2, ":svg:svg", [["xmlns", "http://www.w3.org/2000/svg"]], [[1, "viewBox", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, ":svg:circle", [["fill", "none"]], [[1, "cx", 0], [1, "cy", 0], [1, "r", 0], [4, "stroke", null], [4, "stroke-width", null]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, [[1, 0], ["path", 1]], null, 0, ":svg:path", [["fill", "none"]], [[4, "stroke-width", null], [4, "stroke", null], [4, "stroke-linecap", null], [1, "transform", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._viewBox; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.radius; var currVal_2 = _co.radius; var currVal_3 = (_co.radius - (_co.stroke / 2)); var currVal_4 = _co.resolveColor(_co.background); var currVal_5 = _co.stroke; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.stroke; var currVal_7 = _co.resolveColor(_co.color); var currVal_8 = (_co.rounded ? "round" : ""); var currVal_9 = _co.getPathTransform(); _ck(_v, 3, 0, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_RoundProgressComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "round-progress", [["role", "progressbar"]], [[1, "aria-valuemin", 0], [1, "aria-valuemax", 0], [4, "width", null], [4, "height", null], [4, "padding-bottom", null], [2, "responsive", null]], null, null, View_RoundProgressComponent_0, RenderType_RoundProgressComponent)), i0.ɵdid(1, 573440, null, 0, i1.RoundProgressComponent, [i2.RoundProgressService, i3.RoundProgressEase, i4.RoundProgressConfig, i0.NgZone, i0.Renderer2], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).current; var currVal_1 = i0.ɵnov(_v, 1).max; var currVal_2 = (i0.ɵnov(_v, 1).responsive ? "" : (i0.ɵnov(_v, 1)._diameter + "px")); var currVal_3 = i0.ɵnov(_v, 1)._elementHeight; var currVal_4 = i0.ɵnov(_v, 1)._paddingBottom; var currVal_5 = i0.ɵnov(_v, 1).responsive; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
var RoundProgressComponentNgFactory = i0.ɵccf("round-progress", i1.RoundProgressComponent, View_RoundProgressComponent_Host_0, { current: "current", max: "max", radius: "radius", animation: "animation", animationDelay: "animationDelay", duration: "duration", stroke: "stroke", color: "color", background: "background", responsive: "responsive", clockwise: "clockwise", semicircle: "semicircle", rounded: "rounded" }, { onRender: "onRender" }, []);
export { RoundProgressComponentNgFactory as RoundProgressComponentNgFactory };
