<!--<div class="modal-header modal-headerbg ">
    <div></div>
    <a class="" aria-label="Close" (click)="closeModal()"><img src="/assets/images/closebt.png" /></a>
</div>

<div class="d-flex container">
     <div class="col-12" style="text-align: center">
        <a><img src="/assets/images/google_play_icon.png" /></a>
    </div>
</div>-->


<div class="modal-header">
  <h4 class="text-center get-andr-app-header">{{'mobiledevice_line1' | translate}} {{'mobiledevice_line2' |
    translate}}</h4>

</div>


<div class="modal-body get-android-app">


</div>

<div class="d-flex  modal-footer">
  <a [attr.href]="wtStorageService.mobileAppDownloadLink" class="app-download-link">
    {{'mobiledevice_download_app' | translate}}
  </a>

</div>







