/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./orientation.component";
import * as i2 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import * as i3 from "../service/wt-storage.service";
var styles_OrientationComponent = [];
var RenderType_OrientationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OrientationComponent, data: {} });
export { RenderType_OrientationComponent as RenderType_OrientationComponent };
export function View_OrientationComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_OrientationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "orientation", [], null, null, null, View_OrientationComponent_0, RenderType_OrientationComponent)), i0.ɵdid(1, 49152, null, 0, i1.OrientationComponent, [i2.NgbActiveModal, i3.WTStorageService], null, null)], null, null); }
var OrientationComponentNgFactory = i0.ɵccf("orientation", i1.OrientationComponent, View_OrientationComponent_Host_0, {}, {}, []);
export { OrientationComponentNgFactory as OrientationComponentNgFactory };
