
    <div
      class="dynamic-field dynamic-bt-block form-button"
      [formGroup]="group">
      <button class="btn bt-second-color"
              [disabled]="config.disabled"
              [name]="config.name"
              type="submit">
        {{ config.label }}
      </button>
    </div>
  